export const DATE_RANGE_PRESETS = {
     CUSTOM: 'Custom',
     TODAY: 'Today',
     LAST_7_DAYS: 'Last 7 Days',
     LAST_14_DAYS: 'Last 14 Days',
     LAST_30_DAYS: 'Last 30 Days',
     LAST_WEEK: 'Last Week',
     THIS_MONTH: 'This Month',
     LAST_MONTH: 'Last Month',
};

export const REPORT_NAMES = {
     EVENT_DETAILS: 'eventDetails',
     PAGE_VIEW_DETAILS: 'pageViewDetails',
     CONVERSION_EVENTS: 'conversionEvents',
     FORM_SUBMISSION_EVENTS: 'formSubmissionEvents',
     SESSION_DETAILS: 'sessionDetails',
     USER_DETAILS: 'userDetails',
     PERSON_DETAILS: 'personDetails',
     COMPANY_DETAILS: 'companyDetails',
     CONVERSION_DETAILS: 'conversionDetails',
     CONVERSION_NAME_DETAILS: 'conversionNameDetails',
     USER_SOURCE_SESSION_REPORT: 'userSourceSessionReport',
     USER_SOURCE_USER_REPORT: 'userSourceUserReport',
     ENTRANCE_AND_EXIT_REPORT: 'entranceAndExitReport',
     CONSENT_REPORT: 'consentReport',
     GEOGRAPHY_REPORT: 'geographyReport',
     DEVICE_REPORT: 'deviceReport',
     BROWSER_REPORT: 'browserReport',
     CHANNEL_REPORT: 'channelReport',
     SOURCE_REPORT: 'sourceReport',
     UTM_REPORT: 'utmReport',
     REFERRALS_REPORT: 'referralsReport',
     NEW_RETURNING_REPORT: 'newReturningReport',
     SESSION_COUNT_REPORT: 'sessionCountReport',
     SESSION_DURATION_REPORT: 'sessionDurationReport',
     CONVERSION_COUNT_REPORT: 'conversionCountReport',
     PEOPLE_AND_USER_COUNT_REPORT: 'peopleAndUserCountReport',
     PEOPLE_COUNT_REPORT: 'peopleCountReport',
     ALL_PAGE_REPORT: 'allPageReport',
     LANDING_PAGE_REPORT: 'landingPageReport',
     EXIT_PAGE_REPORT: 'exitPageReport',
     ALL_CONVERSION_REPORT: 'allConversionReport',
     BY_URL_REPORT: 'byUrlReport',
     BY_SOURCE_REPORT: 'bySourceReport',
     BY_UTM_REPORT: 'byUtmReport',
     BY_LANDING_PAGE_REPORT: 'byLandingPageReport',
     BY_CONVERSION: 'byConversion',
     BY_PROPERTY: 'byProperty',
     FORM_CATEGORY_REPORT: 'formCategoryReport',
     PERSON_DETAILS_PROFILE: 'personDetailsProfile',
     CONVERSION_SOURCE_ATTRIBUTION_REPORT: 'conversionSourceAttributionReport',
     ECOMMERCE_PURCHASE_FLOW: 'eCommercePurchaseFlow',
     ECOMMERCE_ITEMS: 'eCommerceItem',
     ECOMMERCE_PURCHASE_ACTIVITY: 'eCommercePurchaseActivity',
     ECOMMERCE_PURCHASE_TRANSACTION: 'eCommercePurchaseTransaction',
     SALES_CONVERSION_BY_CONVERSION: 'salesConversionReport',
     SALES_CONVERSION_BY_PROPERTY: 'salesConversionByPropertyReport'
};
export const REPORT_TYPE = {
     ATTRIBUTES: 'Attributes',
     ACQUISITION: 'Acquisition',
     ENGAGEMENT: 'Engagement',
     CONTENT: 'Content',
     CONVERSIONS: 'Conversions',
     ECOMMERCE: 'ECommerce',
     FUNNELS_AND_PATHS: 'Funnels And Paths',
     B2B_INSIGHTS: 'B2B Insights',
     CUSTOM_REPORT: 'Custom Reports',
     EXPLORE_DATA: 'Explore Data',
     SALES_CONVERSION: 'Sales Conversion'
};

export const VARIABLE_OPERATORS_DIMENSION_REPORT = [
     { value: 'ct', label: 'contains' },
     { value: 'nct', label: 'not contain' },
     { value: 'eq', label: 'equals to' },
     { value: 'neq', label: 'not equal to' },
];
export const VARIABLE_OPERATORS_BOOLEAN_REPORT = [
     { value: 'eq', label: 'equals to' },
     { value: 'neq', label: 'not equal to' },
];
export const VARIABLE_OPERATORS_METRICS_REPORT = [
     { value: 'eq', label: 'equals to' },
     { value: 'neq', label: 'not equal to' },
     { value: 'gt', label: 'greater than' }, // >
     { value: 'gtoet', label: 'greater than or equal to' }, // >=
     { value: 'lt', label: 'less than' }, // <
     { value: 'ltoet', label: 'less than or equal to' }, // <=
];

export const VARIABLE_OPERATORS_REPORT = [
     { value: 'ct', label: 'contains' },
     { value: 'nct', label: 'not contain' },
     { value: 'eq', label: 'equals to' },
     { value: 'neq', label: 'not equal to' },
     { value: 'gt', label: 'greater than' }, // >
     { value: 'gtoet', label: 'greater than or equal to' }, // >=
     { value: 'lt', label: 'less than' }, // <
     { value: 'ltoet', label: 'less than or equal to' }, // <=
]; // Variable operators of rules

export const CHECKLIST_TYPES = ['industries', 'revenue', 'employees', 'companyState', 'companyCountry', 'primaryIndustry'];

export const CHECK_LIST_OPERATOR_OPTION = { value: 'checklist', label: 'checklist' };

export const EXCLUDE_CHECK_LIST_OPERATOR_OPTION = { value: 'nctChecklist', label: 'not contain checklist' };

export const SCOPED = {
     EVENT: 'Event',
     SESSION: 'Session',
     USER: 'User',
     PEOPLE: 'People',
     FORM: 'Form',
     COMPANY: 'Company',
     CONVERSION: 'Conversion Event',
     SALES_CONVERSION_EVENT: 'Sales Conversion Event'
};

export const SECTION_REPORT = {
     DETAILS: 'Details',
     LOCATION: 'Location',
     DATE_UTC: 'Date (UTC)',
     DATE_ACCOUNT: 'Date (Account)',
     SOURCE_FIRST: 'Source (First)',
     SOURCE_LAST: 'Source (Last)',
     SOURCE_PATH: 'Source (Path)',
     COMPANY: 'Company',
     COUNTS: 'Counts',
     TOTAL_ACTIVITY: 'Total Activity',
     VALUES: 'Values',
     CONV_RATE: 'Conv. Rates',
     RELATED_OBJECTS: 'Related Objects',
     DEVICE: 'Device',
     BROWSER: 'Browser',
     CONSENT: 'Consent',
     GEOGRAPHY: 'Geography',
     ALL_CONV: 'All Conv.',
     PRIMARY_CONV: 'Primary Conv.',
     SECONDARY_CONV: 'Secondary Conv.',
     INDIVIDUAL_CONV: 'Individual Conv.',
     ECOMMERCE: 'Ecommerce',
};

export const DATE_SCOPE = {
     EVENT_ACTIVITY: 'Event activity',
     CREATION_DATE: 'Creation Date',
     LAST_CONVERSION_DATE: 'Last Conversion Date',
     LAST_ACTIVE_DATE: 'Last Active Date',
     // LAST_UPDATED_DATE: 'Last Updated Date'
};

export const REPORT_DATA_TYPES = {
     [REPORT_NAMES.EVENT_DETAILS]: {
          [SCOPED.EVENT]: SCOPED.EVENT,
          dimensions: [{ key: 'eventId', label: 'Event ID' }],
          properties: [
               { key: 'viewDetail', label: 'Profile', isDefault: true },
               { key: 'eventName', label: 'Event Name', isDefault: true },
               { key: 'eventLocation', label: 'Event Location', isDefault: true },
               { key: 'creationDate', label: 'Creation Date (event)', isDefault: true },
               { key: 'isConversion', label: 'Is Conversion?', isDefault: true },
               { key: 'conversionType', label: 'Conversion Type', isDefault: true },
               { key: 'userId', label: 'User ID', isDefault: true },
               { key: 'sessionId', label: 'Session ID', isDefault: true },
               { key: 'personId', label: 'Person ID', isDefault: true },
               { key: 'consentMethod', label: 'Consent Method (event)' },
               { key: 'trackingLevel', label: 'Tracking Level (event)' },
               { key: 'consentRegionalRule', label: 'Consent Regional Rule (event)' },
               { key: 'adStorage', label: 'ad_storage (event)' },
               { key: 'analyticsStorage', label: 'analytics_storage (event)' },
          ],
          metrics: [{ key: 'eventCount', label: 'Events' }],
     },
     [REPORT_NAMES.PAGE_VIEW_DETAILS]: {
          [SCOPED.EVENT]: SCOPED.EVENT,
          dimensions: [{ key: 'eventId', label: 'Page View Event ID' }],
          properties: [
               { key: 'eventName', label: 'Event Name', isDefault: true },
               { key: 'eventLocation', label: 'Event Location', isDefault: true },
               { key: 'creationDate', label: 'Creation Date (event)', isDefault: true },
               { key: 'isConversion', label: 'Is Conversion?', isDefault: true },
               { key: 'conversionType', label: 'Conversion Type', isDefault: true },
               { key: 'userId', label: 'User ID', isDefault: true },
               { key: 'sessionId', label: 'Session ID', isDefault: true },
               { key: 'personId', label: 'Person ID', isDefault: true },
               { key: 'consentMethod', label: 'Consent Method (event)' },
               { key: 'trackingLevel', label: 'Tracking Level (event)' },
               { key: 'consentRegionalRule', label: 'Consent Regional Rule (event)' },
               { key: 'adStorage', label: 'ad_storage (event)' },
               { key: 'analyticsStorage', label: 'analytics_storage (event)' },
          ],
          metrics: [{ key: 'eventCount', label: 'Events' }],
     },
     [REPORT_NAMES.CONVERSION_EVENTS]: {
          [SCOPED.EVENT]: SCOPED.EVENT,
          dimensions: [{ key: 'eventId', label: 'Event ID' }],
          properties: [
               { key: 'conversionName', label: 'Conversion Name', isDefault: true },
               { key: 'eventName', label: 'Event Name', isDefault: true },
               { key: 'userSourceFirst', label: 'User Source - First (conversion)', isDefault: true },
               { key: 'userSourcePath', label: 'User Source - Path (conversion)', isDefault: true },
               { key: 'userSourceLast', label: 'User Source - Last (conversion)', isDefault: true },
               { key: 'creationDate', label: 'Created Date', isDefault: true },
               { key: 'conversionType', label: 'Conversion Type', isDefault: true },
               { key: 'userId', label: 'User ID', isDefault: true },
               { key: 'sessionId', label: 'Session ID', isDefault: true },
               { key: 'personId', label: 'Person ID', isDefault: true },
               { key: 'externalId', label: 'External ID', isDefault: true },
               { key: 'email', label: 'Email', isDefault: true },
               { key: 'phone', label: 'Phone', isDefault: true },
               { key: 'firstName', label: 'First Name', isDefault: true },
               { key: 'lastName', label: 'Last Name', isDefault: true },
               { key: 'conversionValue', label: 'Conversion Value', isDefault: true },
               { key: 'conversionCurrency', label: 'Conversion Currency', isDefault: true },
          ],
          metrics: [{ key: 'conversionCount', label: 'Events' }],
     },
     [REPORT_NAMES.FORM_SUBMISSION_EVENTS]: {
          [SCOPED.SESSION]: SCOPED.SESSION,
          dimensions: [{ key: 'eventId', label: 'Event ID' }],
          properties: [
               { key: 'eventName', label: 'Event Name', isDefault: true },
               { key: 'userSourceFirst', label: 'User Source - First (form submission)', isDefault: true },
               { key: 'userSourcePath', label: 'User Source - Path (form submission)', isDefault: true },
               { key: 'userSourceLast', label: 'User Source - Last (form submission)', isDefault: true },
               { key: 'creationDate', label: 'Created Date', isDefault: true },
               { key: 'userId', label: 'User ID', isDefault: true },
               { key: 'sessionId', label: 'Session ID', isDefault: true },
               { key: 'personId', label: 'Person ID', isDefault: true },
               { key: 'externalId', label: 'External ID', isDefault: true },
               { key: 'email', label: 'Email', isDefault: true },
               { key: 'phone', label: 'Phone', isDefault: true },
               { key: 'firstName', label: 'First Name', isDefault: true },
               { key: 'lastName', label: 'Last Name', isDefault: true },
          ],
          metrics: [{ key: 'eventCount', label: 'Events' }],
     },
     [REPORT_NAMES.SESSION_DETAILS]: {
          [SCOPED.SESSION]: SCOPED.SESSION,
          isConversionName: true,
          isConversionNameRate: false,
          isDefaultMetrics: true,
          dimensions: [{ key: 'sessionId', label: 'Session ID' }],
          properties: [
               { key: 'viewDetail', label: 'Profile', isDefault: true },
               { key: 'landingPage', label: 'Landing Page', isDefault: true },
               { key: 'exitPage', label: 'Exit Page', isDefault: true },
               { key: 'engaged', label: 'Engaged', isDefault: true },
               { key: 'userSource', label: 'User Source', isDefault: true },
               { key: 'creationDate', label: 'Creation Date (session)', isDefault: true },
               { key: 'sessionNumber', label: 'Session Number', isDefault: true },
               { key: 'targetAccount', label: 'Target Account' },
               { key: 'targetAccountDomain', label: 'Target Account Domain' },
          ],
          metrics: [
               { key: 'eventCount', label: 'Events', isDefault: true },
               { key: 'engagementScore', label: 'Engagement Score', isDefault: true },
               { key: 'pageViewCount', label: 'Pageviews', isDefault: true },
               { key: 'sessionDuration', label: 'Session Duration', isDefault: true },
               { key: 'avgVisibleTimePerPage', label: 'Avg Visible Time / Page' },
               { key: 'personCreatedCount', label: 'Person Created', isDefault: true },
               { key: 'conversionCount', label: 'All Conversions', isDefault: true },
               { key: 'primaryConversionCount', label: 'Primary Conversions' },
               { key: 'secondaryConversionCount', label: 'Secondary Conversions' },
          ],
     },
     [REPORT_NAMES.CONVERSION_SOURCE_ATTRIBUTION_REPORT]: {
          [SCOPED.CONVERSION]: {
               isConversionName: true,
               isConversionNameRate: false,
               // isConversionNameValue: true,
               dimensions: [
                    { key: 'userSource', label: 'User Source' },
                    { key: 'channel', label: 'Channel' },
                    { key: 'utmMedium', label: 'utm_medium' },
                    { key: 'utmSource', label: 'utm_source' },
                    { key: 'utmCampaign', label: 'utm_campaign' },
                    { key: 'utmTerm', label: 'utm_term' },
                    { key: 'referrerOriginPath', label: 'Referrer' },
                    { key: 'landingPageOriginPath', label: 'Landing Page' },
                    // { key: 'gclid', label: 'Gclid' },
                    // { key: 'fbclid', label: 'Fbclid' },
               ],
               defaultDimensions: [{ key: 'userSource', label: 'User Source' }],
               properties: [],
               metrics: [
                    { key: 'sessionCount', label: 'Sessions' },
                    { key: 'conversionCount', label: 'All Conversions' },
                    { key: 'conversionValueCount', label: 'All Conversions value' },
                    // { key: 'firstTouch', label: 'First - Touch' },
                    // { key: 'lastTouch', label: 'Last - Touch' },
                    // { key: 'linear', label: 'Linear' },
                    // { key: 'engagementDriven', label: 'Engagement Driven' },
                    // { key: 'uShape', label: 'U-Shape' },
                    { key: 'primaryConversionCount', label: 'Primary Conversions' },
                    { key: 'primaryConversionValueCount', label: 'Primary Conversions Value' },
                    { key: 'secondaryConversionCount', label: 'Secondary Conversions' },
                    { key: 'secondaryConversionValueCount', label: 'Secondary Conversions Value' },
               ],
               weights: [
                    { value: 'firstTouch', label: 'First - Touch' },
                    { value: 'lastTouch', label: 'Last - Touch' },
                    { value: 'linear', label: 'Linear' },
                    { value: 'engagementDriven', label: 'Engagement Driven' },
                    { value: 'uShape', label: 'U-Shape' },
               ],
               defaultWeight: { value: 'engagementDriven', label: 'Engagement Driven', noneDirect: false },
          },
     },
     [REPORT_NAMES.USER_DETAILS]: {
          [SCOPED.USER]: SCOPED.USER,
          isConversionName: true,
          isConversionNameRate: false,
          isDefaultMetrics: true,
          dimensions: [{ key: 'userId', label: 'User ID' }],
          properties: [
               // { key: '', label: 'User Source - First' },
               // { key: '', label: 'User Source - Last' },
               // { key: '', label: 'User Source - Path' },
               { key: 'viewDetail', label: 'Profile', isDefault: true },
               { key: 'userSourceFirst', label: 'User Source - First (user)', isDefault: true },
               { key: 'userSourcePath', label: 'User Source - Path (user)', isDefault: true },
               { key: 'noneDirectSourcePath', label: 'Non-Direct User Source - Path (user)' },
               { key: 'userSourceLast', label: 'User Source - Last (user)', isDefault: true },
               { key: 'creationDate', label: 'Creation Date (user)', isDefault: true },
               { key: 'lastConversionDate', label: 'Last Conversion Date', isDefault: true },
               { key: 'lastEventDate', label: 'Last Event Date', isDefault: true },
               { key: 'targetAccount', label: 'Target Account' },
               { key: 'targetAccountDomain', label: 'Target Account Domain' },
          ],
          metrics: [
               { key: 'eventCount', label: 'Events', isDefault: true },
               { key: 'sessionCount', label: 'Sessions', isDefault: true },
               { key: 'avgSessionDuration', label: 'Avg Session Duration' },
               { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
               { key: 'engagementRate', label: 'Engagement Rate' },
               { key: 'engagementScore', label: 'Lifetime Engagement Score', isDefault: true },
               { key: 'bouncedSessionCount', label: 'Bounced Sessions' },
               { key: 'bounceRate', label: 'Bounce Rate' },
               { key: 'pageViewCount', label: 'Pageviews', isDefault: true },
               { key: 'avgPageViewsPerSession', label: 'Avg Pageviews / Session' },
               { key: 'avgVisibleTimePerPage', label: 'Avg Visible Time / Page' },
               { key: 'sourcePathCount', label: 'Source Path Count' },
               { key: 'noneDirectSourcePathCount', label: 'Non-Direct Source Path Count' },
               { key: 'conversionCount', label: 'All Conversions', isDefault: true },
               { key: 'primaryConversionCount', label: 'Primary Conversions' },
               { key: 'secondaryConversionCount', label: 'Secondary Conversions' },
               { key: 'conversionRateAll', label: 'Conv Rate (All Conversions)' },
               { key: 'conversionRatePrimary', label: 'Conv Rate (Primary Conversions)' },
               { key: 'conversionRateSecondary', label: 'Conv Rate (Secondary Conversions)' },
          ],
          dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE, DATE_SCOPE.LAST_CONVERSION_DATE, DATE_SCOPE.LAST_ACTIVE_DATE],
     },
     [REPORT_NAMES.COMPANY_DETAILS]: {
          [SCOPED.COMPANY]: SCOPED.COMPANY,
          isConversionName: true,
          isConversionNameRate: false,
          isDefaultMetrics: true,
          dimensions: [{ key: 'companyId', label: 'Revealed Company ID' }],
          properties: [
               { key: 'viewDetail', label: 'Profile', isDefault: true },
               { key: 'companyName', label: 'Company Name', isDefault: true },
               { key: 'domain', label: 'Domain', isDefault: true },
               { key: 'revenue', label: 'Revenue', isDefault: true },
               { key: 'employees', label: 'Total Employees', isDefault: true },
               { key: 'primaryIndustry', label: 'Primary Industry', isDefault: true },
               { key: 'industries', label: 'Products & Services Tags', isDefault: true },
               { key: 'companyCountry', label: 'Country', isDefault: true },
               { key: 'companyState', label: 'State' },
               { key: 'createdDate', label: 'Creation date' },
               { key: 'latestUpdateDate', label: 'Latest update date' },
               { key: 'lastConversionDate', label: 'Last Conversion Date' },
               { key: 'userSourceFirst', label: 'User Source - First (company)', isDefault: true },
               { key: 'userSourcePath', label: 'User Source - Path (company)', isDefault: true },
               { key: 'noneDirectSourcePath', label: 'Non-Direct User Source - Path (company)' },
               { key: 'userSourceLast', label: 'User Source - Last (company)', isDefault: true },
               { key: 'targetAccount', label: 'Target Account' },
          ],
          metrics: [
               { key: 'peopleCount', label: 'People', isDefault: true },
               { key: 'userCount', label: 'Users', isDefault: true },
               { key: 'sessionCount', label: 'Sessions', isDefault: true },
               { key: 'eventCount', label: 'Events', isDefault: true },
               { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
               { key: 'engagementScore', label: 'Lifetime Engagement Score', isDefault: true },
               { key: 'avgSessionDuration', label: 'Avg Session Duration' },
               { key: 'avgVisibleTimePerPage', label: 'Avg Visible Time / Page' },
               { key: 'sourcePathCount', label: 'Source Path Count' },
               { key: 'noneDirectSourcePathCount', label: 'Non-Direct Source Path Count' },
               { key: 'conversionCount', label: 'All Conversions', isDefault: true },
               { key: 'primaryConversionCount', label: 'Primary Conversions' },
               { key: 'secondaryConversionCount', label: 'Secondary Conversions' },
               { key: 'conversionRateAll', label: 'Conv Rate (All Conversions)' },
               { key: 'conversionRatePrimary', label: 'Conv Rate (Primary Conversions)' },
               { key: 'conversionRateSecondary', label: 'Conv Rate (Secondary Conversions)' },
          ],
          dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE, DATE_SCOPE.LAST_CONVERSION_DATE, DATE_SCOPE.LAST_ACTIVE_DATE],
     },
     [REPORT_NAMES.PERSON_DETAILS]: {
          [SCOPED.PEOPLE]: SCOPED.PEOPLE,
          isConversionName: true,
          isConversionNameRate: false,
          isDefaultMetrics: true,
          dimensions: [{ key: 'personId', label: 'Person ID' }],
          properties: [
               // { key: '', label: 'User Source - First' },
               // { key: '', label: 'User Source - Last' },
               // { key: '', label: 'User Source - Path' },
               { key: 'viewDetail', label: 'Profile', isDefault: true },
               { key: 'name', label: 'Name', isDefault: true },
               { key: 'email', label: 'Email', isDefault: true },
               { key: 'emailList', label: 'Email List', isDefault: true },
               { key: 'phone', label: 'Phone', isDefault: true },
               { key: 'phoneList', label: 'Phone List', isDefault: true },
               { key: 'userSourceFirst', label: 'User Source - First (person)', isDefault: true },
               { key: 'userSourcePath', label: 'User Source - Path (person)', isDefault: true },
               { key: 'noneDirectSourcePath', label: 'Non-Direct User Source - Path (person)', isDefault: true },
               { key: 'userSourceLast', label: 'User Source - Last (person)', isDefault: true },
               { key: 'userSourceFirstPersonCreated', label: 'User Source - First (person created)', isDefault: true },
               { key: 'userSourcePathPersonCreated', label: 'User Source - Path (person created)', isDefault: true },
               { key: 'userSourceLastPersonCreated', label: 'User Source - Last (person created)', isDefault: true },
               { key: 'creationDate', label: 'Creation Date', isDefault: true },
               { key: 'lastConversionDate', label: 'Last Conversion Date', isDefault: true },
               { key: 'lastEventDate', label: 'Last Event Date', isDefault: true },
               { key: 'userId', label: 'User ID', isDefault: true },
               { key: 'externalId', label: 'External ID', isDefault: true },
               { key: 'targetAccount', label: 'Target Account' },
               { key: 'targetAccountDomain', label: 'Target Account Domain' },
          ],
          metrics: [
               { key: 'eventCount', label: 'Events', isDefault: true },
               { key: 'sessionCount', label: 'Sessions', isDefault: true },
               { key: 'avgSessionDuration', label: 'Avg Session Duration' },
               { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
               { key: 'engagementRate', label: 'Engagement Rate' },
               { key: 'engagementScore', label: 'Lifetime Engagement Score', isDefault: true },
               { key: 'bouncedSessionCount', label: 'Bounced Sessions' },
               { key: 'bounceRate', label: 'Bounce Rate' },
               { key: 'pageViewCount', label: 'Pageviews', isDefault: true },
               { key: 'avgPageViewsPerSession', label: 'Avg Pageviews / Session' },
               { key: 'avgVisibleTimePerPage', label: 'Avg Visible Time / Page' },
               { key: 'sourcePathCount', label: 'Source Path Count' },
               { key: 'noneDirectSourcePathCount', label: 'Non-Direct Source Path Count' },
               { key: 'conversionCount', label: 'All Conversions', isDefault: true },
               { key: 'primaryConversionCount', label: 'Primary Conversions' },
               { key: 'secondaryConversionCount', label: 'Secondary Conversions' },
               { key: 'conversionRateAll', label: 'Conv Rate (All Conversions)' },
               { key: 'conversionRatePrimary', label: 'Conv Rate (Primary Conversions)' },
               { key: 'conversionRateSecondary', label: 'Conv Rate (Secondary Conversions)' },
          ],
          dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE, DATE_SCOPE.LAST_CONVERSION_DATE, DATE_SCOPE.LAST_ACTIVE_DATE],
     },
     [REPORT_NAMES.PERSON_DETAILS_PROFILE]: {
          [SCOPED.PEOPLE]: SCOPED.PEOPLE,
          isConversionName: true,
          isConversionNameRate: false,
          isDefaultMetrics: true,
          dimensions: [{ key: 'personId', label: 'Person ID' }],
          properties: [
               // { key: '', label: 'User Source - First' },
               // { key: '', label: 'User Source - Last' },
               // { key: '', label: 'User Source - Path' },
               { key: 'viewDetail', label: 'Profile', isDefault: true },
               { key: 'name', label: 'Name', isDefault: true },
               { key: 'email', label: 'Email', isDefault: true },
               { key: 'phone', label: 'Phone', isDefault: true },
               { key: 'userSourceFirst', label: 'User Source - First (person)', isDefault: true },
               { key: 'userSourcePath', label: 'User Source - Path (person)', isDefault: true },
               { key: 'noneDirectSourcePath', label: 'Non-Direct User Source - Path (person)', isDefault: true },
               { key: 'userSourceLast', label: 'User Source - Last (person)', isDefault: true },
               { key: 'userSourceFirstPersonCreated', label: 'User Source - First (person created)', isDefault: true },
               { key: 'userSourcePathPersonCreated', label: 'User Source - Path (person created)', isDefault: true },
               { key: 'userSourceLastPersonCreated', label: 'User Source - Last (person created)', isDefault: true },
               { key: 'creationDate', label: 'Creation Date', isDefault: true },
               { key: 'lastConversionDate', label: 'Last Conversion Date', isDefault: true },
               { key: 'lastEventDate', label: 'Last Event Date', isDefault: true },
               { key: 'userId', label: 'User ID', isDefault: true },
               { key: 'externalId', label: 'External ID', isDefault: true },
               { key: 'targetAccount', label: 'Target Account' },
               { key: 'targetAccountDomain', label: 'Target Account Domain' },
          ],
          metrics: [
               { key: 'eventCount', label: 'Events', isDefault: true },
               { key: 'sessionCount', label: 'Sessions', isDefault: true },
               { key: 'avgSessionDuration', label: 'Avg Session Duration' },
               { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
               { key: 'engagementRate', label: 'Engagement Rate' },
               { key: 'engagementScore', label: 'Lifetime Engagement Score', isDefault: true },
               { key: 'bouncedSessionCount', label: 'Bounced Sessions' },
               { key: 'bounceRate', label: 'Bounce Rate' },
               { key: 'pageViewCount', label: 'Pageviews', isDefault: true },
               { key: 'avgPageViewsPerSession', label: 'Avg Pageviews / Session' },
               { key: 'avgVisibleTimePerPage', label: 'Avg Visible Time / Page' },
               { key: 'conversionCount', label: 'All Conversions', isDefault: true },
               { key: 'primaryConversionCount', label: 'Primary Conversions' },
               { key: 'secondaryConversionCount', label: 'Secondary Conversions' },
               { key: 'conversionRateAll', label: 'Conv Rate (All Conversions)' },
               { key: 'conversionRatePrimary', label: 'Conv Rate (Primary Conversions)' },
               { key: 'conversionRateSecondary', label: 'Conv Rate (Secondary Conversions)' },
          ],
          dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE, DATE_SCOPE.LAST_CONVERSION_DATE, DATE_SCOPE.LAST_ACTIVE_DATE],
     },
     [REPORT_NAMES.CONVERSION_DETAILS]: {
          [SCOPED.SESSION]: SCOPED.SESSION,
          isConversionName: true,
          isConversionNameRate: false,
          dimensions: [{ key: 'conversionId', label: 'Conversion ID' }],
          properties: [{ key: 'conversionName', label: 'Conversion Name' }],
          metrics: [
               { key: 'eventCount', label: 'Events' },
               // { key: 'sessionCount', label: 'Sessions' },
               // { key: 'userCount', label: 'Users' },
               // { key: 'peopleCount', label: 'People' },
               { key: 'avgSessionDuration', label: 'Avg Session Duration' },
               { key: 'pageViewCount', label: 'Pageviews' },
               { key: 'avgVisibleTimePerPage', label: 'Avg Visible Time / Page' },
               { key: 'conversionCount', label: 'All Conversions' },
               { key: 'primaryConversionCount', label: 'Primary Conversions' },
               { key: 'secondaryConversionCount', label: 'Secondary Conversions' },
          ],
     },
     [REPORT_NAMES.CONVERSION_NAME_DETAILS]: {
          dimensions: [{ key: 'conversionName', label: 'Conversion Name' }],
          properties: [{ key: 'conversionType', label: 'Conversion Type' }],
          metrics: [
               { key: 'conversionCount', label: 'All Conversions' },
               { key: 'primaryConversionCount', label: 'Primary Conversions' },
               { key: 'secondaryConversionCount', label: 'Secondary Conversions' },
               { key: 'activeUserCount', label: 'Active Users' },
               { key: 'newUserCount', label: 'New Users' },
               { key: 'avgSessionDuration', label: 'Avg Session Duration' },
               { key: 'avgPageViewsPerSession', label: 'Avg Pageviews / Session' },
          ],
     },
     [REPORT_NAMES.USER_SOURCE_SESSION_REPORT]: {
          dimensions: [{ key: 'userSource', label: 'User Source (session)' }],
          properties: [],
          metrics: [
               { key: 'activeUserCount', label: 'Active Users' },
               { key: 'newUserCount', label: 'New Users' },
               { key: 'sessionCount', label: 'Sessions' },
               { key: 'avgSessionDuration', label: 'Avg Session Duration' },
               { key: 'engagedSessionCount', label: 'Engaged Sessions' },
               { key: 'engagementRate', label: 'Engagement Rate' },
               { key: 'bouncedSessionCount', label: 'Bounced Sessions' },
               { key: 'bounceRate', label: 'Bounce Rate' },
               { key: 'pageViewCount', label: 'Pageviews' },
               { key: 'avgPageViewsPerSession', label: 'Avg Pageviews / Session' },
               { key: 'avgVisibleTimePerPage', label: 'Avg Visible Time / Page' },
               { key: 'conversionCount', label: 'All Conversions' },
               { key: 'primaryConversionCount', label: 'Primary Conversions' },
               { key: 'secondaryConversionCount', label: 'Secondary Conversions' },
               { key: 'conversionRateAll', label: 'Conv Rate (All Conversions)' },
               { key: 'conversionRatePrimary', label: 'Conv Rate (Primary Conversions)' },
               { key: 'conversionRateSecondary', label: 'Conv Rate (Secondary Conversions)' },
          ],
     },
     [REPORT_NAMES.USER_SOURCE_USER_REPORT]: {
          dimensions: [
               { key: 'userSourceFirst', label: 'User Source - First (user)' },
               { key: 'userSourceLast', label: 'User Source - Last (user)' },
          ],
          properties: [],
          metrics: [
               { key: 'activeUserCount', label: 'Active Users' },
               { key: 'newUserCount', label: 'New Users' },
               { key: 'sessionCount', label: 'Sessions' },
               { key: 'avgSessionDuration', label: 'Avg Session Duration' },
               { key: 'engagedSessionCount', label: 'Engaged Sessions' },
               { key: 'engagementRate', label: 'Engagement Rate' },
               { key: 'bouncedSessionCount', label: 'Bounced Sessions' },
               { key: 'bounceRate', label: 'Bounce Rate' },
               { key: 'pageViewCount', label: 'Pageviews' },
               { key: 'avgPageViewsPerSession', label: 'Avg Pageviews / Session' },
               { key: 'avgVisibleTimePerPage', label: 'Avg Visible Time / Page' },
               { key: 'conversionCount', label: 'All Conversions' },
               { key: 'primaryConversionCount', label: 'Primary Conversions' },
               { key: 'secondaryConversionCount', label: 'Secondary Conversions' },
               { key: 'conversionRateAll', label: 'Conv Rate (All Conversions)' },
               { key: 'conversionRatePrimary', label: 'Conv Rate (Primary Conversions)' },
               { key: 'conversionRateSecondary', label: 'Conv Rate (Secondary Conversions)' },
          ],
     },
     [REPORT_NAMES.ENTRANCE_AND_EXIT_REPORT]: {
          [SCOPED.SESSION]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'landingPageOriginPath', label: 'Landing Page' },
                    { key: 'exitPageOriginPath', label: 'Exit Page' },
               ],
               properties: [],
               metrics: [
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'userCount', label: 'User', isDefault: true },
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'avgSessionDuration', label: 'Avg Session Duration' },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagementRate', label: 'Engagement Rate' },
                    { key: 'bouncedSessionCount', label: 'Bounced Sessions' },
                    { key: 'bounceRate', label: 'Bounce Rate' },
                    { key: 'pageViewCount', label: 'Pageviews', isDefault: true },
                    { key: 'pageViewPerSession', label: 'Pageviews / Session' },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'primaryConversionCount', label: 'Primary Conversions' },
                    { key: 'secondaryConversionCount', label: 'Secondary Conversions' },
                    { key: 'conversionRateAll', label: 'Conv Rate (All Conversions)' },
                    { key: 'conversionRatePrimary', label: 'Conv Rate (Primary Conversions)' },
                    { key: 'conversionRateSecondary', label: 'Conv Rate (Secondary Conversions)' },
               ],
          },
          [SCOPED.USER]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'landingPageOriginPath', label: 'Landing Page' },
                    { key: 'exitPageOriginPath', label: 'Exit Page' },
               ],
               properties: [],
               metrics: [
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'newUserCount', label: 'New Users' },
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'avgSessionDuration', label: 'Avg Session Duration' },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagementRate', label: 'Engagement Rate' },
                    { key: 'pageViewCount', label: 'Pageviews' },
                    { key: 'pageViewPerUser', label: 'Pageviews / User' },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'primaryConversionCount', label: 'Primary Conversions' },
                    { key: 'secondaryConversionCount', label: 'Secondary Conversions' },
                    { key: 'conversionRateAll', label: 'Conv Rate (All Conversions)' },
                    { key: 'conversionRatePrimary', label: 'Conv Rate (Primary Conversions)' },
                    { key: 'conversionRateSecondary', label: 'Conv Rate (Secondary Conversions)' },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
          [SCOPED.PEOPLE]: {
               isConversionName: true,
               isConversionNameRate: false,
               dimensions: [
                    { key: 'landingPageOriginPath', label: 'Landing Page' },
                    { key: 'exitPageOriginPath', label: 'Exit Page' },
               ],
               properties: [],
               metrics: [
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'newPeopleCount', label: 'New People' },
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'avgSessionDuration', label: 'Avg Session Duration' },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagementRate', label: 'Engagement Rate' },
                    { key: 'pageViewCount', label: 'Pageviews' },
                    { key: 'pageViewPerPerson', label: 'Pageviews / Person' },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'primaryConversionCount', label: 'Primary Conversions' },
                    { key: 'secondaryConversionCount', label: 'Secondary Conversions' },
                    { key: 'conversionRateAll', label: 'Conv Rate (All Conversions)' },
                    { key: 'conversionRatePrimary', label: 'Conv Rate (Primary Conversions)' },
                    { key: 'conversionRateSecondary', label: 'Conv Rate (Secondary Conversions)' },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
          [SCOPED.COMPANY]: {
               isConversionName: true,
               isConversionNameRate: false,
               dimensions: [
                    { key: 'landingPageOriginPath', label: 'Landing Page' },
                    { key: 'exitPageOriginPath', label: 'Exit Page' },
               ],
               properties: [],
               metrics: [
                    { key: 'revealedCompany', label: 'Revealed Companies', isDefault: true },
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'peoplePerRevealedCompany', label: 'People Per Revealed Company', isDefault: true },
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'userPerRevealedCompany', label: 'Users Per Revealed Company', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'sessionPerRevealedCompany', label: 'Session Per Revealed Company', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagedSessionPerRevealedCompany', label: 'Engaged Session Per Revealed Company', isDefault: true },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'revealedCompanyConvRate', label: 'Revealed Company Conv Rate', isDefault: true },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
     },
     [REPORT_NAMES.CONSENT_REPORT]: {
          [SCOPED.SESSION]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'consentMethod', label: 'Consent Method' },
                    { key: 'trackingLevel', label: 'Tracking Level' },
                    { key: 'regionalRule', label: 'Regional Rule' },
               ],
               properties: [],
               metrics: [
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagementRate', label: 'Engagement Rate' },
                    { key: 'bounceRate', label: 'Bounce Rate' },
                    { key: 'avgSessionDuration', label: 'Avg Session Duration' },
                    { key: 'pageViewCount', label: 'Pageviews', isDefault: true },
                    { key: 'pageViewPerSession', label: 'Pageviews / Session' },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'sessionConvRate', label: 'Session Conv Rate' },
               ],
          },
          [SCOPED.USER]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'consentMethod', label: 'Consent Method' },
                    { key: 'trackingLevel', label: 'Tracking Level' },
                    { key: 'regionalRule', label: 'Regional Rule' },
               ],
               properties: [],
               metrics: [
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'newUserCount', label: 'New Users', isDefault: true },
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'sessionPerUser', label: 'Sessions / User', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagedSessionPerUser', label: 'Engaged Sessions / User', isDefault: true },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'userConvRate', label: 'User Conv Rate', isDefault: true },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
          [SCOPED.PEOPLE]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'consentMethod', label: 'Consent Method' },
                    { key: 'trackingLevel', label: 'Tracking Level' },
                    { key: 'regionalRule', label: 'Regional Rule' },
               ],
               properties: [],
               metrics: [
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'newPeopleCount', label: 'New People', isDefault: true },
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'sessionPerPerson', label: 'Sessions / Person', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagedSessionPerPerson', label: 'Engaged Sessions / Person', isDefault: true },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'personConvRate', label: 'Person Conv Rate', isDefault: true },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
          [SCOPED.COMPANY]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'consentMethod', label: 'Consent Method' },
                    { key: 'trackingLevel', label: 'Tracking Level' },
                    { key: 'regionalRule', label: 'Regional Rule' },
               ],
               properties: [],
               metrics: [
                    { key: 'revealedCompany', label: 'Revealed Companies', isDefault: true },
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'peoplePerRevealedCompany', label: 'People Per Revealed Company', isDefault: true },
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'userPerRevealedCompany', label: 'Users Per Revealed Company', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'sessionPerRevealedCompany', label: 'Session Per Revealed Company', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagedSessionPerRevealedCompany', label: 'Engaged Session Per Revealed Company', isDefault: true },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'revealedCompanyConvRate', label: 'Revealed Company Conv Rate', isDefault: true },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
     },
     [REPORT_NAMES.GEOGRAPHY_REPORT]: {
          [SCOPED.SESSION]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'country', label: 'Country' },
                    { key: 'region', label: 'Region' },
                    { key: 'city', label: 'City' },
               ],
               properties: [],
               metrics: [
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagementRate', label: 'Engagement Rate' },
                    { key: 'bounceRate', label: 'Bounce Rate' },
                    { key: 'avgSessionDuration', label: 'Avg Session Duration' },
                    { key: 'pageViewCount', label: 'Pageviews', isDefault: true },
                    { key: 'pageViewPerSession', label: 'Pageviews / Session' },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'sessionConvRate', label: 'Session Conv Rate' },
               ],
          },
          [SCOPED.USER]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'country', label: 'Country' },
                    { key: 'region', label: 'Region' },
                    { key: 'city', label: 'City' },
               ],
               properties: [],
               metrics: [
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'newUserCount', label: 'New Users', isDefault: true },
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'sessionPerUser', label: 'Sessions / User', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagedSessionPerUser', label: 'Engaged Sessions / User', isDefault: true },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'userConvRate', label: 'User Conv Rate', isDefault: true },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
          [SCOPED.PEOPLE]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'country', label: 'Country' },
                    { key: 'region', label: 'Region' },
                    { key: 'city', label: 'City' },
               ],
               properties: [],
               metrics: [
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'newPeopleCount', label: 'New People', isDefault: true },
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'sessionPerPerson', label: 'Sessions / Person', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagedSessionPerPerson', label: 'Engaged Sessions / Person', isDefault: true },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'personConvRate', label: 'Person Conv Rate', isDefault: true },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
          [SCOPED.COMPANY]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'country', label: 'Country' },
                    { key: 'region', label: 'Region' },
                    { key: 'city', label: 'City' },
               ],
               properties: [],
               metrics: [
                    { key: 'revealedCompany', label: 'Revealed Companies', isDefault: true },
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'peoplePerRevealedCompany', label: 'People Per Revealed Company', isDefault: true },
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'userPerRevealedCompany', label: 'Users Per Revealed Company', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'sessionPerRevealedCompany', label: 'Session Per Revealed Company', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagedSessionPerRevealedCompany', label: 'Engaged Session Per Revealed Company', isDefault: true },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'revealedCompanyConvRate', label: 'Revealed Company Conv Rate', isDefault: true },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
     },
     [REPORT_NAMES.DEVICE_REPORT]: {
          [SCOPED.SESSION]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'deviceType', label: 'Device Type' },
                    { key: 'deviceFamily', label: 'Device Family' },
                    { key: 'deviceManufacturer', label: 'Manufacturer' },
               ],
               properties: [],
               metrics: [
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagementRate', label: 'Engagement Rate' },
                    { key: 'bounceRate', label: 'Bounce Rate' },
                    { key: 'avgSessionDuration', label: 'Avg Session Duration' },
                    { key: 'pageViewCount', label: 'Pageviews', isDefault: true },
                    { key: 'pageViewPerSession', label: 'Pageviews / Session' },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'sessionConvRate', label: 'Session Conv Rate' },
               ],
          },
          [SCOPED.USER]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'deviceType', label: 'Device Type' },
                    { key: 'deviceFamily', label: 'Device Family' },
                    { key: 'deviceManufacturer', label: 'Manufacturer' },
               ],
               properties: [],
               metrics: [
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'newUserCount', label: 'New Users', isDefault: true },
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'sessionPerUser', label: 'Sessions / User', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagedSessionPerUser', label: 'Engaged Sessions / User', isDefault: true },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'userConvRate', label: 'User Conv Rate', isDefault: true },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
          [SCOPED.PEOPLE]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'deviceType', label: 'Device Type' },
                    { key: 'deviceFamily', label: 'Device Family' },
                    { key: 'deviceManufacturer', label: 'Manufacturer' },
               ],
               properties: [],
               metrics: [
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'newPeopleCount', label: 'New People', isDefault: true },
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'sessionPerPerson', label: 'Sessions / Person', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagedSessionPerPerson', label: 'Engaged Sessions / Person', isDefault: true },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'personConvRate', label: 'Person Conv Rate', isDefault: true },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
          [SCOPED.COMPANY]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'deviceType', label: 'Device Type' },
                    { key: 'deviceFamily', label: 'Device Family' },
                    { key: 'deviceManufacturer', label: 'Manufacturer' },
               ],
               properties: [],
               metrics: [
                    { key: 'revealedCompany', label: 'Revealed Companies', isDefault: true },
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'peoplePerRevealedCompany', label: 'People Per Revealed Company', isDefault: true },
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'userPerRevealedCompany', label: 'Users Per Revealed Company', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'sessionPerRevealedCompany', label: 'Session Per Revealed Company', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagedSessionPerRevealedCompany', label: 'Engaged Session Per Revealed Company', isDefault: true },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'revealedCompanyConvRate', label: 'Revealed Company Conv Rate', isDefault: true },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
     },
     [REPORT_NAMES.BROWSER_REPORT]: {
          [SCOPED.SESSION]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'browserFamily', label: 'Browser Family' },
                    { key: 'browserVersion', label: 'Browser Version (general)' },
                    { key: 'browserDetailedVersion', label: 'Browser Version (specific)' },
               ],
               properties: [],
               metrics: [
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagementRate', label: 'Engagement Rate' },
                    { key: 'bounceRate', label: 'Bounce Rate' },
                    { key: 'avgSessionDuration', label: 'Avg Session Duration' },
                    { key: 'pageViewCount', label: 'Pageviews', isDefault: true },
                    { key: 'pageViewPerSession', label: 'Pageviews / Session' },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'sessionConvRate', label: 'Session Conv Rate' },
               ],
          },
          [SCOPED.USER]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'browserFamily', label: 'Browser Family' },
                    { key: 'browserVersion', label: 'Browser Version (general)' },
                    { key: 'browserDetailedVersion', label: 'Browser Version (specific)' },
               ],
               properties: [],
               metrics: [
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'newUserCount', label: 'New Users', isDefault: true },
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'sessionPerUser', label: 'Sessions / User', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagedSessionPerUser', label: 'Engaged Sessions / User', isDefault: true },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'userConvRate', label: 'User Conv Rate', isDefault: true },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
          [SCOPED.PEOPLE]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'browserFamily', label: 'Browser Family' },
                    { key: 'browserVersion', label: 'Browser Version (general)' },
                    { key: 'browserDetailedVersion', label: 'Browser Version (specific)' },
               ],
               properties: [],
               metrics: [
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'newPeopleCount', label: 'New People', isDefault: true },
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'sessionPerPerson', label: 'Sessions / Person', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagedSessionPerPerson', label: 'Engaged Sessions / Person', isDefault: true },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'personConvRate', label: 'Person Conv Rate', isDefault: true },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
          [SCOPED.COMPANY]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'browserFamily', label: 'Browser Family' },
                    { key: 'browserVersion', label: 'Browser Version (general)' },
                    { key: 'browserDetailedVersion', label: 'Browser Version (specific)' },
               ],
               properties: [],
               metrics: [
                    { key: 'revealedCompany', label: 'Revealed Companies', isDefault: true },
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'peoplePerRevealedCompany', label: 'People Per Revealed Company', isDefault: true },
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'userPerRevealedCompany', label: 'Users Per Revealed Company', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'sessionPerRevealedCompany', label: 'Session Per Revealed Company', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagedSessionPerRevealedCompany', label: 'Engaged Session Per Revealed Company', isDefault: true },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'revealedCompanyConvRate', label: 'Revealed Company Conv Rate', isDefault: true },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
     },
     [REPORT_NAMES.ALL_PAGE_REPORT]: {
          [SCOPED.SESSION]: {
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'originPath', label: 'All Page OriginPath' },
                    { key: 'path', label: 'All Page Path' },
                    { key: 'hostName', label: 'All Page Hostname' },
               ],
               defaultDimensions: [{ key: 'originPath', label: 'All Page OriginPath' }],
               properties: [],
               metrics: [
                    { key: 'pageViewCount', label: 'Pageviews', isDefault: true },
                    { key: 'pageViewPerSession', label: 'Pageviews / Session', isDefault: true },
                    { key: 'pageViewPerUser', label: 'Pageviews / User', isDefault: true },
                    { key: 'avgVisibleTimeOnPage', label: 'Avg Visible Time on Page' },
                    { key: 'entrances', label: 'Entrances', isDefault: true },
                    { key: 'exits', label: 'Exits', isDefault: true },
                    { key: 'bouncedSessionCount', label: 'Bounced', isDefault: true },
                    { key: 'bounceRate', label: 'Bounce Rate', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'revealedCompany', label: 'Revealed Companies', isDefault: true },
               ],
          },
     },
     [REPORT_NAMES.LANDING_PAGE_REPORT]: {
          [SCOPED.SESSION]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'landingPageOriginPath', label: 'Landing Page OriginPath' },
                    { key: 'path', label: 'Landing Page Path' },
                    { key: 'hostName', label: 'Landing Page Hostname' },
               ],
               defaultDimensions: [{ key: 'landingPageOriginPath', label: 'Landing Page OriginPath' }],
               properties: [],
               metrics: [
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'revealedCompany', label: 'Revealed Companies', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagementRate', label: 'Engagement Rate', isDefault: true },
                    { key: 'bounceRate', label: 'Bounce Rate', isDefault: true },
                    { key: 'avgSessionDuration', label: 'Avg Session Duration' },
                    { key: 'pageViewCount', label: 'Pageviews', isDefault: true },
                    { key: 'pageViewPerSession', label: 'Pageviews / Session', isDefault: true },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'sessionConvRate', label: 'Session Conv Rate', isDefault: true },
               ],
          },
          [SCOPED.USER]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'landingPageOriginPath', label: 'Landing Page OriginPath' },
                    { key: 'path', label: 'Landing Page Path' },
                    { key: 'hostName', label: 'Landing Page Hostname' },
               ],
               defaultDimensions: [{ key: 'landingPageOriginPath', label: 'Landing Page OriginPath' }],
               properties: [],
               metrics: [
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'sessionPerUser', label: 'Sessions / User', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagedSessionPerUser', label: 'Engaged Sessions / User', isDefault: true },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'userConvRate', label: 'User Conv Rate', isDefault: true },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
          [SCOPED.PEOPLE]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'landingPageOriginPath', label: 'Landing Page OriginPath' },
                    { key: 'path', label: 'Landing Page Path' },
                    { key: 'hostName', label: 'Landing Page Hostname' },
               ],
               defaultDimensions: [{ key: 'landingPageOriginPath', label: 'Landing Page OriginPath' }],
               properties: [],
               metrics: [
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'revealedCompany', label: 'Revealed Companies', isDefault: true },
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'sessionPerPerson', label: 'Sessions / Person', isDefault: true },
                    { key: 'sessionPerUser', label: 'Sessions / User', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagedSessionPerPerson', label: 'Engaged Sessions / Person', isDefault: true },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'personConvRate', label: 'Person Conv Rate', isDefault: true },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
          [SCOPED.COMPANY]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'landingPageOriginPath', label: 'Landing Page OriginPath' },
                    { key: 'path', label: 'Landing Page Path' },
                    { key: 'hostName', label: 'Landing Page Hostname' },
               ],
               defaultDimensions: [{ key: 'landingPageOriginPath', label: 'Landing Page OriginPath' }],
               properties: [],
               metrics: [
                    { key: 'revealedCompany', label: 'Revealed Companies', isDefault: true },
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'peoplePerRevealedCompany', label: 'People Per Revealed Company', isDefault: true },
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'userPerRevealedCompany', label: 'Users Per Revealed Company', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'sessionPerRevealedCompany', label: 'Session Per Revealed Company', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagedSessionPerRevealedCompany', label: 'Engaged Session Per Revealed Company', isDefault: true },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'revealedCompanyConvRate', label: 'Revealed Company Conv Rate', isDefault: true },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
     },
     [REPORT_NAMES.EXIT_PAGE_REPORT]: {
          [SCOPED.SESSION]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'exitPageOriginPath', label: 'Exit Page OriginPath' },
                    { key: 'path', label: 'Exit Page Path' },
                    { key: 'hostName', label: 'Exit Page Hostname' },
               ],
               defaultDimensions: [{ key: 'exitPageOriginPath', label: 'Exit Page OriginPath' }],
               properties: [],
               metrics: [
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'revealedCompany', label: 'Revealed Companies', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagementRate', label: 'Engagement Rate', isDefault: true },
                    { key: 'bounceRate', label: 'Bounce Rate', isDefault: true },
                    { key: 'avgSessionDuration', label: 'Avg Session Duration' },
                    { key: 'pageViewCount', label: 'Pageviews', isDefault: true },
                    { key: 'pageViewPerSession', label: 'Pageviews / Session', isDefault: true },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'sessionConvRate', label: 'Session Conv Rate', isDefault: true },
               ],
          },
          [SCOPED.USER]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'exitPageOriginPath', label: 'Exit Page OriginPath' },
                    { key: 'path', label: 'Exit Page Path' },
                    { key: 'hostName', label: 'Exit Page Hostname' },
               ],
               defaultDimensions: [{ key: 'exitPageOriginPath', label: 'Exit Page OriginPath' }],
               properties: [],
               metrics: [
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'sessionPerUser', label: 'Sessions / User', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagedSessionPerUser', label: 'Engaged Sessions / User', isDefault: true },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'userConvRate', label: 'User Conv Rate', isDefault: true },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
          [SCOPED.PEOPLE]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'exitPageOriginPath', label: 'Exit Page OriginPath' },
                    { key: 'path', label: 'Exit Page Path' },
                    { key: 'hostName', label: 'Exit Page Hostname' },
               ],
               defaultDimensions: [{ key: 'exitPageOriginPath', label: 'Exit Page OriginPath' }],
               properties: [],
               metrics: [
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'revealedCompany', label: 'Revealed Companies', isDefault: true },
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'sessionPerPerson', label: 'Sessions / Person', isDefault: true },
                    { key: 'sessionPerUser', label: 'Sessions / User', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagedSessionPerPerson', label: 'Engaged Sessions / Person', isDefault: true },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'personConvRate', label: 'Person Conv Rate', isDefault: true },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
          [SCOPED.COMPANY]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'exitPageOriginPath', label: 'Exit Page OriginPath' },
                    { key: 'path', label: 'Exit Page Path' },
                    { key: 'hostName', label: 'Exit Page Hostname' },
               ],
               defaultDimensions: [{ key: 'exitPageOriginPath', label: 'Exit Page OriginPath' }],
               properties: [],
               metrics: [
                    { key: 'revealedCompany', label: 'Revealed Companies', isDefault: true },
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'peoplePerRevealedCompany', label: 'People Per Revealed Company', isDefault: true },
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'userPerRevealedCompany', label: 'Users Per Revealed Company', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'sessionPerRevealedCompany', label: 'Session Per Revealed Company', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagedSessionPerRevealedCompany', label: 'Engaged Session Per Revealed Company', isDefault: true },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'revealedCompanyConvRate', label: 'Revealed Company Conv Rate', isDefault: true },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
     },
     [REPORT_NAMES.CHANNEL_REPORT]: {
          [SCOPED.SESSION]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [{ key: 'channel', label: 'Channel' }],
               defaultDimensions: [{ key: 'channel', label: 'Channel' }],
               properties: [],
               metrics: [
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'revealedCompany', label: 'Revealed Companies', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagementRate', label: 'Engagement Rate' },
                    { key: 'bounceRate', label: 'Bounce Rate' },
                    { key: 'avgSessionDuration', label: 'Avg Session Duration' },
                    { key: 'pageViewCount', label: 'Pageviews', isDefault: true },
                    { key: 'pageViewPerSession', label: 'Pageviews / Session' },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'sessionConvRate', label: 'Session Conv Rate' },
               ],
          },
          [SCOPED.USER]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'channelFirst', label: 'Channel - First' },
                    { key: 'channelLast', label: 'Channel - Last' },
                    { key: 'channelPath', label: 'Channel - Path' },
               ],
               defaultDimensions: [
                    { key: 'channelFirst', label: 'Channel - First' },
                    { key: 'channelLast', label: 'Channel - Last' },
               ],
               properties: [],
               metrics: [
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'newUserCount', label: 'New Users', isDefault: true },
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'revealedCompany', label: 'Revealed Companies', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'sessionPerUser', label: 'Sessions / User', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagedSessionPerUser', label: 'Engaged Sessions / User', isDefault: true },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'userConvRate', label: 'User Conv Rate', isDefault: true },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
          [SCOPED.PEOPLE]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'channelFirst', label: 'Channel - First' },
                    { key: 'channelLast', label: 'Channel - Last' },
                    { key: 'channelPath', label: 'Channel - Path' },
               ],
               defaultDimensions: [
                    { key: 'channelFirst', label: 'Channel - First' },
                    { key: 'channelLast', label: 'Channel - Last' },
               ],
               properties: [],
               metrics: [
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'newPeopleCount', label: 'New People', isDefault: true },
                    { key: 'revealedCompany', label: 'Revealed Companies', isDefault: true },
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'sessionPerPerson', label: 'Sessions / Person', isDefault: true },
                    { key: 'sessionPerUser', label: 'Sessions / User', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagedSessionPerPerson', label: 'Engaged Sessions / Person', isDefault: true },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'personConvRate', label: 'Person Conv Rate', isDefault: true },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
          [SCOPED.COMPANY]: {
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'channelFirst', label: 'Channel - First' },
                    { key: 'channelLast', label: 'Channel - Last' },
                    { key: 'channelPath', label: 'Channel - Path' },
               ],
               defaultDimensions: [
                    { key: 'channelFirst', label: 'Channel - First' },
                    { key: 'channelLast', label: 'Channel - Last' },
               ],
               properties: [],
               metrics: [
                    { key: 'revealedCompany', label: 'Revealed Companies', isDefault: true },
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'peoplePerRevealedCompany', label: 'People Per Revealed Company', isDefault: true },
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'userPerRevealedCompany', label: 'Users Per Revealed Company', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'sessionPerRevealedCompany', label: 'Session Per Revealed Company', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagedSessionPerRevealedCompany', label: 'Engaged Session Per Revealed Company', isDefault: true },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'revealedCompanyConvRate', label: 'Revealed Company Conv Rate', isDefault: true },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
     },
     [REPORT_NAMES.SOURCE_REPORT]: {
          [SCOPED.SESSION]: {
               isConversionName: true,
               isConversionNameRate: false,
               dimensions: [{ key: 'userSource', label: 'User Source' }],
               defaultDimensions: [{ key: 'userSource', label: 'User Source' }],
               isDefaultMetrics: true,
               properties: [],
               metrics: [
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'revealedCompany', label: 'Revealed Companies', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagementRate', label: 'Engagement Rate' },
                    { key: 'bounceRate', label: 'Bounce Rate' },
                    { key: 'avgSessionDuration', label: 'Avg Session Duration' },
                    { key: 'pageViewCount', label: 'Pageviews', isDefault: true },
                    { key: 'pageViewPerSession', label: 'Pageviews / Session' },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'sessionConvRate', label: 'Session Conv Rate' },
               ],
          },
          [SCOPED.USER]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'userSourceFirst', label: 'User Source - First' },
                    { key: 'userSourceLast', label: 'User Source - Last' },
                    { key: 'userSourcePath', label: 'User Source - Path' },
               ],
               defaultDimensions: [
                    { key: 'userSourceFirst', label: 'User Source - First' },
                    { key: 'userSourceLast', label: 'User Source - Last' },
               ],
               properties: [],
               metrics: [
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'newUserCount', label: 'New Users', isDefault: true },
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'revealedCompany', label: 'Revealed Companies', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'sessionPerUser', label: 'Sessions / User', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagedSessionPerUser', label: 'Engaged Sessions / User', isDefault: true },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'userConvRate', label: 'User Conv Rate', isDefault: true },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
          [SCOPED.PEOPLE]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'userSourceFirst', label: 'User Source - First' },
                    { key: 'userSourceLast', label: 'User Source - Last' },
                    { key: 'userSourcePath', label: 'User Source - Path' },
               ],
               defaultDimensions: [
                    { key: 'userSourceFirst', label: 'User Source - First' },
                    { key: 'userSourceLast', label: 'User Source - Last' },
               ],
               properties: [],
               metrics: [
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'newPeopleCount', label: 'New People', isDefault: true },
                    { key: 'revealedCompany', label: 'Revealed Companies', isDefault: true },
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'sessionPerPerson', label: 'Sessions / Person', isDefault: true },
                    { key: 'sessionPerUser', label: 'Sessions / User', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagedSessionPerPerson', label: 'Engaged Sessions / Person', isDefault: true },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'personConvRate', label: 'Person Conv Rate', isDefault: true },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
          [SCOPED.COMPANY]: {
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'userSourceFirst', label: 'User Source - First' },
                    { key: 'userSourceLast', label: 'User Source - Last' },
                    { key: 'userSourcePath', label: 'User Source - Path' },
               ],
               defaultDimensions: [
                    { key: 'userSourceFirst', label: 'User Source - First' },
                    { key: 'userSourceLast', label: 'User Source - Last' },
               ],
               properties: [],
               metrics: [
                    { key: 'revealedCompany', label: 'Revealed Companies', isDefault: true },
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'peoplePerRevealedCompany', label: 'People Per Revealed Company', isDefault: true },
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'userPerRevealedCompany', label: 'Users Per Revealed Company', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'sessionPerRevealedCompany', label: 'Session Per Revealed Company', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagedSessionPerRevealedCompany', label: 'Engaged Session Per Revealed Company', isDefault: true },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'revealedCompanyConvRate', label: 'Revealed Company Conv Rate', isDefault: true },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
     },
     [REPORT_NAMES.UTM_REPORT]: {
          [SCOPED.SESSION]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'utmSource', label: 'utm_source' },
                    { key: 'utmMedium', label: 'utm_medium' },
                    { key: 'utmSourceMedium', label: 'utm_source / utm_medium' },
                    { key: 'utmCampaign', label: 'utm_campaign' },
                    { key: 'utmTerm', label: 'utm_term' },
                    { key: 'utmContent', label: 'utm_content' },
               ],
               defaultDimensions: [
                    { key: 'utmSource', label: 'utm_source' },
                    { key: 'utmMedium', label: 'utm_medium' },
               ],
               properties: [],
               metrics: [
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'revealedCompany', label: 'Revealed Companies', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagementRate', label: 'Engagement Rate' },
                    { key: 'bounceRate', label: 'Bounce Rate' },
                    { key: 'avgSessionDuration', label: 'Avg Session Duration' },
                    { key: 'pageViewCount', label: 'Pageviews', isDefault: true },
                    { key: 'pageViewPerSession', label: 'Pageviews / Session' },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'sessionConvRate', label: 'Session Conv Rate' },
               ],
          },
          [SCOPED.USER]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'utmSource', label: 'utm_source' },
                    { key: 'utmMedium', label: 'utm_medium' },
                    { key: 'utmSourceMedium', label: 'utm_source / utm_medium' },
                    { key: 'utmCampaign', label: 'utm_campaign' },
                    { key: 'utmTerm', label: 'utm_term' },
                    { key: 'utmContent', label: 'utm_content' },
               ],
               properties: [],
               metrics: [
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'newUserCount', label: 'New Users', isDefault: true },
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'revealedCompany', label: 'Revealed Companies', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'sessionPerUser', label: 'Sessions / User', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagedSessionPerUser', label: 'Engaged Sessions / User', isDefault: true },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'userConvRate', label: 'User Conv Rate', isDefault: true },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
          [SCOPED.PEOPLE]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'utmSource', label: 'utm_source' },
                    { key: 'utmMedium', label: 'utm_medium' },
                    { key: 'utmSourceMedium', label: 'utm_source / utm_medium' },
                    { key: 'utmCampaign', label: 'utm_campaign' },
                    { key: 'utmTerm', label: 'utm_term' },
                    { key: 'utmContent', label: 'utm_content' },
               ],
               properties: [],
               metrics: [
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'newPeopleCount', label: 'New People', isDefault: true },
                    { key: 'revealedCompany', label: 'Revealed Companies', isDefault: true },
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'sessionPerPerson', label: 'Sessions / Person', isDefault: true },
                    { key: 'sessionPerUser', label: 'Sessions / User', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagedSessionPerPerson', label: 'Engaged Sessions / Person', isDefault: true },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'personConvRate', label: 'Person Conv Rate', isDefault: true },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
          [SCOPED.COMPANY]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'utmSource', label: 'utm_source' },
                    { key: 'utmMedium', label: 'utm_medium' },
                    { key: 'utmSourceMedium', label: 'utm_source / utm_medium' },
                    { key: 'utmCampaign', label: 'utm_campaign' },
                    { key: 'utmTerm', label: 'utm_term' },
                    { key: 'utmContent', label: 'utm_content' },
               ],
               properties: [],
               metrics: [
                    { key: 'revealedCompany', label: 'Revealed Companies', isDefault: true },
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'peoplePerRevealedCompany', label: 'People Per Revealed Company', isDefault: true },
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'userPerRevealedCompany', label: 'Users Per Revealed Company', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'sessionPerRevealedCompany', label: 'Session Per Revealed Company', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagedSessionPerRevealedCompany', label: 'Engaged Session Per Revealed Company', isDefault: true },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'revealedCompanyConvRate', label: 'Revealed Company Conv Rate', isDefault: true },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
     },
     [REPORT_NAMES.REFERRALS_REPORT]: {
          [SCOPED.SESSION]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'referrerOriginPath', label: 'Referring OriginPath' },
                    { key: 'referrerHostname', label: 'Referring Hostname' },
                    { key: 'referrerPath', label: 'Referring PathName' },
               ],
               properties: [],
               metrics: [
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'revealedCompany', label: 'Revealed Companies', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagementRate', label: 'Engagement Rate' },
                    { key: 'bounceRate', label: 'Bounce Rate' },
                    { key: 'avgSessionDuration', label: 'Avg Session Duration' },
                    { key: 'pageViewCount', label: 'Pageviews', isDefault: true },
                    { key: 'pageViewPerSession', label: 'Pageviews / Session' },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'sessionConvRate', label: 'Session Conv Rate' },
               ],
          },
          [SCOPED.USER]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'referrerOriginPath', label: 'Referring OriginPath' },
                    { key: 'referrerHostname', label: 'Referring Hostname' },
                    { key: 'referrerPath', label: 'Referring PathName' },
               ],
               properties: [],
               metrics: [
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'newUserCount', label: 'New Users', isDefault: true },
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'revealedCompany', label: 'Revealed Companies', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'sessionPerUser', label: 'Sessions / User', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagedSessionPerUser', label: 'Engaged Sessions / User', isDefault: true },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'userConvRate', label: 'User Conv Rate', isDefault: true },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
          [SCOPED.PEOPLE]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'referrerOriginPath', label: 'Referring OriginPath' },
                    { key: 'referrerHostname', label: 'Referring Hostname' },
                    { key: 'referrerPath', label: 'Referring PathName' },
               ],
               properties: [],
               metrics: [
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'newPeopleCount', label: 'New People', isDefault: true },
                    { key: 'revealedCompany', label: 'Revealed Companies', isDefault: true },
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'sessionPerPerson', label: 'Sessions / Person', isDefault: true },
                    { key: 'sessionPerUser', label: 'Sessions / User', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagedSessionPerPerson', label: 'Engaged Sessions / Person', isDefault: true },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'personConvRate', label: 'Person Conv Rate', isDefault: true },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
          [SCOPED.COMPANY]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'referrerOriginPath', label: 'Referring OriginPath' },
                    { key: 'referrerHostname', label: 'Referring Hostname' },
                    { key: 'referrerPath', label: 'Referring PathName' },
               ],
               properties: [],
               metrics: [
                    { key: 'revealedCompany', label: 'Revealed Companies', isDefault: true },
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'peoplePerRevealedCompany', label: 'People Per Revealed Company', isDefault: true },
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'userPerRevealedCompany', label: 'Users Per Revealed Company', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'sessionPerRevealedCompany', label: 'Session Per Revealed Company', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagedSessionPerRevealedCompany', label: 'Engaged Session Per Revealed Company', isDefault: true },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'revealedCompanyConvRate', label: 'Revealed Company Conv Rate', isDefault: true },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
     },
     [REPORT_NAMES.NEW_RETURNING_REPORT]: {
          [SCOPED.USER]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [{ key: 'userType', label: 'User Type' }],
               properties: [],
               metrics: [
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    // { key: 'revealedCompany', label: 'Revealed Companies' },
                    { key: 'sessionPerUser', label: 'Sessions / User', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagedSessionPerUser', label: 'Engaged Sessions / User', isDefault: true },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'userConvRate', label: 'User Conv Rate', isDefault: true },
               ],
          },
          [SCOPED.PEOPLE]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [{ key: 'personType', label: 'Person Type' }],
               properties: [],
               metrics: [
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'revealedCompany', label: 'Revealed Companies', isDefault: true },
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'sessionPerPerson', label: 'Sessions / Person', isDefault: true },
                    { key: 'sessionPerUser', label: 'Sessions / User', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagedSessionPerPerson', label: 'Engaged Sessions / Person', isDefault: true },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'personConvRate', label: 'Person Conv Rate', isDefault: true },
               ],
          },
          [SCOPED.COMPANY]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [{ key: 'revealedCompanyType', label: 'Revealed Company Type' }],
               properties: [],
               metrics: [
                    { key: 'revealedCompany', label: 'Revealed Companies', isDefault: true },
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'peoplePerRevealedCompany', label: 'People Per Revealed Company', isDefault: true },
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'userPerRevealedCompany', label: 'Users Per Revealed Company', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'sessionPerRevealedCompany', label: 'Session Per Revealed Company', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagedSessionPerRevealedCompany', label: 'Engaged Session Per Revealed Company', isDefault: true },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'revealedCompanyConvRate', label: 'Revealed Company Conv Rate', isDefault: true },
               ],
          },
     },

     [REPORT_NAMES.SESSION_COUNT_REPORT]: {
          [SCOPED.USER]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [{ key: 'sessionCountRow', label: 'Session Count' }],
               properties: [],
               metrics: [
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    // { key: 'revealedCompany', label: 'Revealed Companies', isDefault: true },
                    { key: 'sessionPerUser', label: 'Sessions / User', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagedSessionPerUser', label: 'Engaged Sessions / User', isDefault: true },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'userConvRate', label: 'User Conv Rate', isDefault: true },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
          [SCOPED.PEOPLE]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [{ key: 'sessionCountRow', label: 'Session Count' }],
               properties: [],
               metrics: [
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'revealedCompany', label: 'Revealed Companies', isDefault: true },
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'sessionPerPerson', label: 'Sessions / Person', isDefault: true },
                    { key: 'sessionPerUser', label: 'Sessions / User', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagedSessionPerPerson', label: 'Engaged Sessions / Person', isDefault: true },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'personConvRate', label: 'Person Conv Rate', isDefault: true },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
          [SCOPED.COMPANY]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [{ key: 'sessionCountRow', label: 'Session Count' }],
               properties: [],
               metrics: [
                    { key: 'revealedCompany', label: 'Revealed Companies', isDefault: true },
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'peoplePerRevealedCompany', label: 'People Per Revealed Company', isDefault: true },
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'userPerRevealedCompany', label: 'Users Per Revealed Company', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'sessionPerRevealedCompany', label: 'Session Per Revealed Company', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagedSessionPerRevealedCompany', label: 'Engaged Session Per Revealed Company', isDefault: true },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'revealedCompanyConvRate', label: 'Revealed Company Conv Rate', isDefault: true },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
     },

     [REPORT_NAMES.SESSION_DURATION_REPORT]: {
          [SCOPED.SESSION]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [{ key: 'totalSessionDurationType', label: 'Total Session Duration' }],
               properties: [],
               metrics: [
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'revealedCompany', label: 'Revealed Companies', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagementRate', label: 'Engagement Rate' },
                    { key: 'bounceRate', label: 'Bounce Rate' },
                    { key: 'avgSessionDuration', label: 'Avg Session Duration', isDefault: true },
                    { key: 'pageViewCount', label: 'Pageviews', isDefault: true },
                    { key: 'pageViewPerSession', label: 'Pageviews / Session' },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'sessionConvRate', label: 'Session Conv Rate' },
               ],
          },
          [SCOPED.USER]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [{ key: 'totalSessionDurationType', label: 'Total Session Duration' }],
               properties: [],
               metrics: [
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    // { key: 'revealedCompany', label: 'Revealed Companies' },
                    { key: 'sessionPerUser', label: 'Sessions / User', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagedSessionPerUser', label: 'Engaged Sessions / User', isDefault: true },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'userConvRate', label: 'User Conv Rate', isDefault: true },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
          [SCOPED.PEOPLE]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [{ key: 'totalSessionDurationType', label: 'Total Session Duration' }],
               properties: [],
               metrics: [
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'revealedCompany', label: 'Revealed Companies', isDefault: true },
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'sessionPerPerson', label: 'Sessions / Person', isDefault: true },
                    { key: 'sessionPerUser', label: 'Sessions / User', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagedSessionPerPerson', label: 'Engaged Sessions / Person', isDefault: true },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'personConvRate', label: 'Person Conv Rate', isDefault: true },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
          [SCOPED.COMPANY]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [{ key: 'totalSessionDurationType', label: 'Total Session Duration' }],
               properties: [],
               metrics: [
                    { key: 'revealedCompany', label: 'Revealed Companies', isDefault: true },
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'peoplePerRevealedCompany', label: 'People Per Revealed Company', isDefault: true },
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'userPerRevealedCompany', label: 'Users Per Revealed Company', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'sessionPerRevealedCompany', label: 'Session Per Revealed Company', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagedSessionPerRevealedCompany', label: 'Engaged Session Per Revealed Company', isDefault: true },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'revealedCompanyConvRate', label: 'Revealed Company Conv Rate', isDefault: true },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
     },
     [REPORT_NAMES.CONVERSION_COUNT_REPORT]: {
          [SCOPED.SESSION]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [{ key: 'conversionCountRow', label: 'Conversion Count' }],
               properties: [],
               metrics: [
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'revealedCompany', label: 'Revealed Companies', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagementRate', label: 'Engagement Rate' },
                    { key: 'bounceRate', label: 'Bounce Rate' },
                    { key: 'avgSessionDuration', label: 'Avg Session Duration' },
                    { key: 'pageViewCount', label: 'Pageviews', isDefault: true },
                    { key: 'pageViewPerSession', label: 'Pageviews / Session' },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'sessionConvRate', label: 'Session Conv Rate' },
               ],
          },
          [SCOPED.USER]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [{ key: 'conversionCountRow', label: 'Conversion Count' }],
               properties: [],
               metrics: [
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    // { key: 'revealedCompany', label: 'Revealed Companies' },
                    { key: 'sessionPerUser', label: 'Sessions / User', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagedSessionPerUser', label: 'Engaged Sessions / User', isDefault: true },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'userConvRate', label: 'User Conv Rate', isDefault: true },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
          [SCOPED.PEOPLE]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [{ key: 'conversionCountRow', label: 'Conversion Count' }],
               properties: [],
               metrics: [
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'revealedCompany', label: 'Revealed Companies', isDefault: true },
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'sessionPerPerson', label: 'Sessions / Person', isDefault: true },
                    { key: 'sessionPerUser', label: 'Sessions / User', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagedSessionPerPerson', label: 'Engaged Sessions / Person', isDefault: true },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'personConvRate', label: 'Person Conv Rate', isDefault: true },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
          [SCOPED.COMPANY]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [{ key: 'conversionCountRow', label: 'Conversion Count' }],
               properties: [],
               metrics: [
                    { key: 'revealedCompany', label: 'Revealed Companies', isDefault: true },
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'peoplePerRevealedCompany', label: 'People Per Revealed Company', isDefault: true },
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'userPerRevealedCompany', label: 'Users Per Revealed Company', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'sessionPerRevealedCompany', label: 'Session Per Revealed Company', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagedSessionPerRevealedCompany', label: 'Engaged Session Per Revealed Company', isDefault: true },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'revealedCompanyConvRate', label: 'Revealed Company Conv Rate', isDefault: true },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
     },
     // block company at subscriber
     [REPORT_NAMES.PEOPLE_AND_USER_COUNT_REPORT]: {
          [SCOPED.COMPANY]: {
               isConversionName: true,
               isConversionNameRate: false,
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'peopleCountRow', label: 'People Count' },
                    { key: 'userCountRow', label: 'User Count' },
               ],
               properties: [],
               metrics: [
                    { key: 'revealedCompany', label: 'Revealed Companies', isDefault: true },
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'peoplePerRevealedCompany', label: 'People Per Revealed Company', isDefault: true },
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'userPerRevealedCompany', label: 'Users Per Revealed Company', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'sessionPerRevealedCompany', label: 'Session Per Revealed Company', isDefault: true },
                    { key: 'engagedSessionCount', label: 'Engaged Sessions', isDefault: true },
                    { key: 'engagedSessionPerRevealedCompany', label: 'Engaged Session Per Revealed Company', isDefault: true },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'revealedCompanyConvRate', label: 'Revealed Company Conv Rate', isDefault: true },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
     },
     [REPORT_NAMES.BY_URL_REPORT]: {
          [SCOPED.SESSION]: {
               isConversionName: true,
               isConversionNameRate: true,
               isDefaultMetrics: true,
               dimensions: [{ key: 'conversionByUrl', label: 'Conversion Location URL' }],
               properties: [],
               metrics: [
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'primaryConversionCount', label: 'Primary Conversions', isDefault: true },
                    { key: 'secondaryConversionCount', label: 'Secondary Conversions', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'conversionRateAll', label: 'Conv Rate (All Conversions)', isDefault: true },
                    { key: 'conversionRatePrimary', label: 'Conv Rate (Primary Conversions)', isDefault: true },
                    { key: 'conversionRateSecondary', label: 'Conv Rate (Secondary Conversions)', isDefault: true },
               ],
          },
     },
     [REPORT_NAMES.BY_LANDING_PAGE_REPORT]: {
          [SCOPED.SESSION]: {
               isConversionName: true,
               isConversionNameRate: true,
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'landingPageOriginPath', label: 'Landing Page OriginPath' },
                    { key: 'path', label: 'Landing Page Path' },
                    { key: 'hostName', label: 'Landing Page Hostname' },
               ],
               defaultDimensions: [{ key: 'landingPageOriginPath', label: 'Landing Page OriginPath' }],
               properties: [],
               metrics: [
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'primaryConversionCount', label: 'Primary Conversions', isDefault: true },
                    { key: 'secondaryConversionCount', label: 'Secondary Conversions', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'conversionRateAll', label: 'Conv Rate (All Conversions)', isDefault: true },
                    { key: 'conversionRatePrimary', label: 'Conv Rate (Primary Conversions)', isDefault: true },
                    { key: 'conversionRateSecondary', label: 'Conv Rate (Secondary Conversions)', isDefault: true },
               ],
          },
          [SCOPED.USER]: {
               isConversionName: true,
               isConversionNameRate: true,
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'landingPageOriginPath', label: 'Landing Page OriginPath' },
                    { key: 'path', label: 'Landing Page Path' },
                    { key: 'hostName', label: 'Landing Page Hostname' },
               ],
               defaultDimensions: [{ key: 'landingPageOriginPath', label: 'Landing Page OriginPath' }],
               properties: [],
               metrics: [
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'primaryConversionCount', label: 'Primary Conversions', isDefault: true },
                    { key: 'secondaryConversionCount', label: 'Secondary Conversions', isDefault: true },
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'conversionRateAll', label: 'Conv Rate (All Conversions)', isDefault: true },
                    { key: 'conversionRatePrimary', label: 'Conv Rate (Primary Conversions)', isDefault: true },
                    { key: 'conversionRateSecondary', label: 'Conv Rate (Secondary Conversions)', isDefault: true },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
          [SCOPED.PEOPLE]: {
               isConversionName: true,
               isConversionNameRate: true,
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'landingPageOriginPath', label: 'Landing Page OriginPath' },
                    { key: 'path', label: 'Landing Page Path' },
                    { key: 'hostName', label: 'Landing Page Hostname' },
               ],
               defaultDimensions: [{ key: 'landingPageOriginPath', label: 'Landing Page OriginPath' }],
               properties: [],
               metrics: [
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'primaryConversionCount', label: 'Primary Conversions', isDefault: true },
                    { key: 'secondaryConversionCount', label: 'Secondary Conversions', isDefault: true },
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'conversionRateAll', label: 'Conv Rate (All Conversions)', isDefault: true },
                    { key: 'conversionRatePrimary', label: 'Conv Rate (Primary Conversions)', isDefault: true },
                    { key: 'conversionRateSecondary', label: 'Conv Rate (Secondary Conversions)', isDefault: true },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
          [SCOPED.COMPANY]: {
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'landingPageOriginPath', label: 'Landing Page OriginPath' },
                    { key: 'path', label: 'Landing Page Path' },
                    { key: 'hostName', label: 'Landing Page Hostname' },
               ],
               defaultDimensions: [{ key: 'landingPageOriginPath', label: 'Landing Page OriginPath' }],
               properties: [],
               metrics: [
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'primaryConversionCount', label: 'Primary Conversions', isDefault: true },
                    { key: 'secondaryConversionCount', label: 'Secondary Conversions', isDefault: true },
                    { key: 'revealedCompany', label: 'Revealed Companies', isDefault: true },
                    { key: 'conversionRateAll', label: 'Conv Rate (All Conversions)', isDefault: true },
                    { key: 'conversionRatePrimary', label: 'Conv Rate (Primary Conversions)', isDefault: true },
                    { key: 'conversionRateSecondary', label: 'Conv Rate (Secondary Conversions)', isDefault: true },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
     },
     [REPORT_NAMES.ALL_CONVERSION_REPORT]: {
          [SCOPED.SESSION]: {
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'conversionName', label: 'Conversion Name' },
                    { key: 'conversionType', label: 'Conversion Type' },
               ],
               properties: [],
               metrics: [
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'revealedCompany', label: 'Revealed Companies', isDefault: true },
                    { key: 'sessionConvRate', label: 'Conv Rate (Sessions)', isDefault: true },
                    { key: 'userConvRate', label: 'Conv Rate (Users)', isDefault: true },
                    { key: 'personConvRate', label: 'Conv Rate (People)', isDefault: true },
                    { key: 'companyConvRate', label: 'Conv Rate (Company)', isDefault: true },
               ],
          },
     },
     [REPORT_NAMES.BY_CONVERSION]: {
          [SCOPED.CONVERSION]: {
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'conversionID', label: 'Conversion ID', section: SECTION_REPORT.DETAILS },
                    { key: 'conversionName', label: 'Conversion Name', section: SECTION_REPORT.DETAILS },
                    { key: 'conversionType', label: 'Conversion Type', section: SECTION_REPORT.DETAILS },
                    { key: 'conversionCurrency', label: 'Conversion Currency', section: SECTION_REPORT.DETAILS },
                    { key: 'conversionLocationHostname', label: 'Conversion Location (Hostname)', section: SECTION_REPORT.LOCATION },
                    { key: 'conversionLocationOriginPath', label: 'Conversion Location (Origin Path)', section: SECTION_REPORT.LOCATION },
                    { key: 'conversionLocationURL', label: 'Conversion Location (URL)', section: SECTION_REPORT.LOCATION },
                    { key: 'dateUTC', label: 'Date UTC', section: SECTION_REPORT.DATE_UTC },
                    { key: 'hourUTC', label: 'Hour UTC', section: SECTION_REPORT.DATE_UTC },
                    { key: 'dayOfWeekUTC', label: 'Day of Week UTC', section: SECTION_REPORT.DATE_UTC },
                    { key: 'isoWeekUTC', label: 'ISO Week UTC', section: SECTION_REPORT.DATE_UTC },
                    { key: 'monthUTC', label: 'Month UTC', section: SECTION_REPORT.DATE_UTC },
                    { key: 'yearMonthUTC', label: 'Year Month UTC', section: SECTION_REPORT.DATE_UTC },
                    { key: 'quarterUTC', label: 'Quarter UTC', section: SECTION_REPORT.DATE_UTC },
                    { key: 'yearQuarterUTC', label: 'Year Quarter UTC', section: SECTION_REPORT.DATE_UTC },
                    { key: 'date', label: 'Date', section: SECTION_REPORT.DATE_ACCOUNT },
                    { key: 'hour', label: 'Hour', section: SECTION_REPORT.DATE_ACCOUNT },
                    { key: 'dayOfWeek', label: 'Day of Week', section: SECTION_REPORT.DATE_ACCOUNT },
                    { key: 'isoWeek', label: 'ISO Week', section: SECTION_REPORT.DATE_ACCOUNT },
                    { key: 'month', label: 'Month', section: SECTION_REPORT.DATE_ACCOUNT },
                    { key: 'yearMonth', label: 'Year Month', section: SECTION_REPORT.DATE_ACCOUNT },
                    { key: 'quarter', label: 'Quarter', section: SECTION_REPORT.DATE_ACCOUNT },
                    { key: 'yearQuarter', label: 'Year Quarter', section: SECTION_REPORT.DATE_ACCOUNT },
                    { key: 'userSourceFirst', label: 'Created Source (first)', section: SECTION_REPORT.SOURCE_FIRST },
                    { key: 'userSourceFirstND', label: 'Created Source (first non-direct)', section: SECTION_REPORT.SOURCE_FIRST },
                    { key: 'utmSourceFirst', label: 'utm_source (first)', section: SECTION_REPORT.SOURCE_FIRST },
                    { key: 'utmMediumFirst', label: 'utm_medium (first)', section: SECTION_REPORT.SOURCE_FIRST },
                    { key: 'utmSourceMediumFirst', label: 'utm_source / utm_medium (first)', section: SECTION_REPORT.SOURCE_FIRST },
                    { key: 'utmCampaignFirst', label: 'utm_campaign (first)', section: SECTION_REPORT.SOURCE_FIRST },
                    { key: 'utmTermFirst', label: 'utm_term (first)', section: SECTION_REPORT.SOURCE_FIRST },
                    { key: 'utmContentFirst', label: 'utm_content (first)', section: SECTION_REPORT.SOURCE_FIRST },
                    { key: 'referrerOriginPathFirst', label: 'Referrer Origin Path (first)', section: SECTION_REPORT.SOURCE_FIRST },
                    { key: 'referrerHostnameFirst', label: 'Referrer Hostname (first)', section: SECTION_REPORT.SOURCE_FIRST },
                    { key: 'referrerPathFirst', label: 'Referrer Path (first)', section: SECTION_REPORT.SOURCE_FIRST },
                    { key: 'landingPageOriginPathFirst', label: 'Landing Page (first)', section: SECTION_REPORT.SOURCE_FIRST },
                    { key: 'userSourceLast', label: 'Created Source (last)', section: SECTION_REPORT.SOURCE_LAST },
                    { key: 'userSourceLastND', label: 'Created Source (last non-direct)', section: SECTION_REPORT.SOURCE_LAST },
                    { key: 'utmSourceLast', label: 'utm_source (last)', section: SECTION_REPORT.SOURCE_LAST },
                    { key: 'utmMediumLast', label: 'utm_medium (last)', section: SECTION_REPORT.SOURCE_LAST },
                    { key: 'utmSourceMediumLast', label: 'utm_source / utm_medium (last)', section: SECTION_REPORT.SOURCE_LAST },
                    { key: 'utmCampaignLast', label: 'utm_campaign (last)', section: SECTION_REPORT.SOURCE_LAST },
                    { key: 'utmTermLast', label: 'utm_term (last)', section: SECTION_REPORT.SOURCE_LAST },
                    { key: 'utmContentLast', label: 'utm_content (last)', section: SECTION_REPORT.SOURCE_LAST },
                    { key: 'referrerOriginPathLast', label: 'Referrer Origin Path (last)', section: SECTION_REPORT.SOURCE_LAST },
                    { key: 'referrerHostnameLast', label: 'Referrer Hostname (last)', section: SECTION_REPORT.SOURCE_LAST },
                    { key: 'referrerPathLast', label: 'Referrer Path (last)', section: SECTION_REPORT.SOURCE_LAST },
                    { key: 'landingPageOriginPathLast', label: 'Landing Page (last)', section: SECTION_REPORT.SOURCE_LAST },
                    { key: 'userSourcePath', label: 'Created Source (path)', section: SECTION_REPORT.SOURCE_PATH },
                    { key: 'userSourcePathND', label: 'Created Source (path non-direct)', section: SECTION_REPORT.SOURCE_PATH },
                    { key: 'utmSourcePath', label: 'utm_source (path)', section: SECTION_REPORT.SOURCE_PATH },
                    { key: 'utmMediumPath', label: 'utm_medium (path)', section: SECTION_REPORT.SOURCE_PATH },
                    { key: 'utmSourceMediumPath', label: 'utm_source / utm_medium (path)', section: SECTION_REPORT.SOURCE_PATH },
                    { key: 'utmCampaignPath', label: 'utm_campaign (path)', section: SECTION_REPORT.SOURCE_PATH },
                    { key: 'utmTermPath', label: 'utm_term (path)', section: SECTION_REPORT.SOURCE_PATH },
                    { key: 'utmContentPath', label: 'utm_content (path)', section: SECTION_REPORT.SOURCE_PATH },
                    { key: 'referrerOriginPath', label: 'Referrer Origin Path (path)', section: SECTION_REPORT.SOURCE_PATH },
                    { key: 'referrerHostnamePath', label: 'Referrer Hostname (path)', section: SECTION_REPORT.SOURCE_PATH },
                    { key: 'referrerPath', label: 'Referrer Path (path)', section: SECTION_REPORT.SOURCE_PATH },
                    { key: 'landingPageOriginPath', label: 'Landing Page (path)', section: SECTION_REPORT.SOURCE_PATH },
                    { key: 'revealedCompanyName', label: 'Revealed Company Name', section: SECTION_REPORT.COMPANY },
                    { key: 'revealedCompanyDomain', label: 'Revealed Company Domain', section: SECTION_REPORT.COMPANY },
               ],
               defaultDimensions: [
                    { key: 'conversionName', label: 'Conversion Name', section: SECTION_REPORT.DETAILS },
                    { key: 'conversionType', label: 'Conversion Type', section: SECTION_REPORT.DETAILS },
               ],
               dimensionLength: 3,
               requireDimensions: [
                    { key: 'conversionName', label: 'Conversion Name', section: SECTION_REPORT.DETAILS },
                    { key: 'conversionType', label: 'Conversion Type', section: SECTION_REPORT.DETAILS },
                    { key: 'conversionID', label: 'Conversion ID', section: SECTION_REPORT.DETAILS },
               ],
               properties: [],
               metrics: [
                    { key: 'conversionCount', label: 'Conversions', isDefault: true, section: SECTION_REPORT.COUNTS, description: 'All Conversion events' },
                    { key: 'sessionCount', label: 'Conversion Sessions', isDefault: false, section: SECTION_REPORT.COUNTS, description: 'The unique Sessions belonging to the Conversions - allows you to measure only one conversion per Session' },
                    { key: 'userCount', label: 'Conversion Users', isDefault: false, section: SECTION_REPORT.COUNTS, description: 'The unique Users belonging to the Conversions - allows you to measure only one conversion per User' },
                    { key: 'totalActiveSessionCount', label: 'Total Sessions', isDefault: true, section: SECTION_REPORT.TOTAL_ACTIVITY, description: 'The total number of Sessions in the date range. Dimensions are not applied to this number allowing us to calculate the conversion rate for a given conversion or dimension against all Sessions in the date range, even those without a Conversion' },
                    { key: 'totalActiveUserCount', label: 'Total Active Users', isDefault: true, section: SECTION_REPORT.TOTAL_ACTIVITY, description: 'The total number of Users active in the date range. Dimensions are not applied to this number allowing us to calculate the conversion rate for a given conversion or dimension against all Active Users in the date range, even those without a Conversion' },
                    { key: 'conversionValueCount', label: 'Conversions Value', isDefault: true, section: SECTION_REPORT.VALUES, description: 'The SUM of the currency-based value assigned to all Conversion events in the report' },
                    { key: 'conversionSessionValue', label: 'Conversion Sessions Value', isDefault: false, section: SECTION_REPORT.VALUES, description: 'The SUM of the currency-based value assigned to Conversion Sessions, which represents only one conversion per Session. We use the last instance of a conversion action from the Session in the date range' },
                    { key: 'conversionUserValue', label: 'Conversion Users Value', isDefault: false, section: SECTION_REPORT.VALUES, description: 'The SUM of the currency-based value assigned to Conversion Users, which represents only one conversion per User. We use the last instance of a conversion action from the User in the date range' },
                    { key: 'avgConversionValue', label: 'Avg. Conv. Value (Conversions)', isDefault: true, section: SECTION_REPORT.VALUES, description: 'Conversions Value / Conversions' },
                    {
                         key: 'avgConversionSessionValue',
                         label: 'Avg. Conv. Value (Conversion Sessions)',
                         isDefault: false,
                         section: SECTION_REPORT.VALUES,
                         description: 'Conversion Sessions Value / Conversion Sessions'
                    },
                    { key: 'avgConversionUserValue', label: 'Avg. Conv. Value (Conversion Users)', isDefault: false, section: SECTION_REPORT.VALUES, description: 'Conversion Users Value / Conversion Users' },
                    {
                         key: 'conversionEventSessionRate',
                         label: 'Session Conv. Rate (Conversions)',
                         isDefault: true,
                         section: SECTION_REPORT.CONV_RATE,
                         description: 'The total conversion rate for Sessions (all conversion events). Conversions / Total Sessions'
                    },
                    {
                         key: 'conversionSessionRate',
                         label: 'Session Conv. Rate (Conversion Sessions)',
                         isDefault: false,
                         section: SECTION_REPORT.CONV_RATE,
                         description: 'The unique conversion rate for Sessions (one conversion per Session). Conversion Sessions / Total Sessions'
                    },
                    { key: 'conversionEventUserRate', label: 'User Conv. Rate (Conversions)', isDefault: false, section: SECTION_REPORT.CONV_RATE, description: 'The total conversion rate for Users (all conversion events). Conversions / Total Active Users' },
                    { key: 'conversionUserRate', label: 'User Conv. Rate (Conversion Users)', isDefault: false, section: SECTION_REPORT.CONV_RATE, description: 'The unique conversion rate for Users (one conversion per User). Conversion Users / Total Active Users' },
                    { key: 'peopleCountActive', label: 'Active People', isDefault: true, section: SECTION_REPORT.RELATED_OBJECTS, description: 'The count of Revealed People that belong to these Conversion events' },
                    { key: 'companyCountActive', label: 'Active Companies', isDefault: true, section: SECTION_REPORT.RELATED_OBJECTS, description: 'The count of Revealed Companies that belong to these Conversion events' },
                    { key: 'targetAccountCount', label: 'Target Accounts', isDefault: true, section: SECTION_REPORT.RELATED_OBJECTS, description: 'The count of Target Accounts that belong to these Conversions' },
               ],
          },
     },
     [REPORT_NAMES.BY_PROPERTY]: {
          [SCOPED.CONVERSION]: {
               isDefaultMetrics: true,
               isConversionName: true,
               isConversionAllReport: true,
               dimensions: [
                    { key: 'conversionCurrency', label: 'Conversion Currency', section: SECTION_REPORT.DETAILS },
                    { key: 'conversionLocationHostname', label: 'Conversion Location (Hostname)', section: SECTION_REPORT.LOCATION },
                    { key: 'conversionLocationOriginPath', label: 'Conversion Location (Origin Path)', section: SECTION_REPORT.LOCATION },
                    { key: 'conversionLocationURL', label: 'Conversion Location (URL)', section: SECTION_REPORT.LOCATION },
                    { key: 'deviceType', label: 'Device Type', section: SECTION_REPORT.DEVICE },
                    { key: 'deviceFamily', label: 'Device Family', section: SECTION_REPORT.DEVICE },
                    { key: 'deviceManufacturer', label: 'Manufacturer', section: SECTION_REPORT.DEVICE },
                    { key: 'browserFamily', label: 'Browser Family', section: SECTION_REPORT.BROWSER },
                    { key: 'browserVersion', label: 'Browser Version (general)', section: SECTION_REPORT.BROWSER },
                    { key: 'browserDetailedVersion', label: 'Browser Version (specific)', section: SECTION_REPORT.BROWSER },
                    { key: 'consentMethod', label: 'Consent Method', section: SECTION_REPORT.CONSENT },
                    { key: 'trackingLevel', label: 'Tracking Level', section: SECTION_REPORT.CONSENT },
                    { key: 'regionalRule', label: 'Regional Rule', section: SECTION_REPORT.CONSENT },
                    { key: 'country', label: 'Country', section: SECTION_REPORT.GEOGRAPHY },
                    { key: 'region', label: 'Region', section: SECTION_REPORT.GEOGRAPHY },
                    { key: 'city', label: 'City', section: SECTION_REPORT.GEOGRAPHY },
                    { key: 'dateUTC', label: 'Date UTC', section: SECTION_REPORT.DATE_UTC },
                    { key: 'hourUTC', label: 'Hour UTC', section: SECTION_REPORT.DATE_UTC },
                    { key: 'dayOfWeekUTC', label: 'Day of Week UTC', section: SECTION_REPORT.DATE_UTC },
                    { key: 'isoWeekUTC', label: 'ISO Week UTC', section: SECTION_REPORT.DATE_UTC },
                    { key: 'monthUTC', label: 'Month UTC', section: SECTION_REPORT.DATE_UTC },
                    { key: 'yearMonthUTC', label: 'Year Month UTC', section: SECTION_REPORT.DATE_UTC },
                    { key: 'quarterUTC', label: 'Quarter UTC', section: SECTION_REPORT.DATE_UTC },
                    { key: 'yearQuarterUTC', label: 'Year Quarter UTC', section: SECTION_REPORT.DATE_UTC },
                    { key: 'date', label: 'Date', section: SECTION_REPORT.DATE_ACCOUNT },
                    { key: 'hour', label: 'Hour', section: SECTION_REPORT.DATE_ACCOUNT },
                    { key: 'dayOfWeek', label: 'Day of Week', section: SECTION_REPORT.DATE_ACCOUNT },
                    { key: 'isoWeek', label: 'ISO Week', section: SECTION_REPORT.DATE_ACCOUNT },
                    { key: 'month', label: 'Month', section: SECTION_REPORT.DATE_ACCOUNT },
                    { key: 'yearMonth', label: 'Year Month', section: SECTION_REPORT.DATE_ACCOUNT },
                    { key: 'quarter', label: 'Quarter', section: SECTION_REPORT.DATE_ACCOUNT },
                    { key: 'yearQuarter', label: 'Year Quarter', section: SECTION_REPORT.DATE_ACCOUNT },
                    { key: 'userSourceFirst', label: 'Created Source (first)', section: SECTION_REPORT.SOURCE_FIRST },
                    { key: 'userSourceFirstND', label: 'Created Source (first non-direct)', section: SECTION_REPORT.SOURCE_FIRST },
                    { key: 'utmSourceFirst', label: 'utm_source (first)', section: SECTION_REPORT.SOURCE_FIRST },
                    { key: 'utmMediumFirst', label: 'utm_medium (first)', section: SECTION_REPORT.SOURCE_FIRST },
                    { key: 'utmSourceMediumFirst', label: 'utm_source / utm_medium (first)', section: SECTION_REPORT.SOURCE_FIRST },
                    { key: 'utmCampaignFirst', label: 'utm_campaign (first)', section: SECTION_REPORT.SOURCE_FIRST },
                    { key: 'utmTermFirst', label: 'utm_term (first)', section: SECTION_REPORT.SOURCE_FIRST },
                    { key: 'utmContentFirst', label: 'utm_content (first)', section: SECTION_REPORT.SOURCE_FIRST },
                    { key: 'referrerOriginPathFirst', label: 'Referrer Origin Path (first)', section: SECTION_REPORT.SOURCE_FIRST },
                    { key: 'referrerHostnameFirst', label: 'Referrer Hostname (first)', section: SECTION_REPORT.SOURCE_FIRST },
                    { key: 'referrerPathFirst', label: 'Referrer Path (first)', section: SECTION_REPORT.SOURCE_FIRST },
                    { key: 'landingPageOriginPathFirst', label: 'Landing Page (first)', section: SECTION_REPORT.SOURCE_FIRST },
                    { key: 'userSourceLast', label: 'Created Source (last)', section: SECTION_REPORT.SOURCE_LAST },
                    { key: 'userSourceLastND', label: 'Created Source (last non-direct)', section: SECTION_REPORT.SOURCE_LAST },
                    { key: 'utmSourceLast', label: 'utm_source (last)', section: SECTION_REPORT.SOURCE_LAST },
                    { key: 'utmMediumLast', label: 'utm_medium (last)', section: SECTION_REPORT.SOURCE_LAST },
                    { key: 'utmSourceMediumLast', label: 'utm_source / utm_medium (last)', section: SECTION_REPORT.SOURCE_LAST },
                    { key: 'utmCampaignLast', label: 'utm_campaign (last)', section: SECTION_REPORT.SOURCE_LAST },
                    { key: 'utmTermLast', label: 'utm_term (last)', section: SECTION_REPORT.SOURCE_LAST },
                    { key: 'utmContentLast', label: 'utm_content (last)', section: SECTION_REPORT.SOURCE_LAST },
                    { key: 'referrerOriginPathLast', label: 'Referrer Origin Path (last)', section: SECTION_REPORT.SOURCE_LAST },
                    { key: 'referrerHostnameLast', label: 'Referrer Hostname (last)', section: SECTION_REPORT.SOURCE_LAST },
                    { key: 'referrerPathLast', label: 'Referrer Path (last)', section: SECTION_REPORT.SOURCE_LAST },
                    { key: 'landingPageOriginPathLast', label: 'Landing Page (last)', section: SECTION_REPORT.SOURCE_LAST },
                    { key: 'userSourcePath', label: 'Created Source (path)', section: SECTION_REPORT.SOURCE_PATH },
                    { key: 'userSourcePathND', label: 'Created Source (path non-direct)', section: SECTION_REPORT.SOURCE_PATH },
                    { key: 'utmSourcePath', label: 'utm_source (path)', section: SECTION_REPORT.SOURCE_PATH },
                    { key: 'utmMediumPath', label: 'utm_medium (path)', section: SECTION_REPORT.SOURCE_PATH },
                    { key: 'utmSourceMediumPath', label: 'utm_source / utm_medium (path)', section: SECTION_REPORT.SOURCE_PATH },
                    { key: 'utmCampaignPath', label: 'utm_campaign (path)', section: SECTION_REPORT.SOURCE_PATH },
                    { key: 'utmTermPath', label: 'utm_term (path)', section: SECTION_REPORT.SOURCE_PATH },
                    { key: 'utmContentPath', label: 'utm_content (path)', section: SECTION_REPORT.SOURCE_PATH },
                    { key: 'referrerOriginPath', label: 'Referrer Origin Path (path)', section: SECTION_REPORT.SOURCE_PATH },
                    { key: 'referrerHostnamePath', label: 'Referrer Hostname (path)', section: SECTION_REPORT.SOURCE_PATH },
                    { key: 'referrerPath', label: 'Referrer Path (path)', section: SECTION_REPORT.SOURCE_PATH },
                    { key: 'landingPageOriginPath', label: 'Landing Page (path)', section: SECTION_REPORT.SOURCE_PATH },
                    { key: 'revealedCompanyName', label: 'Revealed Company Name', section: SECTION_REPORT.COMPANY },
                    { key: 'revealedCompanyDomain', label: 'Revealed Company Domain', section: SECTION_REPORT.COMPANY },
               ],
               defaultDimensions: [
                    { key: 'userSourceFirst', label: 'Created Source (first)', section: SECTION_REPORT.SOURCE_FIRST },
                    { key: 'userSourceLast', label: 'Created Source (last)', section: SECTION_REPORT.SOURCE_LAST },
               ],
               dimensionLength: 3,
               properties: [],
               metrics: [
                    { key: 'conversionCount', label: 'Conversions', isDefault: true, section: SECTION_REPORT.COUNTS, sectionParent: SECTION_REPORT.ALL_CONV, description: 'All Conversion events' },
                    { key: 'totalActiveSessionCount', label: 'Total Sessions', isDefault: true, section: SECTION_REPORT.TOTAL_ACTIVITY, description: 'The total number of Sessions in the date range. Dimensions are not applied to this number allowing us to calculate the conversion rate for a given conversion or dimension against all Sessions in the date range, even those without a Conversion' },
                    { key: 'totalActiveUserCount', label: 'Total Active Users', isDefault: true, section: SECTION_REPORT.TOTAL_ACTIVITY, description: 'The total number of Users active in the date range. Dimensions are not applied to this number allowing us to calculate the conversion rate for a given conversion or dimension against all Active Users in the date range, even those without a Conversion' },
                    { key: 'sessionCount', label: 'Conversion Sessions', isDefault: false, section: SECTION_REPORT.COUNTS, sectionParent: SECTION_REPORT.ALL_CONV, description: 'The unique Sessions belonging to the Conversions - allows you to measure only one conversion per Session' },
                    { key: 'userCount', label: 'Conversion Users', isDefault: false, section: SECTION_REPORT.COUNTS, sectionParent: SECTION_REPORT.ALL_CONV, description: 'The unique Users belonging to the Conversions - allows you to measure only one conversion per User' },
                    { key: 'conversionValueCount', label: 'Conversions Value', isDefault: true, section: SECTION_REPORT.VALUES, sectionParent: SECTION_REPORT.ALL_CONV, description: 'The SUM of the currency-based value assigned to all Conversion events in the report' },
                    { key: 'conversionSessionValue', label: 'Conversion Sessions Value', isDefault: false, section: SECTION_REPORT.VALUES, sectionParent: SECTION_REPORT.ALL_CONV, description: 'The SUM of the currency-based value assigned to Conversion Sessions, which represents only one conversion per Session. We use the last instance of a conversion action from the Session in the date range' },
                    { key: 'conversionUserValue', label: 'Conversion Users Value', isDefault: false, section: SECTION_REPORT.VALUES, sectionParent: SECTION_REPORT.ALL_CONV, description: 'The SUM of the currency-based value assigned to Conversion Users, which represents only one conversion per User. We use the last instance of a conversion action from the User in the date range' },
                    { key: 'avgConversionValue', label: 'Avg. Conv. Value (Conversions)', isDefault: true, section: SECTION_REPORT.VALUES, sectionParent: SECTION_REPORT.ALL_CONV, description: 'Conversions Value / Conversions' },
                    { key: 'avgConversionSessionValue', label: 'Avg. Conv. Value (Conversion Sessions)', isDefault: false, section: SECTION_REPORT.VALUES, sectionParent: SECTION_REPORT.ALL_CONV, description: 'Conversion Sessions Value / Conversion Sessions' },
                    { key: 'avgConversionUserValue', label: 'Avg. Conv. Value (Conversion Users)', isDefault: false, section: SECTION_REPORT.VALUES, sectionParent: SECTION_REPORT.ALL_CONV, description: 'Conversion Users Value / Conversion Users' },
                    { key: 'conversionEventSessionRate', label: 'Session Conv. Rate (Conversions)', isDefault: true, section: SECTION_REPORT.CONV_RATE, sectionParent: SECTION_REPORT.ALL_CONV, description: 'The total conversion rate for Sessions (all conversion events). Conversions / Total Sessions' },
                    { key: 'conversionSessionRate', label: 'Session Conv. Rate (Conversion Sessions)', isDefault: false, section: SECTION_REPORT.CONV_RATE, sectionParent: SECTION_REPORT.ALL_CONV, description: 'The unique conversion rate for Sessions (one conversion per Session). Conversion Sessions / Total Sessions' },
                    { key: 'conversionEventUserRate', label: 'User Conv. Rate (Conversions)', isDefault: false, section: SECTION_REPORT.CONV_RATE, sectionParent: SECTION_REPORT.ALL_CONV, description: 'The total conversion rate for Users (all conversion events). Conversions / Total Active Users' },
                    { key: 'conversionUserRate', label: 'User Conv. Rate (Conversion Users)', isDefault: false, section: SECTION_REPORT.CONV_RATE, sectionParent: SECTION_REPORT.ALL_CONV, description: 'Conversion Users / Total Active Users' },
                    { key: 'primaryConversionCount', label: 'Primary Conversions', isDefault: false, section: SECTION_REPORT.COUNTS, sectionParent: SECTION_REPORT.PRIMARY_CONV, description: 'All Primary Conversion events' },
                    { key: 'primarySessionCount', label: 'Primary Conversion Sessions', isDefault: false, section: SECTION_REPORT.COUNTS, sectionParent: SECTION_REPORT.PRIMARY_CONV, description: 'The unique Sessions belonging to the Primary Conversions - allows you to measure only one conversion per Session' },
                    { key: 'primaryUserCount', label: 'Primary Conversion Users', isDefault: false, section: SECTION_REPORT.COUNTS, sectionParent: SECTION_REPORT.PRIMARY_CONV, description: 'The unique Users belonging to the Primary Conversions - allows you to measure only one conversion per User' },
                    { key: 'primaryConversionValueCount', label: 'Primary Conversions Value', isDefault: false, section: SECTION_REPORT.VALUES, sectionParent: SECTION_REPORT.PRIMARY_CONV, description: 'The SUM of the currency-based value assigned to all Primary Conversion events in the report' },
                    { key: 'primaryConversionSessionValue', label: 'Primary Conversion Sessions Value', isDefault: false, section: SECTION_REPORT.VALUES, sectionParent: SECTION_REPORT.PRIMARY_CONV, description: 'The SUM of the currency-based value assigned to Primary Conversion Sessions, which represents only one conversion per Session. We use the last instance of a conversion action from the Session in the date range' },
                    { key: 'primaryConversionUserValue', label: 'Primary Conversion Users Value', isDefault: false, section: SECTION_REPORT.VALUES, sectionParent: SECTION_REPORT.PRIMARY_CONV, description: 'The SUM of the currency-based value assigned to Primary Conversion Users, which represents only one conversion per User. We use the last instance of a conversion action from the User in the date range' },
                    { key: 'avgPrimaryConversionValue', label: 'Avg. Conv. Value (Primary Conversions)', isDefault: false, section: SECTION_REPORT.VALUES, sectionParent: SECTION_REPORT.PRIMARY_CONV, description: 'Primary Conversions Value / Primary Conversions' },
                    { key: 'avgPrimaryConversionSessionValue', label: 'Avg. Conv. Value (Primary Conversion Sessions)', isDefault: false, section: SECTION_REPORT.VALUES, sectionParent: SECTION_REPORT.PRIMARY_CONV, description: 'Primary Conversion Sessions Value / Primary Conversion Sessions' },
                    { key: 'avgPrimaryConversionUserValue', label: 'Avg. Conv. Value (Primary Conversion Users)', isDefault: false, section: SECTION_REPORT.VALUES, sectionParent: SECTION_REPORT.PRIMARY_CONV, description: 'Primary Conversion Users Value / Primary Conversion Users' },
                    { key: 'primaryConversionEventSessionRate', label: 'Session Conv. Rate (Primary Conversions)', isDefault: false, section: SECTION_REPORT.CONV_RATE, sectionParent: SECTION_REPORT.PRIMARY_CONV, description: 'The total conversion rate for Sessions (all conversion events). Primary Conversions / Total Sessions' },
                    { key: 'primaryConversionSessionRate', label: 'Session Conv. Rate (Primary Conversion Sessions)', isDefault: false, section: SECTION_REPORT.CONV_RATE, sectionParent: SECTION_REPORT.PRIMARY_CONV, description: 'The unique conversion rate for Sessions (one conversion per Session). Primary Conversion Sessions / Total Sessions' },
                    { key: 'primaryConversionEventUserRate', label: 'User Conv. Rate (Primary Conversions)', isDefault: false, section: SECTION_REPORT.CONV_RATE, sectionParent: SECTION_REPORT.PRIMARY_CONV, description: 'The total conversion rate for Users (all conversion events). Primary Conversions / Total Active Users' },
                    { key: 'primaryConversionUserRate', label: 'User Conv. Rate (Primary Conversion Users)', isDefault: false, section: SECTION_REPORT.CONV_RATE, sectionParent: SECTION_REPORT.PRIMARY_CONV, description: 'Primary Conversion Users / Total Active Users' },
                    { key: 'secondaryConversionCount', label: 'Secondary Conversions', isDefault: false, section: SECTION_REPORT.COUNTS, sectionParent: SECTION_REPORT.SECONDARY_CONV, description: 'All Secondary Conversion events' },
                    { key: 'secondarySessionCount', label: 'Secondary Conversion Sessions', isDefault: false, section: SECTION_REPORT.COUNTS, sectionParent: SECTION_REPORT.SECONDARY_CONV, description: 'The unique Sessions belonging to the Secondary Conversions - allows you to measure only one conversion per Session' },
                    { key: 'secondaryUserCount', label: 'Secondary Conversion Users', isDefault: false, section: SECTION_REPORT.COUNTS, sectionParent: SECTION_REPORT.SECONDARY_CONV, description: 'The unique Users belonging to the Secondary Conversions - allows you to measure only one conversion per User' },
                    { key: 'secondaryConversionValueCount', label: 'Secondary Conversions Value', isDefault: false, section: SECTION_REPORT.VALUES, sectionParent: SECTION_REPORT.SECONDARY_CONV, description: 'The SUM of the currency-based value assigned to all Secondary Conversion events in the report' },
                    { key: 'secondaryConversionSessionValue', label: 'Secondary Conversion Sessions Value', isDefault: false, section: SECTION_REPORT.VALUES, sectionParent: SECTION_REPORT.SECONDARY_CONV, description: 'The SUM of the currency-based value assigned to Secondary Conversion Sessions, which represents only one conversion per Session. We use the last instance of a conversion action from the Session in the date range' },
                    { key: 'secondaryConversionUserValue', label: 'Secondary Conversion Users Value', isDefault: false, section: SECTION_REPORT.VALUES, sectionParent: SECTION_REPORT.SECONDARY_CONV, description: 'The SUM of the currency-based value assigned to Secondary Conversion Users, which represents only one conversion per User. We use the last instance of a conversion action from the User in the date range' },
                    { key: 'avgSecondaryConversionValue', label: 'Avg. Conv. Value (Secondary Conversions)', isDefault: false, section: SECTION_REPORT.VALUES, sectionParent: SECTION_REPORT.SECONDARY_CONV, description: 'Secondary Conversions Value / Secondary Conversions' },
                    { key: 'avgSecondaryConversionSessionValue', label: 'Avg. Conv. Value (Secondary Conversion Sessions)', isDefault: false, section: SECTION_REPORT.VALUES, sectionParent: SECTION_REPORT.SECONDARY_CONV, description: 'Secondary Conversion Sessions Value / Secondary Conversion Sessions' },
                    { key: 'avgSecondaryConversionUserValue', label: 'Avg. Conv. Value (Secondary Conversion Users)', isDefault: false, section: SECTION_REPORT.VALUES, sectionParent: SECTION_REPORT.SECONDARY_CONV, description: 'Secondary Conversion Users Value / Secondary Conversion Users' },
                    { key: 'secondaryConversionEventSessionRate', label: 'Session Conv. Rate (Secondary Conversions)', isDefault: false, section: SECTION_REPORT.CONV_RATE, sectionParent: SECTION_REPORT.SECONDARY_CONV, description: 'The total conversion rate for Sessions (all conversion events). Secondary Conversions / Total Sessions' },
                    { key: 'secondaryConversionSessionRate', label: 'Session Conv. Rate (Secondary Conversion Sessions)', isDefault: false, section: SECTION_REPORT.CONV_RATE, sectionParent: SECTION_REPORT.SECONDARY_CONV, description: 'The unique conversion rate for Sessions (one conversion per Session). Secondary Conversion Sessions / Total Sessions' },
                    { key: 'secondaryConversionEventUserRate', label: 'User Conv. Rate (Secondary Conversions)', isDefault: false, section: SECTION_REPORT.CONV_RATE, sectionParent: SECTION_REPORT.SECONDARY_CONV, description: 'The total conversion rate for Users (all conversion events). Secondary Conversions / Total Active Users' },
                    { key: 'secondaryConversionUserRate', label: 'User Conv. Rate (Secondary Conversion Users)', isDefault: false, section: SECTION_REPORT.CONV_RATE, sectionParent: SECTION_REPORT.SECONDARY_CONV, description: 'The unique conversion rate for Users (one conversion per User). Secondary Conversion Users / Total Active Users' },
               ],
          },
     },
     [REPORT_NAMES.BY_SOURCE_REPORT]: {
          [SCOPED.SESSION]: {
               isConversionName: true,
               isConversionNameRate: true,
               isDefaultMetrics: true,
               dimensions: [{ key: 'userSource', label: 'User Source' }],
               defaultDimensions: [{ key: 'userSource', label: 'User Source' }],
               properties: [],
               metrics: [
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'primaryConversionCount', label: 'Primary Conversions', isDefault: true },
                    { key: 'secondaryConversionCount', label: 'Secondary Conversions', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'conversionRateAll', label: 'Conv Rate (All Conversions)', isDefault: true },
                    { key: 'conversionRatePrimary', label: 'Conv Rate (Primary Conversions)', isDefault: true },
                    { key: 'conversionRateSecondary', label: 'Conv Rate (Secondary Conversions)', isDefault: true },
               ],
          },
          [SCOPED.USER]: {
               isConversionName: true,
               isConversionNameRate: true,
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'userSourceFirst', label: 'User Source - First' },
                    { key: 'userSourceLast', label: 'User Source - Last' },
                    { key: 'userSourcePath', label: 'User Source - Path' },
               ],
               defaultDimensions: [
                    { key: 'userSourceFirst', label: 'User Source - First' },
                    { key: 'userSourceLast', label: 'User Source - Last' },
               ],
               properties: [],
               metrics: [
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'primaryConversionCount', label: 'Primary Conversions', isDefault: true },
                    { key: 'secondaryConversionCount', label: 'Secondary Conversions', isDefault: true },
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'conversionRateAll', label: 'Conv Rate (All Conversions)', isDefault: true },
                    { key: 'conversionRatePrimary', label: 'Conv Rate (Primary Conversions)', isDefault: true },
                    { key: 'conversionRateSecondary', label: 'Conv Rate (Secondary Conversions)', isDefault: true },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
          [SCOPED.PEOPLE]: {
               isConversionName: true,
               isConversionNameRate: true,
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'userSourceFirst', label: 'User Source - First' },
                    { key: 'userSourceLast', label: 'User Source - Last' },
                    { key: 'userSourcePath', label: 'User Source - Path' },
               ],
               defaultDimensions: [
                    { key: 'userSourceFirst', label: 'User Source - First' },
                    { key: 'userSourceLast', label: 'User Source - Last' },
               ],
               properties: [],
               metrics: [
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'primaryConversionCount', label: 'Primary Conversions', isDefault: true },
                    { key: 'secondaryConversionCount', label: 'Secondary Conversions', isDefault: true },
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'conversionRateAll', label: 'Conv Rate (All Conversions)', isDefault: true },
                    { key: 'conversionRatePrimary', label: 'Conv Rate (Primary Conversions)', isDefault: true },
                    { key: 'conversionRateSecondary', label: 'Conv Rate (Secondary Conversions)', isDefault: true },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
          [SCOPED.COMPANY]: {
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'userSourceFirst', label: 'User Source - First' },
                    { key: 'userSourceLast', label: 'User Source - Last' },
                    { key: 'userSourcePath', label: 'User Source - Path' },
               ],
               defaultDimensions: [
                    { key: 'userSourceFirst', label: 'User Source - First' },
                    { key: 'userSourceLast', label: 'User Source - Last' },
               ],
               properties: [],
               metrics: [
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'primaryConversionCount', label: 'Primary Conversions', isDefault: true },
                    { key: 'secondaryConversionCount', label: 'Secondary Conversions', isDefault: true },
                    { key: 'revealedCompany', label: 'Revealed Companies', isDefault: true },
                    { key: 'conversionRateAll', label: 'Conv Rate (All Conversions)', isDefault: true },
                    { key: 'conversionRatePrimary', label: 'Conv Rate (Primary Conversions)', isDefault: true },
                    { key: 'conversionRateSecondary', label: 'Conv Rate (Secondary Conversions)', isDefault: true },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
     },
     [REPORT_NAMES.BY_UTM_REPORT]: {
          [SCOPED.SESSION]: {
               isConversionName: true,
               isConversionNameRate: true,
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'utmSource', label: 'utm_source' },
                    { key: 'utmMedium', label: 'utm_medium' },
                    { key: 'utmSourceMedium', label: 'utm_source / utm_medium' },
                    { key: 'utmCampaign', label: 'utm_campaign' },
                    { key: 'utmTerm', label: 'utm_term' },
                    { key: 'utmContent', label: 'utm_content' },
               ],
               defaultDimensions: [
                    { key: 'utmSource', label: 'utm_source' },
                    { key: 'utmMedium', label: 'utm_medium' },
               ],
               properties: [],
               metrics: [
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'primaryConversionCount', label: 'Primary Conversions', isDefault: true },
                    { key: 'secondaryConversionCount', label: 'Secondary Conversions', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'conversionRateAll', label: 'Conv Rate (All Conversions)', isDefault: true },
                    { key: 'conversionRatePrimary', label: 'Conv Rate (Primary Conversions)', isDefault: true },
                    { key: 'conversionRateSecondary', label: 'Conv Rate (Secondary Conversions)', isDefault: true },
               ],
          },
          [SCOPED.USER]: {
               isConversionName: true,
               isConversionNameRate: true,
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'utmSource', label: 'utm_source' },
                    { key: 'utmMedium', label: 'utm_medium' },
                    { key: 'utmSourceMedium', label: 'utm_medium / utm_source' },
                    { key: 'utmCampaign', label: 'utm_campaign' },
                    { key: 'utmTerm', label: 'utm_term' },
                    { key: 'utmContent', label: 'utm_content' },
               ],
               defaultDimensions: [
                    { key: 'utmSource', label: 'utm_source' },
                    { key: 'utmMedium', label: 'utm_medium' },
               ],
               properties: [],
               metrics: [
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'primaryConversionCount', label: 'Primary Conversions', isDefault: true },
                    { key: 'secondaryConversionCount', label: 'Secondary Conversions', isDefault: true },
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'conversionRateAll', label: 'Conv Rate (All Conversions)', isDefault: true },
                    { key: 'conversionRatePrimary', label: 'Conv Rate (Primary Conversions)', isDefault: true },
                    { key: 'conversionRateSecondary', label: 'Conv Rate (Secondary Conversions)', isDefault: true },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
          [SCOPED.PEOPLE]: {
               isConversionName: true,
               isConversionNameRate: true,
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'utmSource', label: 'utm_source' },
                    { key: 'utmMedium', label: 'utm_medium' },
                    { key: 'utmSourceMedium', label: 'utm_medium / utm_source' },
                    { key: 'utmCampaign', label: 'utm_campaign' },
                    { key: 'utmTerm', label: 'utm_term' },
                    { key: 'utmContent', label: 'utm_content' },
               ],
               defaultDimensions: [
                    { key: 'utmSource', label: 'utm_source' },
                    { key: 'utmMedium', label: 'utm_medium' },
               ],
               properties: [],
               metrics: [
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'primaryConversionCount', label: 'Primary Conversions', isDefault: true },
                    { key: 'secondaryConversionCount', label: 'Secondary Conversions', isDefault: true },
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'conversionRateAll', label: 'Conv Rate (All Conversions)', isDefault: true },
                    { key: 'conversionRatePrimary', label: 'Conv Rate (Primary Conversions)', isDefault: true },
                    { key: 'conversionRateSecondary', label: 'Conv Rate (Secondary Conversions)', isDefault: true },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
          [SCOPED.COMPANY]: {
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'utmSource', label: 'utm_source' },
                    { key: 'utmMedium', label: 'utm_medium' },
                    { key: 'utmSourceMedium', label: 'utm_medium / utm_source' },
                    { key: 'utmCampaign', label: 'utm_campaign' },
                    { key: 'utmTerm', label: 'utm_term' },
                    { key: 'utmContent', label: 'utm_content' },
               ],
               properties: [],
               metrics: [
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true },
                    { key: 'primaryConversionCount', label: 'Primary Conversions', isDefault: true },
                    { key: 'secondaryConversionCount', label: 'Secondary Conversions', isDefault: true },
                    { key: 'revealedCompany', label: 'Revealed Companies', isDefault: true },
                    { key: 'conversionRateAll', label: 'Conv Rate (All Conversions)', isDefault: true },
                    { key: 'conversionRatePrimary', label: 'Conv Rate (Primary Conversions)', isDefault: true },
                    { key: 'conversionRateSecondary', label: 'Conv Rate (Secondary Conversions)', isDefault: true },
               ],
               dateScopes: [DATE_SCOPE.EVENT_ACTIVITY, DATE_SCOPE.CREATION_DATE],
          },
     },
     [REPORT_NAMES.FORM_CATEGORY_REPORT]: {
          [SCOPED.FORM]: SCOPED.FORM,
          isDefaultMetrics: true,
          dimensions: [
               { key: 'formId', label: 'FormID' },
               { key: 'formHostNamePathName', label: 'Form Location' },
          ],
          defaultDimensions: [{ key: 'formId', label: 'FormID' }],
          properties: [
               { key: 'viewDetail', label: 'Profile', isDefault: true },
               { key: 'formName', label: 'Form Name', isDefault: true },
               { key: 'formCategory', label: 'Form Category', isDefault: true },
               { key: 'identifiedDate', label: 'Identified Date', isDefault: true },
               { key: 'latestSubmissionDate', label: 'Latest Submission Date', isDefault: true },
          ],
          metrics: [
               { key: 'impressionsLoad', label: 'Impressions (Loads)', isDefault: true },
               { key: 'validationErrors', label: 'Validation Errors', isDefault: true },
               { key: 'submissions', label: 'Submissions', isDefault: true },
               { key: 'formVisible', label: 'Form Visible', isDefault: true },
               { key: 'formStart', label: 'Form Start', isDefault: true },
          ],
     },

     [REPORT_NAMES.ECOMMERCE_PURCHASE_FLOW]: {
          [SCOPED.EVENT]: {
               dimensions: [{ key: 'eCommerceAction', label: 'Ecommerce Action' }],
               properties: [],
               metrics: [
                    { key: 'eventCount', label: 'Event Count', isDefault: true },
                    { key: 'eventValue', label: 'Event Value', isDefault: true },
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'peopleCount', label: 'People', isDefault: true },
               ],
          },
     },
     [REPORT_NAMES.ECOMMERCE_ITEMS]: {
          [SCOPED.EVENT]: {
               dimensions: [
                    { key: 'itemName', label: 'Item Name' },
                    // { key: 'itemCategories', label: 'Item Categories' },
                    // { key: 'itemVariant', label: 'Item Variant' },
                    { key: 'itemId', label: 'Item ID' },
               ],
               defaultDimensions: [{ key: 'itemName', label: 'Item Name' }],
               properties: [],
               metrics: [
                    { key: 'impressionCount', label: 'Impressions', isDefault: true },
                    { key: 'viewCount', label: 'View', isDefault: true },
                    { key: 'addToCartCount', label: 'Add to Cart', isDefault: true },
                    { key: 'purchaseCount', label: 'Purchase', isDefault: true },
                    { key: 'itemRevenue', label: 'Item Revenue', isDefault: true },
               ],
          },
     },
     [REPORT_NAMES.ECOMMERCE_PURCHASE_ACTIVITY]: {
          [SCOPED.EVENT]: {
               dimensions: [{ key: 'sessionSource', label: 'Session Source' }],
               properties: [],
               metrics: [
                    { key: 'sessionCount', label: 'Sessions', isDefault: true },
                    { key: 'userCount', label: 'Users', isDefault: true },
                    { key: 'peopleCount', label: 'People', isDefault: true },
                    { key: 'purchaseCount', label: 'Purchase', isDefault: true },
                    { key: 'companyCount', label: 'Companies' },
                    { key: 'purchaseConversionRate', label: 'Purchase Conversion Rate', isDefault: true },
                    { key: 'eCommercePurchaseRevenue', label: 'Revenue', isDefault: true },
                    { key: 'avgRevenue', label: 'Avg Revenue', isDefault: true },
               ],
          },
     },
     [REPORT_NAMES.ECOMMERCE_PURCHASE_TRANSACTION]: {
          [SCOPED.EVENT]: {
               dimensions: [{ key: 'eventECommerceId', label: 'EventID' }],
               properties: [
                    { key: 'viewDetail', label: 'Profile', isDefault: true },
                    { key: 'transactionId', label: 'TransactionID', isDefault: true },
                    { key: 'userId', label: 'User ID', isDefault: true },
                    { key: 'sessionId', label: 'Session ID', isDefault: true },
                    { key: 'personId', label: 'Person ID', isDefault: true },
                    { key: 'userSourceFirst', label: 'User Source - First' },
                    { key: 'userSourcePath', label: 'User Source - Path', isDefault: true },
                    { key: 'userSourceLast', label: 'User Source - Last' },
               ],
               metrics: [
                    { key: 'purchaseTotal', label: 'Purchase Total', isDefault: true },
                    { key: 'itemQuantity', label: 'Total Item', isDefault: true },
                    { key: 'uniqueItem', label: 'Unique Item', isDefault: true },
                    { key: 'shippingTotal', label: 'Shipping Total', isDefault: true },
                    { key: 'taxTotal', label: 'Tax Total', isDefault: true },
               ],
          },
     },
     [REPORT_NAMES.SALES_CONVERSION_BY_CONVERSION] : {
          [SCOPED.SALES_CONVERSION_EVENT]: {
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'conversionName', label: 'Conversion Name', section: SECTION_REPORT.DETAILS },
                    { key: 'conversionType', label: 'Conversion Type', section: SECTION_REPORT.DETAILS },
                    { key: 'conversionID', label: 'Conversion ID', section: SECTION_REPORT.DETAILS },
                    { key: 'primaryObject', label: 'Primary Object', section: SECTION_REPORT.DETAILS },
                    { key: 'secondaryObject', label: 'Secondary Object', section: SECTION_REPORT.DETAILS },
                    { key: 'dateUTC', label: 'Date UTC', section: SECTION_REPORT.DATE_UTC },
                    { key: 'hourUTC', label: 'Hour UTC', section: SECTION_REPORT.DATE_UTC },
                    { key: 'dayOfWeekUTC', label: 'Day of Week UTC', section: SECTION_REPORT.DATE_UTC },
                    { key: 'isoWeekUTC', label: 'ISO Week UTC', section: SECTION_REPORT.DATE_UTC },
                    { key: 'monthUTC', label: 'Month UTC', section: SECTION_REPORT.DATE_UTC },
                    { key: 'yearMonthUTC', label: 'Year Month UTC', section: SECTION_REPORT.DATE_UTC },
                    { key: 'quarterUTC', label: 'Quarter UTC', section: SECTION_REPORT.DATE_UTC },
                    { key: 'yearQuarterUTC', label: 'Year Quarter UTC', section: SECTION_REPORT.DATE_UTC },
                    { key: 'date', label: 'Date', section: SECTION_REPORT.DATE_ACCOUNT },
                    { key: 'hour', label: 'Hour', section: SECTION_REPORT.DATE_ACCOUNT },
                    { key: 'dayOfWeek', label: 'Day of Week', section: SECTION_REPORT.DATE_ACCOUNT },
                    { key: 'isoWeek', label: 'ISO Week', section: SECTION_REPORT.DATE_ACCOUNT },
                    { key: 'month', label: 'Month', section: SECTION_REPORT.DATE_ACCOUNT },
                    { key: 'yearMonth', label: 'Year Month', section: SECTION_REPORT.DATE_ACCOUNT },
                    { key: 'quarter', label: 'Quarter', section: SECTION_REPORT.DATE_ACCOUNT },
                    { key: 'yearQuarter', label: 'Year Quarter', section: SECTION_REPORT.DATE_ACCOUNT },
                    { key: 'userSourceFirst', label: 'Created Source (first)', section: SECTION_REPORT.SOURCE_FIRST },
                    { key: 'userSourceFirstND', label: 'Created Source (first non-direct)', section: SECTION_REPORT.SOURCE_FIRST },
                    { key: 'utmSourceFirst', label: 'utm_source (first)', section: SECTION_REPORT.SOURCE_FIRST },
                    { key: 'utmMediumFirst', label: 'utm_medium (first)', section: SECTION_REPORT.SOURCE_FIRST },
                    { key: 'utmSourceMediumFirst', label: 'utm_source / utm_medium (first)', section: SECTION_REPORT.SOURCE_FIRST },
                    { key: 'utmCampaignFirst', label: 'utm_campaign (first)', section: SECTION_REPORT.SOURCE_FIRST },
                    { key: 'utmContentFirst', label: 'utm_content (first)', section: SECTION_REPORT.SOURCE_FIRST },
                    { key: 'utmTermFirst', label: 'utm_term (first)', section: SECTION_REPORT.SOURCE_FIRST },
                    { key: 'referrerHostnameFirst', label: 'Referrer Hostname (first)', section: SECTION_REPORT.SOURCE_FIRST },
                    { key: 'referrerOriginPathFirst', label: 'Referrer Origin Path (first)', section: SECTION_REPORT.SOURCE_FIRST },
                    { key: 'referrerPathFirst', label: 'Referrer Path (first)', section: SECTION_REPORT.SOURCE_FIRST },
                    { key: 'landingPageOriginPathFirst', label: 'Landing Page (first)', section: SECTION_REPORT.SOURCE_FIRST },
                    { key: 'userSourceLast', label: 'Created Source (last)', section: SECTION_REPORT.SOURCE_LAST },
                    { key: 'userSourceLastND', label: 'Created Source (last non-direct)', section: SECTION_REPORT.SOURCE_LAST },
                    { key: 'utmSourceLast', label: 'utm_source (last)', section: SECTION_REPORT.SOURCE_LAST },
                    { key: 'utmMediumLast', label: 'utm_medium (last)', section: SECTION_REPORT.SOURCE_LAST },
                    { key: 'utmSourceMediumLast', label: 'utm_source / utm_medium (last)', section: SECTION_REPORT.SOURCE_LAST },
                    { key: 'utmCampaignLast', label: 'utm_campaign (last)', section: SECTION_REPORT.SOURCE_LAST },
                    { key: 'utmContentLast', label: 'utm_content (last)', section: SECTION_REPORT.SOURCE_LAST },
                    { key: 'utmTermLast', label: 'utm_term (last)', section: SECTION_REPORT.SOURCE_LAST },
                    { key: 'referrerHostnameLast', label: 'Referrer Hostname (last)', section: SECTION_REPORT.SOURCE_LAST },
                    { key: 'referrerOriginPathLast', label: 'Referrer Origin Path (last)', section: SECTION_REPORT.SOURCE_LAST },
                    { key: 'referrerPathLast', label: 'Referrer Path (last)', section: SECTION_REPORT.SOURCE_LAST },
                    { key: 'landingPageOriginPathLast', label: 'Landing Page (last)', section: SECTION_REPORT.SOURCE_LAST },
                    { key: 'userSourcePath', label: 'Created Source (path)', section: SECTION_REPORT.SOURCE_PATH },
                    { key: 'userSourcePathND', label: 'Created Source (path non-direct)', section: SECTION_REPORT.SOURCE_PATH },
                    { key: 'utmSourcePath', label: 'utm_source (path)', section: SECTION_REPORT.SOURCE_PATH },
                    { key: 'utmMediumPath', label: 'utm_medium (path)', section: SECTION_REPORT.SOURCE_PATH },
                    { key: 'utmSourceMediumPath', label: 'utm_source / utm_medium (path)', section: SECTION_REPORT.SOURCE_PATH },
                    { key: 'utmCampaignPath', label: 'utm_campaign (path)', section: SECTION_REPORT.SOURCE_PATH },
                    { key: 'utmContentPath', label: 'utm_content (path)', section: SECTION_REPORT.SOURCE_PATH },
                    { key: 'utmTermPath', label: 'utm_term (path)', section: SECTION_REPORT.SOURCE_PATH },
                    { key: 'referrerHostnamePath', label: 'Referrer Hostname (path)', section: SECTION_REPORT.SOURCE_PATH },
                    { key: 'referrerOriginPath', label: 'Referrer Origin Path (path)', section: SECTION_REPORT.SOURCE_PATH },
                    { key: 'referrerPath', label: 'Referrer Path (path)', section: SECTION_REPORT.SOURCE_PATH },
                    { key: 'landingPageOriginPath', label: 'Landing Page (path)', section: SECTION_REPORT.SOURCE_PATH },
               ],
               defaultDimensions: [
                    { key: 'conversionName', label: 'Conversion Name', section: SECTION_REPORT.DETAILS },
                    { key: 'primaryObject', label: 'Primary Object', section: SECTION_REPORT.DETAILS },
               ],
               dimensionLength: 3,
               requireDimensions: [
                    { key: 'conversionName', label: 'Conversion Name', section: SECTION_REPORT.DETAILS },
                    { key: 'conversionType', label: 'Conversion Type', section: SECTION_REPORT.DETAILS },
                    { key: 'conversionID', label: 'Conversion ID', section: SECTION_REPORT.DETAILS },
               ],
               properties: [],
               metrics: [
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true, section: SECTION_REPORT.COUNTS },
                    { key: 'uniqueConversionCount', label: 'Unique Conversions', isDefault: true, section: SECTION_REPORT.COUNTS },
                    { key: 'totalActiveSessionCount', label: 'Total Sessions', isDefault: true, section: SECTION_REPORT.TOTAL_ACTIVITY },
                    { key: 'totalActiveUserCount', label: 'Total Active Users', isDefault: true, section: SECTION_REPORT.TOTAL_ACTIVITY },
                    { key: 'totalActivePrimaryObjectCount', label: 'Primary Objects Created', isDefault: true, section: SECTION_REPORT.TOTAL_ACTIVITY },
                    // { key: 'totalActive[SecondaryObject]Count', label: 'Secondary Objects Created', isDefault: true, section: SECTION_REPORT.TOTAL_ACTIVITY },
                    { key: 'conversionEventSessionRate', label: 'Session Conv. Rate (All Conversions)', isDefault: false, section: SECTION_REPORT.CONV_RATE },
                    { key: 'conversionSessionRate', label: 'Session Conv. Rate (Unique Conversions)', isDefault: false, section: SECTION_REPORT.CONV_RATE },
                    { key: 'conversionEventUserRate', label: 'User Conv. Rate (All Conversions)', isDefault: false, section: SECTION_REPORT.CONV_RATE },
                    { key: 'conversionUserRate', label: 'User Conv. Rate (Unique Conversions)', isDefault: false, section: SECTION_REPORT.CONV_RATE },
                    { key: 'conversionEventPrimaryObjectRate', label: 'Primary Object Conversion Rate (All Conversions)', isDefault: true, section: SECTION_REPORT.CONV_RATE },
                    { key: 'conversionPrimaryObjectRate', label: 'Primary Object Conversion Rate (Unique Conversions)', isDefault: false, section: SECTION_REPORT.CONV_RATE },
                    // { key: 'conversionEvent[Secondary]ObjectRate', label: 'Secondary Object Conversion Rate (All Conversions)', isDefault: true, section: SECTION_REPORT.CONV_RATE },
                    // { key: 'conversion[Secondary]ObjectRate', label: 'Secondary Object Conversion Rate (Unique Conversions)', isDefault: true, section: SECTION_REPORT.CONV_RATE },
                    { key: 'conversionValue', label: 'Conversions Value', isDefault: false, section: SECTION_REPORT.VALUES },
                    { key: 'uniqueConversionValue', label: 'Unique Conversions Value (Primary Object)', isDefault: false, section: SECTION_REPORT.VALUES },
                    { key: 'avgConversionValue', label: 'Avg. Conversions Value', isDefault: false, section: SECTION_REPORT.VALUES },
                    { key: 'avgUniqueConversionValue', label: 'Avg. Unique Conversions Value (Primary Object)', isDefault: false, section: SECTION_REPORT.VALUES },
               ]
          }
     },
     [REPORT_NAMES.SALES_CONVERSION_BY_PROPERTY] : {
          [SCOPED.SALES_CONVERSION_EVENT]: {
               isDefaultMetrics: true,
               dimensions: [
                    { key: 'dateUTC', label: 'Date UTC', section: SECTION_REPORT.DATE_UTC },
                    { key: 'hourUTC', label: 'Hour UTC', section: SECTION_REPORT.DATE_UTC },
                    { key: 'dayOfWeekUTC', label: 'Day of Week UTC', section: SECTION_REPORT.DATE_UTC },
                    { key: 'isoWeekUTC', label: 'ISO Week UTC', section: SECTION_REPORT.DATE_UTC },
                    { key: 'monthUTC', label: 'Month UTC', section: SECTION_REPORT.DATE_UTC },
                    { key: 'yearMonthUTC', label: 'Year Month UTC', section: SECTION_REPORT.DATE_UTC },
                    { key: 'quarterUTC', label: 'Quarter UTC', section: SECTION_REPORT.DATE_UTC },
                    { key: 'yearQuarterUTC', label: 'Year Quarter UTC', section: SECTION_REPORT.DATE_UTC },
                    { key: 'date', label: 'Date', section: SECTION_REPORT.DATE_ACCOUNT },
                    { key: 'hour', label: 'Hour', section: SECTION_REPORT.DATE_ACCOUNT },
                    { key: 'dayOfWeek', label: 'Day of Week', section: SECTION_REPORT.DATE_ACCOUNT },
                    { key: 'isoWeek', label: 'ISO Week', section: SECTION_REPORT.DATE_ACCOUNT },
                    { key: 'month', label: 'Month', section: SECTION_REPORT.DATE_ACCOUNT },
                    { key: 'yearMonth', label: 'Year Month', section: SECTION_REPORT.DATE_ACCOUNT },
                    { key: 'quarter', label: 'Quarter', section: SECTION_REPORT.DATE_ACCOUNT },
                    { key: 'yearQuarter', label: 'Year Quarter', section: SECTION_REPORT.DATE_ACCOUNT },
                    { key: 'userSourceFirst', label: 'Created Source (first)', section: SECTION_REPORT.SOURCE_FIRST },
                    { key: 'userSourceFirstND', label: 'Created Source (first non-direct)', section: SECTION_REPORT.SOURCE_FIRST },
                    { key: 'utmSourceFirst', label: 'utm_source (first)', section: SECTION_REPORT.SOURCE_FIRST },
                    { key: 'utmMediumFirst', label: 'utm_medium (first)', section: SECTION_REPORT.SOURCE_FIRST },
                    { key: 'utmSourceMediumFirst', label: 'utm_source / utm_medium (first)', section: SECTION_REPORT.SOURCE_FIRST },
                    { key: 'utmCampaignFirst', label: 'utm_campaign (first)', section: SECTION_REPORT.SOURCE_FIRST },
                    { key: 'utmContentFirst', label: 'utm_content (first)', section: SECTION_REPORT.SOURCE_FIRST },
                    { key: 'utmTermFirst', label: 'utm_term (first)', section: SECTION_REPORT.SOURCE_FIRST },
                    { key: 'referrerHostnameFirst', label: 'Referrer Hostname (first)', section: SECTION_REPORT.SOURCE_FIRST },
                    { key: 'referrerOriginPathFirst', label: 'Referrer Origin Path (first)', section: SECTION_REPORT.SOURCE_FIRST },
                    { key: 'referrerPathFirst', label: 'Referrer Path (first)', section: SECTION_REPORT.SOURCE_FIRST },
                    { key: 'landingPageOriginPathFirst', label: 'Landing Page (first)', section: SECTION_REPORT.SOURCE_FIRST },
                    { key: 'userSourceLast', label: 'Created Source (last)', section: SECTION_REPORT.SOURCE_LAST },
                    { key: 'userSourceLastND', label: 'Created Source (last non-direct)', section: SECTION_REPORT.SOURCE_LAST },
                    { key: 'utmSourceLast', label: 'utm_source (last)', section: SECTION_REPORT.SOURCE_LAST },
                    { key: 'utmMediumLast', label: 'utm_medium (last)', section: SECTION_REPORT.SOURCE_LAST },
                    { key: 'utmSourceMediumLast', label: 'utm_source / utm_medium (last)', section: SECTION_REPORT.SOURCE_LAST },
                    { key: 'utmCampaignLast', label: 'utm_campaign (last)', section: SECTION_REPORT.SOURCE_LAST },
                    { key: 'utmContentLast', label: 'utm_content (last)', section: SECTION_REPORT.SOURCE_LAST },
                    { key: 'utmTermLast', label: 'utm_term (last)', section: SECTION_REPORT.SOURCE_LAST },
                    { key: 'referrerHostnameLast', label: 'Referrer Hostname (last)', section: SECTION_REPORT.SOURCE_LAST },
                    { key: 'referrerOriginPathLast', label: 'Referrer Origin Path (last)', section: SECTION_REPORT.SOURCE_LAST },
                    { key: 'referrerPathLast', label: 'Referrer Path (last)', section: SECTION_REPORT.SOURCE_LAST },
                    { key: 'landingPageOriginPathLast', label: 'Landing Page (last)', section: SECTION_REPORT.SOURCE_LAST },
                    { key: 'userSourcePath', label: 'Created Source (path)', section: SECTION_REPORT.SOURCE_PATH },
                    { key: 'userSourcePathND', label: 'Created Source (path non-direct)', section: SECTION_REPORT.SOURCE_PATH },
                    { key: 'utmSourcePath', label: 'utm_source (path)', section: SECTION_REPORT.SOURCE_PATH },
                    { key: 'utmMediumPath', label: 'utm_medium (path)', section: SECTION_REPORT.SOURCE_PATH },
                    { key: 'utmSourceMediumPath', label: 'utm_source / utm_medium (path)', section: SECTION_REPORT.SOURCE_PATH },
                    { key: 'utmCampaignPath', label: 'utm_campaign (path)', section: SECTION_REPORT.SOURCE_PATH },
                    { key: 'utmContentPath', label: 'utm_content (path)', section: SECTION_REPORT.SOURCE_PATH },
                    { key: 'utmTermPath', label: 'utm_term (path)', section: SECTION_REPORT.SOURCE_PATH },
                    { key: 'referrerHostnamePath', label: 'Referrer Hostname (path)', section: SECTION_REPORT.SOURCE_PATH },
                    { key: 'referrerOriginPath', label: 'Referrer Origin Path (path)', section: SECTION_REPORT.SOURCE_PATH },
                    { key: 'referrerPath', label: 'Referrer Path (path)', section: SECTION_REPORT.SOURCE_PATH },
                    { key: 'landingPageOriginPath', label: 'Landing Page (path)', section: SECTION_REPORT.SOURCE_PATH },
               ],
               defaultDimensions: [
                    { key: 'userSourceFirst', label: 'Created Source (first)', section: SECTION_REPORT.SOURCE_FIRST },
                    { key: 'userSourceLast', label: 'Created Source (last)', section: SECTION_REPORT.SOURCE_LAST },
               ],
               dimensionLength: 3,
               properties: [],
               metrics: [
                    { key: 'totalActiveSessionCount', label: 'Total Sessions', isDefault: true, section: SECTION_REPORT.TOTAL_ACTIVITY },
                    { key: 'totalActiveUserCount', label: 'Total Active Users', isDefault: true, section: SECTION_REPORT.TOTAL_ACTIVITY },
                    { key: 'totalActivePrimaryObjectCount', label: 'Primary Objects Created', isDefault: true, section: SECTION_REPORT.TOTAL_ACTIVITY },
                    // { key: 'total[Secondary]ObjectCreated', label: 'Sesondary Objects Created', isDefault: true, section: SECTION_REPORT.TOTAL_ACTIVITY },
                    { key: 'conversionCount', label: 'All Conversions', isDefault: true, section: SECTION_REPORT.COUNTS, sectionParent: SECTION_REPORT.ALL_CONV },
                    { key: 'uniqueConversionCount', label: 'Unique All Conversions', isDefault: false, section: SECTION_REPORT.COUNTS, sectionParent: SECTION_REPORT.ALL_CONV },
                    { key: 'conversionValue', label: 'All Conversions Value', isDefault: true, section: SECTION_REPORT.VALUES, sectionParent: SECTION_REPORT.ALL_CONV },
                    { key: 'uniqueConversionValue', label: 'Unique All Conversions Value (Primary Object)', isDefault: false, section: SECTION_REPORT.VALUES, sectionParent: SECTION_REPORT.ALL_CONV },
                    { key: 'avgConversionValue', label: 'Avg. Conv. Value (All Conversions)', isDefault: false, section: SECTION_REPORT.VALUES, sectionParent: SECTION_REPORT.ALL_CONV },
                    { key: 'avgUniqueConversionValue', label: 'Avg. Conv. Value (Unique All Conversions)', isDefault: false, section: SECTION_REPORT.VALUES, sectionParent: SECTION_REPORT.ALL_CONV },
                    { key: 'conversionSessionRate', label: 'Session Conv. Rate (All Conversions)', isDefault: false, section: SECTION_REPORT.CONV_RATE, sectionParent: SECTION_REPORT.ALL_CONV },
                    { key: 'conversionSessionRateUnique', label: 'Session Conv. Rate (Unique Conversions)', isDefault: false, section: SECTION_REPORT.CONV_RATE, sectionParent: SECTION_REPORT.ALL_CONV },
                    { key: 'conversionUserRate', label: 'User Conv. Rate (All Conversions)', isDefault: false, section: SECTION_REPORT.CONV_RATE, sectionParent: SECTION_REPORT.ALL_CONV },
                    { key: 'conversionUserRateUnique', label: 'User Conv. Rate (Unique Conversions)', isDefault: false, section: SECTION_REPORT.CONV_RATE, sectionParent: SECTION_REPORT.ALL_CONV },
                    { key: 'conversionPrimaryRate', label: 'Primary Object Conv. Rate (All Conversions)', isDefault: true, section: SECTION_REPORT.CONV_RATE, sectionParent: SECTION_REPORT.ALL_CONV },
                    { key: 'conversionPrimaryRateUnique', label: 'Primary Object Conv. Rate (Unique All Conversions)', isDefault: false, section: SECTION_REPORT.CONV_RATE, sectionParent: SECTION_REPORT.ALL_CONV },
                    { key: 'primaryConversionCount', label: 'Primary Conversions', isDefault: true, section: SECTION_REPORT.COUNTS, sectionParent: SECTION_REPORT.PRIMARY_CONV },
                    { key: 'primaryConversionCountUnique', label: 'Unique Primary Conversions', isDefault: false, section: SECTION_REPORT.COUNTS, sectionParent: SECTION_REPORT.PRIMARY_CONV },
                    { key: 'primaryConversionValue', label: 'Primary Conversions Value', isDefault: true, section: SECTION_REPORT.VALUES, sectionParent: SECTION_REPORT.PRIMARY_CONV },
                    { key: 'primaryConversionValueUnique', label: 'Unique Primary Conversions Value (Primary Object)', isDefault: false, section: SECTION_REPORT.VALUES, sectionParent: SECTION_REPORT.PRIMARY_CONV },
                    { key: 'avgPrimaryConversionValue', label: 'Avg. Conv. Value (Primary Conversions)', isDefault: false, section: SECTION_REPORT.VALUES, sectionParent: SECTION_REPORT.PRIMARY_CONV },
                    { key: 'avgPrimaryConversionValueUnique', label: 'Avg. Conv. Value (Unique Primary Conversions)', isDefault: false, section: SECTION_REPORT.VALUES, sectionParent: SECTION_REPORT.PRIMARY_CONV },
                    { key: 'primaryConversionSessionRate', label: 'Session Conv. Rate (Primary Conversions)', isDefault: false, section: SECTION_REPORT.CONV_RATE, sectionParent: SECTION_REPORT.PRIMARY_CONV },
                    { key: 'primaryConversionSessionRateUnique', label: 'Session Conv. Rate (Unique Primary Conversions)', isDefault: false, section: SECTION_REPORT.CONV_RATE, sectionParent: SECTION_REPORT.PRIMARY_CONV },
                    { key: 'primaryConversionUserRate', label: 'User Conv. Rate (Primary Conversions)', isDefault: false, section: SECTION_REPORT.CONV_RATE, sectionParent: SECTION_REPORT.PRIMARY_CONV },
                    { key: 'primaryConversionUserRateUnique', label: 'User Conv. Rate (Unique Primary Conversions)', isDefault: false, section: SECTION_REPORT.CONV_RATE, sectionParent: SECTION_REPORT.PRIMARY_CONV },
                    { key: 'primaryConversionPrimaryObjectRate', label: 'Primary Object Conv. Rate (Primary Conversions)', isDefault: true, section: SECTION_REPORT.CONV_RATE, sectionParent: SECTION_REPORT.PRIMARY_CONV },
                    { key: 'primaryConversionPrimaryObjectRateUnique', label: 'Primary Object Conv. Rate (Unique Primary Conversions)', isDefault: false, section: SECTION_REPORT.CONV_RATE, sectionParent: SECTION_REPORT.PRIMARY_CONV },
                    { key: 'secondaryConversionCount', label: 'Secondary Conversions', isDefault: true, section: SECTION_REPORT.COUNTS, sectionParent: SECTION_REPORT.SECONDARY_CONV },
                    { key: 'secondaryConversionCountUnique', label: 'Unique Secondary Conversions', isDefault: false, section: SECTION_REPORT.COUNTS, sectionParent: SECTION_REPORT.SECONDARY_CONV },
                    { key: 'secondaryConversionValue', label: 'Secondary Conversions Value', isDefault: true, section: SECTION_REPORT.VALUES, sectionParent: SECTION_REPORT.SECONDARY_CONV },
                    { key: 'secondaryConversionValueUnique', label: 'Unique Secondary Conversions Value (Primary Object)', isDefault: false, section: SECTION_REPORT.VALUES, sectionParent: SECTION_REPORT.SECONDARY_CONV },
                    { key: 'avgSecondaryConversionValue', label: 'Avg. Conv. Value (Secondary Conversions)', isDefault: false, section: SECTION_REPORT.VALUES, sectionParent: SECTION_REPORT.SECONDARY_CONV },
                    { key: 'avgSecondaryConversionValueUnique', label: 'Avg. Conv. Value (Unique Secondary Conversions)', isDefault: false, section: SECTION_REPORT.VALUES, sectionParent: SECTION_REPORT.SECONDARY_CONV },
                    { key: 'secondaryConversionSessionRate', label: 'Session Conv. Rate (Secondary Conversions)', isDefault: false, section: SECTION_REPORT.CONV_RATE, sectionParent: SECTION_REPORT.SECONDARY_CONV },
                    { key: 'secondaryConversionSessionRateUnique', label: 'Session Conv. Rate (Unique Secondary Conversions)', isDefault: false, section: SECTION_REPORT.CONV_RATE, sectionParent: SECTION_REPORT.SECONDARY_CONV },
                    { key: 'secondaryConversionUserRate', label: 'User Conv. Rate (Secondary Conversions)', isDefault: false, section: SECTION_REPORT.CONV_RATE, sectionParent: SECTION_REPORT.SECONDARY_CONV },
                    { key: 'secondaryConversionUserRateUnique', label: 'User Conv. Rate (Unique Secondary Conversions)', isDefault: false, section: SECTION_REPORT.CONV_RATE, sectionParent: SECTION_REPORT.SECONDARY_CONV },
                    { key: 'secondaryConversionPrimaryObjectRate', label: 'Primary Object Conv. Rate (Secondary Conversions)', isDefault: true, section: SECTION_REPORT.CONV_RATE, sectionParent: SECTION_REPORT.SECONDARY_CONV },
                    { key: 'secondaryConversionPrimaryObjectRateUnique', label: 'Primary Object Conv. Rate (Unique Secondary Conversions)', isDefault: false, section: SECTION_REPORT.CONV_RATE, sectionParent: SECTION_REPORT.SECONDARY_CONV },
               ]
          }
     }
};
export const REPORT_TAB_TYPES = {
     METRICS: 'Metrics',
     DIMENSIONS: 'Dimensions',
     PROPERTIES: 'Properties',
     FILTER: 'Filter',
     GROUP: 'Group',
     EXPORT: 'Export',
     SAVE: 'Save',
     MODEL: 'Model',
};
export const TOTAL_REPORT = {
     [REPORT_NAMES.USER_SOURCE_SESSION_REPORT]: [
          {
               key: 'conversionCount',
               value: 'totalConversionCount',
          },
          {
               key: 'primaryConversionCount',
               value: 'totalPrimaryConversionCount',
          },
          {
               key: 'secondaryConversionCount',
               value: 'totalSecondaryConversionCount',
          },
          {
               key: 'activeUserCount',
               value: 'totalActiveUserCount',
          },
          {
               key: 'newUserCount',
               value: 'totalNewUserCount',
          },
          {
               key: 'avgSessionDuration',
               value: 'totalAvgSessionDuration',
          },
          {
               key: 'avgPageViewsPerSession',
               value: 'totalAvgPageViewsPerSession',
          },
     ],
};

export const FILTER_DATA_TYPES = {
     [REPORT_NAMES.EVENT_DETAILS]: [
          {
               type: 'companyId',
               label: 'Revealed Company ID',
               value: '',
               operator: 'ct',
          },
     ],
     [REPORT_NAMES.CONVERSION_DETAILS]: [
          {
               type: 'userId',
               label: 'user ID',
               value: '',
               operator: 'ct',
          },
          {
               type: 'eventName',
               label: 'event Name',
               value: '',
               operator: 'ct',
          },
     ],
     [REPORT_NAMES.CONVERSION_NAME_DETAILS]: [
          {
               type: '',
               value: '',
               operator: 'ct',
          },
     ],
     [REPORT_NAMES.FORM_SUBMISSION_EVENTS]: [
          {
               type: '',
               value: '',
               operator: 'ct',
          },
     ],
     [REPORT_NAMES.CONVERSION_EVENTS]: [
          {
               type: '',
               value: '',
               operator: 'ct',
          },
     ],
     [REPORT_NAMES.FORM_CATEGORY_REPORT]: [
          {
               type: '',
               value: '',
               operator: 'ct',
          },
     ],
     [REPORT_NAMES.PAGE_VIEW_DETAILS]: [
          {
               type: '',
               value: '',
               operator: 'ct',
          },
     ],
     [REPORT_NAMES.PERSON_DETAILS]: [
          {
               type: '',
               value: '',
               operator: 'ct',
          },
          {
               type: 'targetAccountId',
               label: 'Target AccountID',
               value: '',
               operator: 'ct',
          },
          {
               type: 'companyId',
               label: 'Revealed Company ID',
               value: '',
               operator: 'ct',
          },
          {
               type: 'consentMethod',
               label: 'Consent Method',
               value: '',
               operator: 'ct',
          },
          {
               type: 'trackingLevel',
               label: 'Tracking Level',
               value: '',
               operator: 'ct',
          },
          {
               type: 'regionalRule',
               label: 'Regional Rule',
               value: '',
               operator: 'ct',
          },
          {
               type: 'country',
               label: 'Country',
               value: '',
               operator: 'ct',
          },
          {
               type: 'region',
               label: 'Region',
               value: '',
               operator: 'ct',
          },
          {
               type: 'city',
               label: 'City',
               value: '',
               operator: 'ct',
          },
          {
               type: 'deviceType',
               label: 'Device Type',
               value: '',
               operator: 'ct',
          },
          {
               type: 'deviceFamily',
               label: 'Device Family',
               value: '',
               operator: 'ct',
          },
          {
               type: 'deviceManufacturer',
               label: 'Manufacturer',
               value: '',
               operator: 'ct',
          },
          {
               type: 'browserFamily',
               label: 'Browser Family',
               value: '',
               operator: 'ct',
          },
          {
               type: 'browserVersion',
               label: 'Browser Version (general)',
               value: '',
               operator: 'ct',
          },
          {
               type: 'browserDetailedVersion',
               label: 'Browser Version (specific)',
               value: '',
               operator: 'ct',
          },
          {
               type: 'personType',
               label: 'Person Type',
               value: '',
               operator: 'ct',
          },
          {
               type: 'revealedCompanyType',
               label: 'Revealed Company Type',
               value: '',
               operator: 'ct',
          },
          {
               type: 'userType',
               label: 'User Type',
               value: '',
               operator: 'ct',
          },
          {
               type: 'sessionCountRow',
               label: 'Session Count',
               value: '',
               operator: 'ct',
          },
          {
               type: 'totalSessionDurationType',
               label: 'Total Session Duration',
               value: '',
               operator: 'ct',
          },
          {
               type: 'conversionCountRow',
               label: 'Conversion Count',
               value: '',
               operator: 'ct',
          },
          {
               type: 'originPath',
               label: 'All Page OriginPath',
               value: '',
               operator: 'ct',
          },
          {
               type: 'exitPageOriginPath',
               label: 'Exit Page OriginPath',
               value: '',
               operator: 'ct',
          },
          {
               type: 'exitPageOriginPath',
               label: 'Exit Page',
               value: '',
               operator: 'ct',
          },
          {
               type: 'landingPageOriginPath',
               label: 'Landing Page OriginPath',
               value: '',
               operator: 'ct',
          },
          {
               type: 'landingPageOriginPath',
               label: 'Landing Page',
               value: '',
               operator: 'ct',
          },
          {
               type: 'path',
               label: 'All Page Path',
               value: '',
               operator: 'ct',
          },
          {
               type: 'hostName',
               label: 'All Page Hostname',
               value: '',
               operator: 'ct',
          },
          {
               type: 'hostName',
               label: 'Landing Page Hostname',
               value: '',
               operator: 'ct',
          },
          {
               type: 'hostName',
               label: 'Exit Page Hostname',
               value: '',
               operator: 'ct',
          },
          {
               type: 'userSource',
               label: 'User Source Session',
               value: '',
               operator: 'ct',
          },
          {
               type: 'channel',
               label: 'Channel',
               value: '',
               operator: 'ct',
          },
          {
               type: 'channelFirst',
               label: 'Channel First',
               value: '',
               operator: 'ct',
          },
          {
               type: 'channelLast',
               label: 'Channel Last',
               value: '',
               operator: 'ct',
          },
          {
               type: 'channelPath',
               label: 'Channel Path',
               value: '',
               operator: 'ct',
          },
          {
               type: 'utmSource',
               label: 'utm_source',
               value: '',
               operator: 'ct',
          },
          {
               type: 'utmMedium',
               label: 'utm_medium',
               value: '',
               operator: 'ct',
          },
          {
               type: 'utmSourceMedium',
               label: 'utm_medium / utm_source',
               value: '',
               operator: 'ct',
          },
          {
               type: 'utmCampaign',
               label: 'utm_campaign',
               value: '',
               operator: 'ct',
          },
          {
               type: 'utmTerm',
               label: 'utm_term',
               value: '',
               operator: 'ct',
          },
          {
               type: 'utmContent',
               label: 'utm_content',
               value: '',
               operator: 'ct',
          },
          {
               type: 'referrerOriginPath',
               label: 'Referring OriginPath',
               value: '',
               operator: 'ct',
          },
          {
               type: 'referrerHostname',
               label: 'Referring Hostname',
               value: '',
               operator: 'ct',
          },
          {
               type: 'referrerPath',
               label: 'Referring PathName',
               value: '',
               operator: 'ct',
          },
          {
               type: 'conversionType',
               label: 'Conversion Type',
               value: '',
               operator: 'ct',
          },
          {
               type: 'conversionName',
               label: 'Conversion Name',
               value: '',
               operator: 'ct',
          },
          {
               type: 'conversionByUrl',
               label: 'Conversion Location URL',
               value: '',
               operator: 'ct',
          },
          {
               type: 'userCountRow',
               label: 'User Count',
               value: '',
               operator: 'ct',
          },
          {
               type: 'peopleCountRow',
               label: 'People Count',
               value: '',
               operator: 'ct',
          },
          {
               type: 'eCommerceAction',
               label: 'Ecommerce Action',
               value: '',
               operator: 'ct',
          },
          {
               type: 'sessionSource',
               label: 'Session Source',
               value: '',
               operator: 'ct',
          },
     ],
     [REPORT_NAMES.PERSON_DETAILS_PROFILE]: [
          {
               type: '',
               value: '',
               operator: 'ct',
          },
          {
               type: 'targetAccountId',
               label: 'Target AccountID',
               value: '',
               operator: 'ct',
          },
          {
               type: 'companyId',
               label: 'Revealed Company ID',
               value: '',
               operator: 'ct',
          },
          {
               type: 'consentMethod',
               label: 'Consent Method',
               value: '',
               operator: 'ct',
          },
          {
               type: 'trackingLevel',
               label: 'Tracking Level',
               value: '',
               operator: 'ct',
          },
          {
               type: 'regionalRule',
               label: 'Regional Rule',
               value: '',
               operator: 'ct',
          },
          {
               type: 'country',
               label: 'Country',
               value: '',
               operator: 'ct',
          },
          {
               type: 'region',
               label: 'Region',
               value: '',
               operator: 'ct',
          },
          {
               type: 'city',
               label: 'City',
               value: '',
               operator: 'ct',
          },
          {
               type: 'deviceType',
               label: 'Device Type',
               value: '',
               operator: 'ct',
          },
          {
               type: 'deviceFamily',
               label: 'Device Family',
               value: '',
               operator: 'ct',
          },
          {
               type: 'deviceManufacturer',
               label: 'Manufacturer',
               value: '',
               operator: 'ct',
          },
          {
               type: 'browserFamily',
               label: 'Browser Family',
               value: '',
               operator: 'ct',
          },
          {
               type: 'browserVersion',
               label: 'Browser Version (general)',
               value: '',
               operator: 'ct',
          },
          {
               type: 'browserDetailedVersion',
               label: 'Browser Version (specific)',
               value: '',
               operator: 'ct',
          },
          {
               type: 'personType',
               label: 'Person Type',
               value: '',
               operator: 'ct',
          },
          {
               type: 'revealedCompanyType',
               label: 'Revealed Company Type',
               value: '',
               operator: 'ct',
          },
          {
               type: 'userType',
               label: 'User Type',
               value: '',
               operator: 'ct',
          },
          {
               type: 'sessionCountRow',
               label: 'Session Count',
               value: '',
               operator: 'ct',
          },
          {
               type: 'totalSessionDurationType',
               label: 'Total Session Duration',
               value: '',
               operator: 'ct',
          },
          {
               type: 'conversionCountRow',
               label: 'Conversion Count',
               value: '',
               operator: 'ct',
          },
          {
               type: 'originPath',
               label: 'All Page OriginPath',
               value: '',
               operator: 'ct',
          },
          {
               type: 'exitPageOriginPath',
               label: 'Exit Page OriginPath',
               value: '',
               operator: 'ct',
          },
          {
               type: 'exitPageOriginPath',
               label: 'Exit Page',
               value: '',
               operator: 'ct',
          },
          {
               type: 'landingPageOriginPath',
               label: 'Landing Page OriginPath',
               value: '',
               operator: 'ct',
          },
          {
               type: 'landingPageOriginPath',
               label: 'Landing Page',
               value: '',
               operator: 'ct',
          },
          {
               type: 'path',
               label: 'All Page Path',
               value: '',
               operator: 'ct',
          },
          {
               type: 'hostName',
               label: 'All Page Hostname',
               value: '',
               operator: 'ct',
          },
          {
               type: 'hostName',
               label: 'Landing Page Hostname',
               value: '',
               operator: 'ct',
          },
          {
               type: 'hostName',
               label: 'Exit Page Hostname',
               value: '',
               operator: 'ct',
          },
          {
               type: 'userSource',
               label: 'User Source Session',
               value: '',
               operator: 'ct',
          },
          {
               type: 'utmSource',
               label: 'utm_source',
               value: '',
               operator: 'ct',
          },
          {
               type: 'utmMedium',
               label: 'utm_medium',
               value: '',
               operator: 'ct',
          },
          {
               type: 'utmSourceMedium',
               label: 'utm_medium / utm_source',
               value: '',
               operator: 'ct',
          },
          {
               type: 'utmCampaign',
               label: 'utm_campaign',
               value: '',
               operator: 'ct',
          },
          {
               type: 'utmTerm',
               label: 'utm_term',
               value: '',
               operator: 'ct',
          },
          {
               type: 'utmContent',
               label: 'utm_content',
               value: '',
               operator: 'ct',
          },
          {
               type: 'referrerOriginPath',
               label: 'Referring OriginPath',
               value: '',
               operator: 'ct',
          },
          {
               type: 'referrerHostname',
               label: 'Referring Hostname',
               value: '',
               operator: 'ct',
          },
          {
               type: 'referrerPath',
               label: 'Referring PathName',
               value: '',
               operator: 'ct',
          },
          {
               type: 'conversionType',
               label: 'Conversion Type',
               value: '',
               operator: 'ct',
          },
          {
               type: 'conversionName',
               label: 'Conversion Name',
               value: '',
               operator: 'ct',
          },
          {
               type: 'conversionByUrl',
               label: 'Conversion Location URL',
               value: '',
               operator: 'ct',
          },
          {
               type: 'userCountRow',
               label: 'User Count',
               value: '',
               operator: 'ct',
          },
          {
               type: 'peopleCountRow',
               label: 'People Count',
               value: '',
               operator: 'ct',
          },
     ],
     [REPORT_NAMES.SESSION_DETAILS]: [
          {
               type: '',
               value: '',
               operator: 'ct',
          },
          {
               type: 'userId',
               label: 'User ID',
               value: '',
               operator: 'ct',
          },
          {
               type: 'personId',
               label: 'Person ID',
               value: '',
               operator: 'ct',
          },
          {
               type: 'companyId',
               label: 'Revealed Company ID',
               value: '',
               operator: 'ct',
          },
          {
               type: 'targetAccountId',
               label: 'Target AccountID',
               value: '',
               operator: 'ct',
          },
          {
               type: 'consentMethod',
               label: 'Consent Method',
               value: '',
               operator: 'ct',
          },
          {
               type: 'trackingLevel',
               label: 'Tracking Level',
               value: '',
               operator: 'ct',
          },
          {
               type: 'regionalRule',
               label: 'Regional Rule',
               value: '',
               operator: 'ct',
          },
          {
               type: 'country',
               label: 'Country',
               value: '',
               operator: 'ct',
          },
          {
               type: 'region',
               label: 'Region',
               value: '',
               operator: 'ct',
          },
          {
               type: 'city',
               label: 'City',
               value: '',
               operator: 'ct',
          },
          {
               type: 'deviceType',
               label: 'Device Type',
               value: '',
               operator: 'ct',
          },
          {
               type: 'deviceFamily',
               label: 'Device Family',
               value: '',
               operator: 'ct',
          },
          {
               type: 'deviceManufacturer',
               label: 'Manufacturer',
               value: '',
               operator: 'ct',
          },
          {
               type: 'browserFamily',
               label: 'Browser Family',
               value: '',
               operator: 'ct',
          },
          {
               type: 'browserVersion',
               label: 'Browser Version (general)',
               value: '',
               operator: 'ct',
          },
          {
               type: 'browserDetailedVersion',
               label: 'Browser Version (specific)',
               value: '',
               operator: 'ct',
          },
          {
               type: 'personType',
               label: 'Person Type',
               value: '',
               operator: 'ct',
          },
          {
               type: 'userType',
               label: 'User Type',
               value: '',
               operator: 'ct',
          },
          {
               type: 'revealedCompanyType',
               label: 'Revealed Company Type',
               value: '',
               operator: 'ct',
          },
          {
               type: 'sessionCountRow',
               label: 'Session Count',
               value: '',
               operator: 'ct',
          },
          {
               type: 'totalSessionDurationType',
               label: 'Total Session Duration',
               value: '',
               operator: 'ct',
          },
          {
               type: 'conversionCountRow',
               label: 'Conversion Count',
               value: '',
               operator: 'ct',
          },
          {
               type: 'originPath',
               label: 'All Page OriginPath',
               value: '',
               operator: 'ct',
          },
          {
               type: 'exitPageOriginPath',
               label: 'Exit Page OriginPath',
               value: '',
               operator: 'ct',
          },
          {
               type: 'exitPageOriginPath',
               label: 'Exit Page',
               value: '',
               operator: 'ct',
          },
          {
               type: 'landingPageOriginPath',
               label: 'Landing Page OriginPath',
               value: '',
               operator: 'ct',
          },
          {
               type: 'landingPageOriginPath',
               label: 'Landing Page',
               value: '',
               operator: 'ct',
          },
          {
               type: 'path',
               label: 'All Page Path',
               value: '',
               operator: 'ct',
          },
          {
               type: 'hostName',
               label: 'All Page Hostname',
               value: '',
               operator: 'ct',
          },
          {
               type: 'hostName',
               label: 'Landing Page Hostname',
               value: '',
               operator: 'ct',
          },
          {
               type: 'hostName',
               label: 'Exit Page Hostname',
               value: '',
               operator: 'ct',
          },
          {
               type: 'userSourceFirst',
               label: 'User Source First',
               value: '',
               operator: 'ct',
          },
          {
               type: 'userSourceLast',
               label: 'User Source Last',
               value: '',
               operator: 'ct',
          },
          {
               type: 'userSourcePath',
               label: 'User Source Path',
               value: '',
               operator: 'ct',
          },
          {
               type: 'channel',
               label: 'Channel',
               value: '',
               operator: 'ct',
          },
          {
               type: 'channelFirst',
               label: 'Channel First',
               value: '',
               operator: 'ct',
          },
          {
               type: 'channelLast',
               label: 'Channel Last',
               value: '',
               operator: 'ct',
          },
          {
               type: 'channelPath',
               label: 'Channel Path',
               value: '',
               operator: 'ct',
          },
          {
               type: 'utmSource',
               label: 'utm_source',
               value: '',
               operator: 'ct',
          },
          {
               type: 'utmMedium',
               label: 'utm_medium',
               value: '',
               operator: 'ct',
          },
          {
               type: 'utmSourceMedium',
               label: 'utm_medium / utm_source',
               value: '',
               operator: 'ct',
          },
          {
               type: 'utmCampaign',
               label: 'utm_campaign',
               value: '',
               operator: 'ct',
          },
          {
               type: 'utmTerm',
               label: 'utm_term',
               value: '',
               operator: 'ct',
          },
          {
               type: 'utmContent',
               label: 'utm_content',
               value: '',
               operator: 'ct',
          },
          {
               type: 'referrerOriginPath',
               label: 'Referring OriginPath',
               value: '',
               operator: 'ct',
          },
          {
               type: 'referrerHostname',
               label: 'Referring Hostname',
               value: '',
               operator: 'ct',
          },
          {
               type: 'referrerPath',
               label: 'Referring PathName',
               value: '',
               operator: 'ct',
          },
          {
               type: 'conversionType',
               label: 'Conversion Type',
               value: '',
               operator: 'ct',
          },
          {
               type: 'conversionName',
               label: 'Conversion Name',
               value: '',
               operator: 'ct',
          },
          {
               type: 'conversionByUrl',
               label: 'Conversion Location URL',
               value: '',
               operator: 'ct',
          },
          {
               type: 'userCountRow',
               label: 'User Count',
               value: '',
               operator: 'ct',
          },
          {
               type: 'peopleCountRow',
               label: 'People Count',
               value: '',
               operator: 'ct',
          },
          {
               type: 'eCommerceAction',
               label: 'Ecommerce Action',
               value: '',
               operator: 'ct',
          },
          {
               type: 'sessionSource',
               label: 'Session Source',
               value: '',
               operator: 'ct',
          },
     ],
     [REPORT_NAMES.USER_DETAILS]: [
          {
               type: 'userSource',
               label: 'User Source Session',
               value: '',
               operator: 'ct',
          },
          {
               type: 'targetAccountId',
               label: 'Target AccountID',
               value: '',
               operator: 'ct',
          },
          {
               type: 'companyId',
               label: 'Revealed Company ID',
               value: '',
               operator: 'ct',
          },
          {
               type: 'landingPage',
               label: 'Landing Page',
               value: '',
               operator: 'ct',
          },
          {
               type: 'exitPage',
               label: 'Exit Page',
               value: '',
               operator: 'ct',
          },
          {
               type: 'consentMethod',
               label: 'Consent Method',
               value: '',
               operator: 'ct',
          },
          {
               type: 'trackingLevel',
               label: 'Tracking Level',
               value: '',
               operator: 'ct',
          },
          {
               type: 'regionalRule',
               label: 'Regional Rule',
               value: '',
               operator: 'ct',
          },
          {
               type: 'country',
               label: 'Country',
               value: '',
               operator: 'ct',
          },
          {
               type: 'region',
               label: 'Region',
               value: '',
               operator: 'ct',
          },
          {
               type: 'city',
               label: 'City',
               value: '',
               operator: 'ct',
          },
          {
               type: 'deviceType',
               label: 'Device Type',
               value: '',
               operator: 'ct',
          },
          {
               type: 'deviceFamily',
               label: 'Device Family',
               value: '',
               operator: 'ct',
          },
          {
               type: 'deviceManufacturer',
               label: 'Manufacturer',
               value: '',
               operator: 'ct',
          },
          {
               type: 'browserFamily',
               label: 'Browser Family',
               value: '',
               operator: 'ct',
          },
          {
               type: 'browserVersion',
               label: 'Browser Version (general)',
               value: '',
               operator: 'ct',
          },
          {
               type: 'browserDetailedVersion',
               label: 'Browser Version (specific)',
               value: '',
               operator: 'ct',
          },
          {
               type: 'personType',
               label: 'Person Type',
               value: '',
               operator: 'ct',
          },
          {
               type: 'userType',
               label: 'User Type',
               value: '',
               operator: 'ct',
          },
          {
               type: 'revealedCompanyType',
               label: 'Revealed Company Type',
               value: '',
               operator: 'ct',
          },
          {
               type: 'sessionCountRow',
               label: 'Session Count',
               value: '',
               operator: 'ct',
          },
          {
               type: 'conversionCountRow',
               label: 'Conversion Count',
               value: '',
               operator: 'ct',
          },
          {
               type: 'userCountRow',
               label: 'User Count',
               value: '',
               operator: 'ct',
          },
          {
               type: 'peopleCountRow',
               label: 'People Count',
               value: '',
               operator: 'ct',
          },
          {
               type: 'totalSessionDurationType',
               label: 'Total Session Duration',
               value: '',
               operator: 'ct',
          },
          {
               type: 'originPath',
               label: 'All Page OriginPath',
               value: '',
               operator: 'ct',
          },
          {
               type: 'exitPageOriginPath',
               label: 'Exit Page OriginPath',
               value: '',
               operator: 'ct',
          },
          {
               type: 'exitPageOriginPath',
               label: 'Exit Page',
               value: '',
               operator: 'ct',
          },
          {
               type: 'landingPageOriginPath',
               label: 'Landing Page OriginPath',
               value: '',
               operator: 'ct',
          },
          {
               type: 'landingPageOriginPath',
               label: 'Landing Page',
               value: '',
               operator: 'ct',
          },
          {
               type: 'path',
               label: 'Landing Page Path',
               value: '',
               operator: 'ct',
          },
          {
               type: 'hostName',
               label: 'All Page Hostname',
               value: '',
               operator: 'ct',
          },
          {
               type: 'hostName',
               label: 'Landing Page Hostname',
               value: '',
               operator: 'ct',
          },
          {
               type: 'hostName',
               label: 'Exit Page Hostname',
               value: '',
               operator: 'ct',
          },
          {
               type: 'channel',
               label: 'Channel',
               value: '',
               operator: 'ct',
          },
          {
               type: 'channelFirst',
               label: 'Channel First',
               value: '',
               operator: 'ct',
          },
          {
               type: 'channelLast',
               label: 'Channel Last',
               value: '',
               operator: 'ct',
          },
          {
               type: 'channelPath',
               label: 'Channel Path',
               value: '',
               operator: 'ct',
          },
          {
               type: 'utmSource',
               label: 'utm_source',
               value: '',
               operator: 'ct',
          },
          {
               type: 'utmMedium',
               label: 'utm_medium',
               value: '',
               operator: 'ct',
          },
          {
               type: 'utmSourceMedium',
               label: 'utm_medium / utm_source',
               value: '',
               operator: 'ct',
          },
          {
               type: 'utmCampaign',
               label: 'utm_campaign',
               value: '',
               operator: 'ct',
          },
          {
               type: 'utmTerm',
               label: 'utm_term',
               value: '',
               operator: 'ct',
          },
          {
               type: 'utmContent',
               label: 'utm_content',
               value: '',
               operator: 'ct',
          },
          {
               type: 'referrerOriginPath',
               label: 'Referring OriginPath',
               value: '',
               operator: 'ct',
          },
          {
               type: 'referrerHostname',
               label: 'Referring Hostname',
               value: '',
               operator: 'ct',
          },
          {
               type: 'referrerPath',
               label: 'Referring PathName',
               value: '',
               operator: 'ct',
          },
          {
               type: 'conversionType',
               label: 'Conversion Type',
               value: '',
               operator: 'ct',
          },
          {
               type: 'conversionName',
               label: 'Conversion Name',
               value: '',
               operator: 'ct',
          },
          {
               type: 'conversionByUrl',
               label: 'Conversion Location URL',
               value: '',
               operator: 'ct',
          },
          {
               type: 'personId',
               label: 'Person ID',
               value: '',
               operator: 'ct',
          },
          {
               type: 'eCommerceAction',
               label: 'Ecommerce Action',
               value: '',
               operator: 'ct',
          },
          {
               type: 'sessionSource',
               label: 'Session Source',
               value: '',
               operator: 'ct',
          },
     ],
     [REPORT_NAMES.COMPANY_DETAILS]: [
          {
               type: '',
               value: '',
               operator: 'ct',
          },
          {
               type: 'userSource',
               label: 'User Source Session',
               value: '',
               operator: 'ct',
          },
          {
               type: 'landingPage',
               label: 'Landing Page',
               value: '',
               operator: 'ct',
          },
          {
               type: 'exitPage',
               label: 'Exit Page',
               value: '',
               operator: 'ct',
          },
          {
               type: 'consentMethod',
               label: 'Consent Method',
               value: '',
               operator: 'ct',
          },
          {
               type: 'trackingLevel',
               label: 'Tracking Level',
               value: '',
               operator: 'ct',
          },
          {
               type: 'regionalRule',
               label: 'Regional Rule',
               value: '',
               operator: 'ct',
          },
          {
               type: 'country',
               label: 'Country',
               value: '',
               operator: 'ct',
          },
          {
               type: 'region',
               label: 'Region',
               value: '',
               operator: 'ct',
          },
          {
               type: 'city',
               label: 'City',
               value: '',
               operator: 'ct',
          },
          {
               type: 'deviceType',
               label: 'Device Type',
               value: '',
               operator: 'ct',
          },
          {
               type: 'deviceFamily',
               label: 'Device Family',
               value: '',
               operator: 'ct',
          },
          {
               type: 'deviceManufacturer',
               label: 'Manufacturer',
               value: '',
               operator: 'ct',
          },
          {
               type: 'browserFamily',
               label: 'Browser Family',
               value: '',
               operator: 'ct',
          },
          {
               type: 'browserVersion',
               label: 'Browser Version (general)',
               value: '',
               operator: 'ct',
          },
          {
               type: 'browserDetailedVersion',
               label: 'Browser Version (specific)',
               value: '',
               operator: 'ct',
          },
          {
               type: 'personType',
               label: 'Person Type',
               value: '',
               operator: 'ct',
          },
          {
               type: 'userType',
               label: 'User Type',
               value: '',
               operator: 'ct',
          },
          {
               type: 'revealedCompanyType',
               label: 'Revealed Company Type',
               value: '',
               operator: 'ct',
          },
          {
               type: 'sessionCountRow',
               label: 'Session Count',
               value: '',
               operator: 'ct',
          },
          {
               type: 'conversionCountRow',
               label: 'Conversion Count',
               value: '',
               operator: 'ct',
          },
          {
               type: 'userCountRow',
               label: 'User Count',
               value: '',
               operator: 'ct',
          },
          {
               type: 'peopleCountRow',
               label: 'People Count',
               value: '',
               operator: 'ct',
          },
          {
               type: 'totalSessionDurationType',
               label: 'Total Session Duration',
               value: '',
               operator: 'ct',
          },
          {
               type: 'originPath',
               label: 'All Page OriginPath',
               value: '',
               operator: 'ct',
          },
          {
               type: 'exitPageOriginPath',
               label: 'Exit Page OriginPath',
               value: '',
               operator: 'ct',
          },
          {
               type: 'exitPageOriginPath',
               label: 'Exit Page',
               value: '',
               operator: 'ct',
          },
          {
               type: 'landingPageOriginPath',
               label: 'Landing Page OriginPath',
               value: '',
               operator: 'ct',
          },
          {
               type: 'landingPageOriginPath',
               label: 'Landing Page',
               value: '',
               operator: 'ct',
          },
          {
               type: 'path',
               label: 'All Page Path',
               value: '',
               operator: 'ct',
          },
          {
               type: 'hostName',
               label: 'Exit Page Hostname',
               value: '',
               operator: 'ct',
          },
          {
               type: 'hostName',
               label: 'All Page Hostname',
               value: '',
               operator: 'ct',
          },
          {
               type: 'hostName',
               label: 'Landing Page Hostname',
               value: '',
               operator: 'ct',
          },
          {
               type: 'channel',
               label: 'Channel',
               value: '',
               operator: 'ct',
          },
          {
               type: 'channelFirst',
               label: 'Channel First',
               value: '',
               operator: 'ct',
          },
          {
               type: 'channelLast',
               label: 'Channel Last',
               value: '',
               operator: 'ct',
          },
          {
               type: 'channelPath',
               label: 'Channel Path',
               value: '',
               operator: 'ct',
          },
          {
               type: 'utmSource',
               label: 'utm_source',
               value: '',
               operator: 'ct',
          },
          {
               type: 'utmMedium',
               label: 'utm_medium',
               value: '',
               operator: 'ct',
          },
          {
               type: 'utmSourceMedium',
               label: 'utm_medium / utm_source',
               value: '',
               operator: 'ct',
          },
          {
               type: 'utmCampaign',
               label: 'utm_campaign',
               value: '',
               operator: 'ct',
          },
          {
               type: 'utmTerm',
               label: 'utm_term',
               value: '',
               operator: 'ct',
          },
          {
               type: 'utmContent',
               label: 'utm_content',
               value: '',
               operator: 'ct',
          },
          {
               type: 'referrerOriginPath',
               label: 'Referring OriginPath',
               value: '',
               operator: 'ct',
          },
          {
               type: 'referrerHostname',
               label: 'Referring Hostname',
               value: '',
               operator: 'ct',
          },
          {
               type: 'referrerPath',
               label: 'Referring PathName',
               value: '',
               operator: 'ct',
          },
          {
               type: 'conversionType',
               label: 'Conversion Type',
               value: '',
               operator: 'ct',
          },
          {
               type: 'conversionName',
               label: 'Conversion Name',
               value: '',
               operator: 'ct',
          },
          {
               type: 'conversionByUrl',
               label: 'Conversion Location URL',
               value: '',
               operator: 'ct',
          },
          {
               type: 'sessionId',
               label: 'Session ID',
               value: '',
               operator: 'ct',
          },
          {
               type: 'userId',
               label: 'User ID',
               value: '',
               operator: 'ct',
          },
          {
               type: 'personId',
               label: 'Person ID',
               value: '',
               operator: 'ct',
          },
          {
               type: 'targetAccountId',
               label: 'Target AccountID',
               value: '',
               operator: 'ct',
          },
          {
               type: 'sessionSource',
               label: 'Session Source',
               value: '',
               operator: 'ct',
          },
     ],
     [REPORT_NAMES.USER_SOURCE_SESSION_REPORT]: [
          {
               type: '',
               value: '',
               operator: 'ct',
          },
     ],
     [REPORT_NAMES.USER_SOURCE_USER_REPORT]: [
          {
               type: '',
               value: '',
               operator: 'ct',
          },
     ],
     [REPORT_NAMES.ENTRANCE_AND_EXIT_REPORT]: [
          {
               type: '',
               value: '',
               operator: 'ct',
          },
     ],
     [REPORT_NAMES.CONSENT_REPORT]: [
          {
               type: '',
               value: '',
               operator: 'ct',
          },
     ],
     [REPORT_NAMES.GEOGRAPHY_REPORT]: [
          {
               type: '',
               value: '',
               operator: 'ct',
          },
     ],
     [REPORT_NAMES.DEVICE_REPORT]: [
          {
               type: '',
               value: '',
               operator: 'ct',
          },
     ],
     [REPORT_NAMES.BROWSER_REPORT]: [
          {
               type: '',
               value: '',
               operator: 'ct',
          },
     ],
     [REPORT_NAMES.ALL_PAGE_REPORT]: [
          {
               type: '',
               value: '',
               operator: 'ct',
          },
     ],
     [REPORT_NAMES.LANDING_PAGE_REPORT]: [
          {
               type: '',
               value: '',
               operator: 'ct',
          },
     ],
     [REPORT_NAMES.EXIT_PAGE_REPORT]: [
          {
               type: '',
               value: '',
               operator: 'ct',
          },
     ],
     [REPORT_NAMES.CHANNEL_REPORT]: [
          {
               type: '',
               value: '',
               operator: 'ct',
          },
     ],
     [REPORT_NAMES.SOURCE_REPORT]: [
          {
               type: '',
               value: '',
               operator: 'ct',
          },
     ],
     [REPORT_NAMES.UTM_REPORT]: [
          {
               type: '',
               value: '',
               operator: 'ct',
          },
     ],
     [REPORT_NAMES.REFERRALS_REPORT]: [
          {
               type: '',
               value: '',
               operator: 'ct',
          },
     ],
     [REPORT_NAMES.NEW_RETURNING_REPORT]: [
          {
               type: '',
               value: '',
               operator: 'ct',
          },
     ],
     [REPORT_NAMES.SESSION_COUNT_REPORT]: [
          {
               type: '',
               value: '',
               operator: 'ct',
          },
     ],
     [REPORT_NAMES.SESSION_DURATION_REPORT]: [
          {
               type: '',
               value: '',
               operator: 'ct',
          },
     ],
     [REPORT_NAMES.CONVERSION_COUNT_REPORT]: [
          {
               type: '',
               value: '',
               operator: 'ct',
          },
     ],
     [REPORT_NAMES.PEOPLE_AND_USER_COUNT_REPORT]: [
          {
               type: '',
               value: '',
               operator: 'ct',
          },
     ],
     [REPORT_NAMES.BY_URL_REPORT]: [
          {
               type: '',
               value: '',
               operator: 'ct',
          },
     ],
     [REPORT_NAMES.BY_LANDING_PAGE_REPORT]: [
          {
               type: '',
               value: '',
               operator: 'ct',
          },
     ],
     [REPORT_NAMES.ALL_CONVERSION_REPORT]: [],
     [REPORT_NAMES.BY_CONVERSION]: [],
     [REPORT_NAMES.BY_PROPERTY]: [],
     [REPORT_NAMES.BY_SOURCE_REPORT]: [
          {
               type: '',
               value: '',
               operator: 'ct',
          },
     ],
     [REPORT_NAMES.BY_UTM_REPORT]: [
          {
               type: '',
               value: '',
               operator: 'ct',
          },
     ],
     [REPORT_NAMES.CONVERSION_SOURCE_ATTRIBUTION_REPORT]: [
          {
               type: '',
               value: '',
               operator: 'ct',
          },
     ],
     [REPORT_NAMES.ECOMMERCE_PURCHASE_FLOW]: [
          {
               type: '',
               value: '',
               operator: 'ct',
          },
     ],
     [REPORT_NAMES.ECOMMERCE_ITEMS]: [
          {
               type: '',
               value: '',
               operator: 'ct',
          },
     ],
     [REPORT_NAMES.ECOMMERCE_PURCHASE_ACTIVITY]: [
          {
               type: '',
               value: '',
               operator: 'ct',
          },
     ],
     [REPORT_NAMES.ECOMMERCE_PURCHASE_TRANSACTION]: [
          {
               type: '',
               value: '',
               operator: 'ct',
          },
     ],
     [REPORT_NAMES.SALES_CONVERSION_BY_CONVERSION]: [],
     [REPORT_NAMES.SALES_CONVERSION_BY_PROPERTY]: [],
};

export const OPTIONS_VALUES_FILTER_REPORT = {
     [REPORT_NAMES.BY_CONVERSION]: [
          {
               key: 'quarter',
               optionsValue: [
                    { label: 'Q1', value: 'Q1' },
                    { label: 'Q2', value: 'Q2' },
                    { label: 'Q3', value: 'Q3' },
                    { label: 'Q4', value: 'Q4' },
               ],
          },
          {
               key: 'quarterUTC',
               optionsValue: [
                    { label: 'Q1', value: 'Q1' },
                    { label: 'Q2', value: 'Q2' },
                    { label: 'Q3', value: 'Q3' },
                    { label: 'Q4', value: 'Q4' },
               ],
          },
          {
               key: 'monthUTC',
               optionsValue: [
                    { label: 'January', value: 'January' },
                    { label: 'February', value: 'February' },
                    { label: 'March', value: 'March' },
                    { label: 'April', value: 'April' },
                    { label: 'May', value: 'May' },
                    { label: 'June', value: 'June' },
                    { label: 'July', value: 'July' },
                    { label: 'August', value: 'August' },
                    { label: 'September', value: 'September' },
                    { label: 'October', value: 'October' },
                    { label: 'November', value: 'November' },
                    { label: 'December', value: 'December' },
               ],
          },
          {
               key: 'month',
               optionsValue: [
                    { label: 'January', value: 'January' },
                    { label: 'February', value: 'February' },
                    { label: 'March', value: 'March' },
                    { label: 'April', value: 'April' },
                    { label: 'May', value: 'May' },
                    { label: 'June', value: 'June' },
                    { label: 'July', value: 'July' },
                    { label: 'August', value: 'August' },
                    { label: 'September', value: 'September' },
                    { label: 'October', value: 'October' },
                    { label: 'November', value: 'November' },
                    { label: 'December', value: 'December' },
               ],
          },
          {
               key: 'dayOfWeekUTC',
               optionsValue: [
                    { label: 'Monday', value: 'Monday' },
                    { label: 'Tuesday', value: 'Tuesday' },
                    { label: 'Wednesday', value: 'Wednesday' },
                    { label: 'Thursday', value: 'Thursday' },
                    { label: 'Friday', value: 'Friday' },
                    { label: 'Saturday', value: 'Saturday' },
               ],
          },
          {
               key: 'dayOfWeek',
               optionsValue: [
                    { label: 'Monday', value: 'Monday' },
                    { label: 'Tuesday', value: 'Tuesday' },
                    { label: 'Wednesday', value: 'Wednesday' },
                    { label: 'Thursday', value: 'Thursday' },
                    { label: 'Friday', value: 'Friday' },
                    { label: 'Saturday', value: 'Saturday' },
               ],
          },
          {
               key: 'hour',
               optionsValue: [
                    { label: '1 AM', value: '1 AM' },
                    { label: '2 AM', value: '2 AM' },
                    { label: '3 AM', value: '3 AM' },
                    { label: '4 AM', value: '4 AM' },
                    { label: '5 AM', value: '5 AM' },
                    { label: '6 AM', value: '6 AM' },
                    { label: '7 AM', value: '7 AM' },
                    { label: '8 AM', value: '8 AM' },
                    { label: '9 AM', value: '9 AM' },
                    { label: '10 AM', value: '10 AM' },
                    { label: '11 AM', value: '11 AM' },
                    { label: '12 AM', value: '12 AM' },
                    { label: '1 PM', value: '1 PM' },
                    { label: '2 PM', value: '2 PM' },
                    { label: '3 PM', value: '3 PM' },
                    { label: '4 PM', value: '4 PM' },
                    { label: '5 PM', value: '5 PM' },
                    { label: '6 PM', value: '6 PM' },
                    { label: '7 PM', value: '7 PM' },
                    { label: '8 PM', value: '8 PM' },
                    { label: '9 PM', value: '9 PM' },
                    { label: '10 PM', value: '10 PM' },
                    { label: '11 PM', value: '11 PM' },
                    { label: '12 PM', value: '12 PM' },
               ],
          },
          {
               key: 'hourUTC',
               optionsValue: [
                    { label: '1 AM', value: '1 AM' },
                    { label: '2 AM', value: '2 AM' },
                    { label: '3 AM', value: '3 AM' },
                    { label: '4 AM', value: '4 AM' },
                    { label: '5 AM', value: '5 AM' },
                    { label: '6 AM', value: '6 AM' },
                    { label: '7 AM', value: '7 AM' },
                    { label: '8 AM', value: '8 AM' },
                    { label: '9 AM', value: '9 AM' },
                    { label: '10 AM', value: '10 AM' },
                    { label: '11 AM', value: '11 AM' },
                    { label: '12 AM', value: '12 AM' },
                    { label: '1 PM', value: '1 PM' },
                    { label: '2 PM', value: '2 PM' },
                    { label: '3 PM', value: '3 PM' },
                    { label: '4 PM', value: '4 PM' },
                    { label: '5 PM', value: '5 PM' },
                    { label: '6 PM', value: '6 PM' },
                    { label: '7 PM', value: '7 PM' },
                    { label: '8 PM', value: '8 PM' },
                    { label: '9 PM', value: '9 PM' },
                    { label: '10 PM', value: '10 PM' },
                    { label: '11 PM', value: '11 PM' },
                    { label: '12 PM', value: '12 PM' },
               ],
          },
     ],
     [REPORT_NAMES.BY_PROPERTY]: [
          {
               key: 'quarter',
               optionsValue: [
                    { label: 'Q1', value: 'Q1' },
                    { label: 'Q2', value: 'Q2' },
                    { label: 'Q3', value: 'Q3' },
                    { label: 'Q4', value: 'Q4' },
               ],
          },
          {
               key: 'quarterUTC',
               optionsValue: [
                    { label: 'Q1', value: 'Q1' },
                    { label: 'Q2', value: 'Q2' },
                    { label: 'Q3', value: 'Q3' },
                    { label: 'Q4', value: 'Q4' },
               ],
          },
          {
               key: 'monthUTC',
               optionsValue: [
                    { label: 'January', value: 'January' },
                    { label: 'February', value: 'February' },
                    { label: 'March', value: 'March' },
                    { label: 'April', value: 'April' },
                    { label: 'May', value: 'May' },
                    { label: 'June', value: 'June' },
                    { label: 'July', value: 'July' },
                    { label: 'August', value: 'August' },
                    { label: 'September', value: 'September' },
                    { label: 'October', value: 'October' },
                    { label: 'November', value: 'November' },
                    { label: 'December', value: 'December' },
               ],
          },
          {
               key: 'month',
               optionsValue: [
                    { label: 'January', value: 'January' },
                    { label: 'February', value: 'February' },
                    { label: 'March', value: 'March' },
                    { label: 'April', value: 'April' },
                    { label: 'May', value: 'May' },
                    { label: 'June', value: 'June' },
                    { label: 'July', value: 'July' },
                    { label: 'August', value: 'August' },
                    { label: 'September', value: 'September' },
                    { label: 'October', value: 'October' },
                    { label: 'November', value: 'November' },
                    { label: 'December', value: 'December' },
               ],
          },
          {
               key: 'dayOfWeekUTC',
               optionsValue: [
                    { label: 'Monday', value: 'Monday' },
                    { label: 'Tuesday', value: 'Tuesday' },
                    { label: 'Wednesday', value: 'Wednesday' },
                    { label: 'Thursday', value: 'Thursday' },
                    { label: 'Friday', value: 'Friday' },
                    { label: 'Saturday', value: 'Saturday' },
               ],
          },
          {
               key: 'dayOfWeek',
               optionsValue: [
                    { label: 'Monday', value: 'Monday' },
                    { label: 'Tuesday', value: 'Tuesday' },
                    { label: 'Wednesday', value: 'Wednesday' },
                    { label: 'Thursday', value: 'Thursday' },
                    { label: 'Friday', value: 'Friday' },
                    { label: 'Saturday', value: 'Saturday' },
               ],
          },
          {
               key: 'hour',
               optionsValue: [
                    { label: '1 AM', value: '1 AM' },
                    { label: '2 AM', value: '2 AM' },
                    { label: '3 AM', value: '3 AM' },
                    { label: '4 AM', value: '4 AM' },
                    { label: '5 AM', value: '5 AM' },
                    { label: '6 AM', value: '6 AM' },
                    { label: '7 AM', value: '7 AM' },
                    { label: '8 AM', value: '8 AM' },
                    { label: '9 AM', value: '9 AM' },
                    { label: '10 AM', value: '10 AM' },
                    { label: '11 AM', value: '11 AM' },
                    { label: '12 AM', value: '12 AM' },
                    { label: '1 PM', value: '1 PM' },
                    { label: '2 PM', value: '2 PM' },
                    { label: '3 PM', value: '3 PM' },
                    { label: '4 PM', value: '4 PM' },
                    { label: '5 PM', value: '5 PM' },
                    { label: '6 PM', value: '6 PM' },
                    { label: '7 PM', value: '7 PM' },
                    { label: '8 PM', value: '8 PM' },
                    { label: '9 PM', value: '9 PM' },
                    { label: '10 PM', value: '10 PM' },
                    { label: '11 PM', value: '11 PM' },
                    { label: '12 PM', value: '12 PM' },
               ],
          },
          {
               key: 'hourUTC',
               optionsValue: [
                    { label: '1 AM', value: '1 AM' },
                    { label: '2 AM', value: '2 AM' },
                    { label: '3 AM', value: '3 AM' },
                    { label: '4 AM', value: '4 AM' },
                    { label: '5 AM', value: '5 AM' },
                    { label: '6 AM', value: '6 AM' },
                    { label: '7 AM', value: '7 AM' },
                    { label: '8 AM', value: '8 AM' },
                    { label: '9 AM', value: '9 AM' },
                    { label: '10 AM', value: '10 AM' },
                    { label: '11 AM', value: '11 AM' },
                    { label: '12 AM', value: '12 AM' },
                    { label: '1 PM', value: '1 PM' },
                    { label: '2 PM', value: '2 PM' },
                    { label: '3 PM', value: '3 PM' },
                    { label: '4 PM', value: '4 PM' },
                    { label: '5 PM', value: '5 PM' },
                    { label: '6 PM', value: '6 PM' },
                    { label: '7 PM', value: '7 PM' },
                    { label: '8 PM', value: '8 PM' },
                    { label: '9 PM', value: '9 PM' },
                    { label: '10 PM', value: '10 PM' },
                    { label: '11 PM', value: '11 PM' },
                    { label: '12 PM', value: '12 PM' },
               ],
          },
     ],
}

export const DIMENSION_OPTION = {
     [SCOPED.EVENT]: [
          { value: 'eventID', label: 'Event ID' },
          { value: 'consentMethod', label: 'Consent Method' },
          { value: 'regionalConsentRule', label: 'Regional Consent Rule' },
          { value: 'trackingLevel', label: 'Tracking Level' },
          { value: 'identifyStrength', label: 'Identify Strength' },
          { value: 'analyticsStorage', label: 'analytics_storage' },
          { value: 'adsStorage', label: 'ads_storage' },
          { value: 'eventPageLocation', label: 'Event Page Location' },
          { value: 'eventHostnameLocation', label: 'Event Hostname Location' },
     ],
     [SCOPED.SESSION]: [
          { value: 'sessionID', label: 'Session ID' },
          { value: 'landingPage', label: 'Landing Page' },
          { value: 'exitPage', label: 'Exit Page' },
          { value: 'userSource', label: 'User Source' },
          { value: 'utmSource', label: 'utm_source' },
          { value: 'utmMedium', label: 'utm_medium' },
          { value: 'utmSourceMedium', label: 'utm_medium / utm_source' },
          { value: 'utmCampaign', label: 'utm_campaign' },
          { value: 'utmTerm', label: 'utm_term' },
          { value: 'consentMethod', label: 'Consent Method' },
          { value: 'regionalConsentRule', label: 'Regional Consent Rule' },
          { value: 'trackingLevel', label: 'Tracking Level' },
          { value: 'identifyStrength', label: 'Identify Strength' },
          { value: 'analyticsStorage', label: 'analytics_storage' },
          { value: 'adsStorage', label: 'ads_storage' },
          { value: 'deviceType', label: 'Device Type' },
          { value: 'browser', label: 'Browser' },
          { value: 'continent', label: 'Continent' },
          { value: 'country', label: 'Country' },
          { value: 'region', label: 'Region' },
          { value: 'postalCode', label: 'Postal Code' },
          { value: 'timeZone', label: 'Timezone' },
          { value: 'conversionSlug', label: 'Conversion Name (ID)' },
          { value: 'triggerSlug', label: 'Trigger Name (ID)' },
          { value: 'customValues', label: 'Custom Value' },
          { value: 'page', label: 'Page' },
          { value: 'hostname', label: 'Hostname' },
          { value: 'pageTitle', label: 'Page Title' },
          { value: 'eventPageLocation', label: 'Event Page Location' },
          { value: 'eventHostnameLocation', label: 'Event Hostname Location' },
     ],
     [SCOPED.USER]: [
          { value: 'userID', label: 'User ID' },
          { value: 'landingPage', label: 'Landing Page' },
          { value: 'exitPage', label: 'Exit Page' },
          { value: 'userSourceFirst', label: 'User Source - First' },
          { value: 'userSourceLast', label: 'User Source - Last' },
          { value: 'userSourcePath', label: 'User Source Path' },
          { value: 'utmSource', label: 'utm_source' },
          { value: 'utmMedium', label: 'utm_medium' },
          { value: 'utmSourceMedium', label: 'utm_medium / utm_source' },
          { value: 'utmCampaign', label: 'utm_campaign' },
          { value: 'utmTerm', label: 'utm_term' },
          { value: 'consentMethod', label: 'Consent Method' },
          { value: 'regionalConsentRule', label: 'Regional Consent Rule' },
          { value: 'trackingLevel', label: 'Tracking Level' },
          { value: 'identifyStrength', label: 'Identify Strength' },
          { value: 'analyticsStorage', label: 'analytics_storage' },
          { value: 'adsStorage', label: 'ads_storage' },
          { value: 'deviceType', label: 'Device Type' },
          { value: 'browser', label: 'Browser' },
          { value: 'continent', label: 'Continent' },
          { value: 'country', label: 'Country' },
          { value: 'region', label: 'Region' },
          { value: 'postalCode', label: 'Postal Code' },
          { value: 'timeZone', label: 'Timezone' },
          { value: 'conversionSlug', label: 'Conversion Name (ID)' },
          { value: 'triggerSlug', label: 'Trigger Name (ID)' },
          { value: 'customValues', label: 'Custom Value' },
          { value: 'page', label: 'Page' },
          { value: 'hostname', label: 'Hostname' },
          { value: 'pageTitle', label: 'Page Title' },
          { value: 'eventPageLocation', label: 'Event Page Location' },
          { value: 'eventHostnameLocation', label: 'Event Hostname Location' },
     ],
     [SCOPED.PEOPLE]: [
          { value: 'personID', label: 'Person ID' },
          { value: 'landingPage', label: 'Landing Page' },
          { value: 'exitPage', label: 'Exit Page' },
          { value: 'userSourceFirst', label: 'User Source - First' },
          { value: 'userSourceLast', label: 'User Source - Last' },
          { value: 'userSourcePath', label: 'User Source Path' },
          { value: 'utmSource', label: 'utm_source' },
          { value: 'utmMedium', label: 'utm_medium' },
          { value: 'utmSourceMedium', label: 'utm_medium / utm_source' },
          { value: 'utmCampaign', label: 'utm_campaign' },
          { value: 'utmTerm', label: 'utm_term' },
          { value: 'consentMethod', label: 'Consent Method' },
          { value: 'regionalConsentRule', label: 'Regional Consent Rule' },
          { value: 'trackingLevel', label: 'Tracking Level' },
          { value: 'identifyStrength', label: 'Identify Strength' },
          { value: 'analyticsStorage', label: 'analytics_storage' },
          { value: 'adsStorage', label: 'ads_storage' },
          { value: 'deviceType', label: 'Device Type' },
          { value: 'browser', label: 'Browser' },
          { value: 'continent', label: 'Continent' },
          { value: 'country', label: 'Country' },
          { value: 'region', label: 'Region' },
          { value: 'postalCode', label: 'Postal Code' },
          { value: 'timeZone', label: 'Timezone' },
          { value: 'conversionSlug', label: 'Conversion Name (ID)' },
          { value: 'triggerSlug', label: 'Trigger Name (ID)' },
          { value: 'customValues', label: 'Custom Value' },
          { value: 'page', label: 'Page' },
          { value: 'hostname', label: 'Hostname' },
          { value: 'pageTitle', label: 'Page Title' },
          { value: 'eventPageLocation', label: 'Event Page Location' },
          { value: 'eventHostnameLocation', label: 'Event Hostname Location' },
     ],
     [SCOPED.COMPANY]: [
          { value: 'companyID', label: 'Company ID' },
          { value: 'landingPage', label: 'Landing Page' },
          { value: 'exitPage', label: 'Exit Page' },
          { value: 'utmSource', label: 'utm_source' },
          { value: 'utmMedium', label: 'utm_medium' },
          { value: 'utmSourceMedium', label: 'utm_medium / utm_source' },
          { value: 'utmCampaign', label: 'utm_campaign' },
          { value: 'utmTerm', label: 'utm_term' },
          { value: 'consentMethod', label: 'Consent Method' },
          { value: 'regionalConsentRule', label: 'Regional Consent Rule' },
          { value: 'trackingLevel', label: 'Tracking Level' },
          { value: 'identifyStrength', label: 'Identify Strength' },
          { value: 'analyticsStorage', label: 'analytics_storage' },
          { value: 'adsStorage', label: 'ads_storage' },
          { value: 'deviceType', label: 'Device Type' },
          { value: 'browser', label: 'Browser' },
          { value: 'continent', label: 'Continent' },
          { value: 'country', label: 'Country' },
          { value: 'region', label: 'Region' },
          { value: 'postalCode', label: 'Postal Code' },
          { value: 'timeZone', label: 'Timezone' },
          { value: 'conversionSlug', label: 'Conversion Name (ID)' },
          { value: 'triggerSlug', label: 'Trigger Name (ID)' },
          { value: 'customValues', label: 'Custom Value' },
          { value: 'page', label: 'Page' },
          { value: 'hostname', label: 'Hostname' },
          { value: 'pageTitle', label: 'Page Title' },
          { value: 'eventPageLocation', label: 'Event Page Location' },
          { value: 'eventHostnameLocation', label: 'Event Hostname Location' },
     ],
};
export const METRIC_OPTION = {
     [SCOPED.EVENT]: [
          { value: 'eventCount', label: 'Events' },
          { value: 'newUserCount', label: 'New Users' },
          { value: 'newPeopleCount', label: 'New People' },
          { value: 'pageViewCount', label: 'Pageviews' },
          { value: 'conversionCount', label: 'All Conversions' },
          { value: 'conversionValueAll', label: 'Conv Value (All Conversions)' },
          { value: 'primaryConversionCount', label: 'Primary Conversions' },
     ],
     [SCOPED.SESSION]: [
          { value: 'eventCount', label: 'Events' },
          { value: 'sessionCount', label: 'Sessions' },
          { value: 'userCount', label: 'Users' },
          { value: 'peopleCount', label: 'People' },
          { value: 'newUserCount', label: 'New Users' },
          { value: 'newPeopleCount', label: 'New People' },
          { value: 'engagedSessionCount', label: 'Engaged Sessions' },
          { value: 'bouncedSessionCount', label: 'Bounced Sessions' },
          { value: 'pageViewCount', label: 'Pageviews' },
     ],
     [SCOPED.USER]: [
          { value: 'eventCount', label: 'Events' },
          { value: 'sessionCount', label: 'Sessions' },
          { value: 'userCount', label: 'Users' },
          { value: 'peopleCount', label: 'People' },
          { value: 'newUserCount', label: 'New Users' },
          { value: 'newPeopleCount', label: 'New People' },
          { value: 'engagedSessionCount', label: 'Engaged Sessions' },
          { value: 'bouncedSessionCount', label: 'Bounced Sessions' },
          { value: 'pageViewCount', label: 'Pageviews' },
     ],
     [SCOPED.PEOPLE]: [
          { value: 'eventCount', label: 'Events' },
          { value: 'sessionCount', label: 'Sessions' },
          { value: 'userCount', label: 'Users' },
          { value: 'peopleCount', label: 'People' },
          { value: 'newUserCount', label: 'New Users' },
          { value: 'newPeopleCount', label: 'New People' },
          { value: 'engagedSessionCount', label: 'Engaged Sessions' },
          { value: 'bouncedSessionCount', label: 'Bounced Sessions' },
          { value: 'pageViewCount', label: 'Pageviews' },
     ],
     [SCOPED.COMPANY]: [
          { value: 'eventCount', label: 'Events' },
          { value: 'sessionCount', label: 'Sessions' },
          { value: 'userCount', label: 'Users' },
          { value: 'peopleCount', label: 'People' },
          { value: 'newUserCount', label: 'New Users' },
          { value: 'newPeopleCount', label: 'New People' },
          { value: 'engagedSessionCount', label: 'Engaged Sessions' },
          { value: 'bouncedSessionCount', label: 'Bounced Sessions' },
          { value: 'pageViewCount', label: 'Pageviews' },
     ],
};

export const DIMENSION_PATH = {
     landingPage: 'eventParameters.eventLocation.originPathName',
     exitPage: 'eventParameters.eventLocation.originPathName',
     userSource: 'eventParameters.sourceAutomaticValues.source',
     userSourceFirst: 'eventParameters.sourceAutomaticValues.source',
     userSourcePath: 'eventParameters.sourceAutomaticValues.source',
     userSourceLast: 'eventParameters.sourceAutomaticValues.source',
     utmSource: 'parameterItem.value.string_value',
     utmMedium: 'parameterItem.value.string_value',
     utmSourceMedium: 'parameterItem.value.string_value',
     utmCampaign: 'parameterItem.value.string_value',
     utmTerm: 'parameterItem.value.string_value',
     consentMethod: 'eventParameters.tracking.consentMethod',
     regionalConsentRule: 'eventParameters.tracking.consentRegionRule',
     trackingLevel: 'eventParameters.tracking.trackingLevel',
     identifyStrength: 'eventParameters.tracking.identifyStrength',
     analyticsStorage: 'eventParameters.consentType.analytics_storage',
     adsStorage: 'eventParameters.consentType.ad_storage',
     deviceType: 'eventParameters.udAutomaticValues.device.type',
     browser: 'eventParameters.udAutomaticValues.browse.family',
     continent: 'eventParameters.ugAutomaticValues.geography.continentName',
     country: 'eventParameters.ugAutomaticValues.geography.countryName',
     region: 'eventParameters.ugAutomaticValues.geography.regionName',
     postalCode: 'eventParameters.ugAutomaticValues.geography.postalcode',
     timeZone: 'eventParameters.ugAutomaticValues.geography.timezone',
     conversionSlug: 'eventParameters.conversion.conversionSlug',
     triggerSlug: 'eventParameters.triggers.triggerSlug',
     customValues: 'customValueItem.value.string_value',
};

export const PERIOD_TYPES = {
     CUSTOM: 'Custom',
     PREVIOUS_PERIOD: 'Previous Period',
     PREVIOUS_YEAR: 'Previous Year',
};

export const PERIOD_TYPES_NEW = {
     PREVIOUS_MONTH: 'Previous Month',
     PREVIOUS_QUARTER: 'Previous Quarter',
};

export const EXPORT_TYPE = {
     EXPORT_ALL_PAGE: 'Export All Page',
     EXPORT_A_PAGE: 'Export A Page',
};

export const CUSTOM_REPORT_TYPE = {
     SINGLE_SCOPE: 'Single Scope',
     MULTI_SCOPE: 'Multi Scope',
     DATA_SET: 'Data Set',
};

export const PROFILE_REPORT = {
     FORM: 'form-category',
};

export const VARIABLE_OPERATORS_DIMENSION_REPORT_DATE = [
     { value: 'on', label: 'on' },
     { value: 'af', label: 'after' },
     { value: 'bf', label: 'before' },
]; // Variable operators of rules for date's fields

export const TYPE_PROPERTIES_CUSTOM_FILTER_NEED_REMOVE = [
     { value: 'browserCustom', label: 'Browser' },
     { value: 'consentMethodCustom', label: 'Consent Method' },
     { value: 'countryCustom', label: 'Country' },
     { value: 'deviceTypeCustom', label: 'Device Type' },
     { value: 'hostnameCustom', label: 'Hostname' },
     { value: 'regionalConsentRuleCustom', label: 'Regional Consent Rule' },
     { value: 'regionCustom', label: 'Region' },
     { value: 'trackingLevelCustom', label: 'Tracking Level' },
     { value: 'utmCampaignCustom', label: 'utm_campaign' },
     { value: 'utmMediumCustom', label: 'utm_medium' },
     { value: 'utmMediumUtmSourceCustom', label: 'utm_medium / utm_source' },
     { value: 'utmSourceCustom', label: 'utm_source' },
     { value: 'utmTermCustom', label: 'utm_term' },
];

export const WEIGHT_TYPES = [
     { key: 'firstTouch', label: 'First - Touch' },
     { key: 'lastTouch', label: 'Last - Touch' },
     { key: 'linear', label: 'Linear' },
     { key: 'engagementDriven', label: 'Engagement Driven' },
     { key: 'uShape', label: 'U-Shape' },
];
export const LIST_PATHPROPERTYNAME_FORMAT = ['customValues.price', 'conversion.conversionValue', 'customValues.cartTotal'];
export const VARIABLE_OPERATORS_CONTAIN_REPORT = [
     { value: 'ct', label: 'contains' },
     { value: 'nct', label: 'not contains' },
];
export const listOptionsAddmore = ['sessionCountRow', 'conversionCountRow', 'peopleCountRow', 'userCountRow', 'totalSessionDurationType'];

export const IGNORE_FILTER_OPTIONS = {
     [REPORT_NAMES.SESSION_DETAILS]: [
          'userSourceFirst',
          'userSourceLast',
          'channelFirst',
          'channelLast',
          'channelPath',
          'personType',
          'userType',
          'revealedCompanyType',
          'sessionCountRow',
          'userCountRow',
          'peopleCountRow',
          'conversionType',
          'sessionSource',
          'eCommerceAction',
          'originPath',
          'exitPageOriginPath',
          'landingPageOriginPath',
          'path',
          'hostName',
     ],
     [REPORT_NAMES.USER_DETAILS]: [
          'personType',
          'revealedCompanyType',
          'userCountRow',
          'peopleCountRow',
          'channel',
          'userSource',
          'sessionSource',
          'eCommerceAction',
     ],
     [REPORT_NAMES.PERSON_DETAILS]: [
          'userType',
          'revealedCompanyType',
          'channel',
          'conversionType',
          'peopleCountRow',
          'sessionSource',
          'eCommerceAction',
     ],
     [REPORT_NAMES.COMPANY_DETAILS]: [
          'userSource',
          'personType',
          'userType',
          'channel',
          'country',
          'city',
          'deviceType',
          'deviceFamily',
          'deviceManufacturer',
          'browser',
          'versionGeneral',
          'versionDetail',
          'browserVersion',
          'browserFamily',
          'browserDetailedVersion',
          'conversionName',
          'conversionByUrl',
          'eCommerceAction',
          'sessionSource',
     ],
     [REPORT_NAMES.CONVERSION_EVENTS]: ['conversionCount'],
};

export const METRICS_RATE = [
     'engagementRate',
     'totalEngagementRate',
     'bounceRate',
     'totalBounceRate',
     'sessionConvRate',
     'totalSessionConvRate',
     'personConvRate',
     'totalPersonConvRate',
     'totalCompanyConvRate',
     'userConvRate',
     'totalUserConvRate',
     'conversionRateSecondary',
     'totalConversionRateSecondary',
     'conversionRatePrimary',
     'totalConversionRatePrimary',
     'conversionRateAll',
     'totalConversionRateAll',
     'revealedCompanyConvRate',
];

export const DASHBOARD_GROUP = {
     SESSION: 'session',
     PEOPLE_ACTIVE: 'people-active',
     PEOPLE_REVEALED: 'people-revealed',
     COMPANY_ACTIVE: 'company-active',
     COMPANY_REVEALED: 'company-revealed',
     CONVERSION: 'conversion',
     TARGET_ACCOUNT_ACTIVE: 'target-account-active',
     TARGET_ACCOUNT_REVEALED: 'target-account-revealed',
     ECOMMERCE_EVENT: 'ecommerce-event',
     ECOMMERCE_COUNT: 'ecommerce-count',
     LEADS_SALE_FORCE: 'leads-sale-force',
     CONTACTS_SALE_FORCE: 'contacts-sale-force',
     ACCOUNTS_SALE_FORCE: 'accounts-sale-force',
     OPPORTUNITIES_SALE_FORCE: 'opportunities-sale-force',
     SALE_CONVERSION: 'sale-conversion',
};

export const DASHBOARD_GROUP_DATA = [
     {
          key: 'engagedSessionCount',
          group: DASHBOARD_GROUP.SESSION,
     },
     {
          key: 'userCount',
          group: DASHBOARD_GROUP.SESSION,
     },
     {
          key: 'peopleCount',
          group: DASHBOARD_GROUP.SESSION,
     },
     {
          key: 'webConversion',
          group: DASHBOARD_GROUP.CONVERSION,
     },
     {
          key: 'webConversionValue',
          group: DASHBOARD_GROUP.CONVERSION,
     },
     {
          key: 'sessionEngagementRate',
          group: DASHBOARD_GROUP.SESSION,
     },
     {
          key: 'conversionRateAll',
          group: DASHBOARD_GROUP.SESSION,
     },
     {
          key: 'conversionRatePrimary',
          group: DASHBOARD_GROUP.SESSION,
     },
     {
          key: 'conversionRateSecondary',
          group: DASHBOARD_GROUP.SESSION,
     },
     {
          key: 'bounceRate',
          group: DASHBOARD_GROUP.SESSION,
     },
     {
          key: 'sessionDuration',
          group: DASHBOARD_GROUP.SESSION,
     },
     {
          key: 'avgSessionDuration',
          group: DASHBOARD_GROUP.SESSION,
     },
     {
          key: 'peopleActive',
          group: DASHBOARD_GROUP.PEOPLE_ACTIVE,
     },

     {
          key: 'peopleRevealed',
          group: DASHBOARD_GROUP.PEOPLE_REVEALED,
     },
     {
          key: 'peopleRevealedRate',
          group: DASHBOARD_GROUP.PEOPLE_REVEALED,
     },
     {
          key: 'companyRevealed',
          group: DASHBOARD_GROUP.COMPANY_REVEALED,
     },

     {
          key: 'companyActive',
          group: DASHBOARD_GROUP.COMPANY_ACTIVE,
     },

     {
          key: 'productViews',
          group: DASHBOARD_GROUP.ECOMMERCE_EVENT,
     },
     {
          key: 'addToCarts',
          group: DASHBOARD_GROUP.ECOMMERCE_EVENT,
     },
     {
          key: 'beginCheckouts',
          group: DASHBOARD_GROUP.ECOMMERCE_EVENT,
     },
     {
          key: 'purchases',
          group: DASHBOARD_GROUP.ECOMMERCE_EVENT,
     },
     {
          key: 'revenue',
          group: DASHBOARD_GROUP.ECOMMERCE_COUNT,
     },
     {
          key: 'averageOrderValue',
          group: DASHBOARD_GROUP.ECOMMERCE_COUNT,
     },
     {
          key: 'targetAccountsActive',
          group: DASHBOARD_GROUP.TARGET_ACCOUNT_ACTIVE,
     },
     {
          key: 'targetAccountsRevealed',
          group: DASHBOARD_GROUP.TARGET_ACCOUNT_REVEALED,
     },
     {
          key: 'newLeads',
          group: DASHBOARD_GROUP.LEADS_SALE_FORCE,
     },
     {
          key: 'newContacts',
          group: DASHBOARD_GROUP.CONTACTS_SALE_FORCE,
     },
     {
          key: 'newAccounts',
          group: DASHBOARD_GROUP.ACCOUNTS_SALE_FORCE,
     },
     {
          key: 'newOpportunities',
          group: DASHBOARD_GROUP.OPPORTUNITIES_SALE_FORCE,
     },
     {
          key: 'newOpportunitiesValue',
          group: DASHBOARD_GROUP.OPPORTUNITIES_SALE_FORCE,
     },
     {
          key: 'saleConversions',
          group: DASHBOARD_GROUP.SALE_CONVERSION,
     },
     {
          key: 'primarySaleConversions',
          group: DASHBOARD_GROUP.SALE_CONVERSION,
     },
     {
          key: 'secondarySaleConversions',
          group: DASHBOARD_GROUP.SALE_CONVERSION,
     },
     {
          key: 'saleConversionsValue',
          group: DASHBOARD_GROUP.SALE_CONVERSION,
     },
     {
          key: 'primarySaleConversionsValue',
          group: DASHBOARD_GROUP.SALE_CONVERSION,
     },
     {
          key: 'secondarySaleConversionsValue',
          group: DASHBOARD_GROUP.SALE_CONVERSION,
     },
];

export const SAVE_REPORT_DATE_OPTION = {
     NO_RANGE: 'noRange',
     STATIC_RANGE: 'staticRange',
     ROLLING_RANGE: 'rollingRange',
};

export const ROLLING_DATE_RANGE = {
     previous_week_sun_sat: 'previous_week_sun_sat',
     previous_week_mon_sun: 'previous_week_mon_sun',
     previous_month: 'previous_month',
     month_to_date_yesterday: 'month_to_date_yesterday',
     year_to_date_yesterday: 'year_to_date_yesterday',
     quarter_to_date: 'quarter_to_date',
     previous_x_days: 'previous_x_days',
     previous_x_weeks: 'previous_x_weeks',
};
